import { Box, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { Get, Post } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
// import { Post_GetClientRolesByClientNumber_URL, Post_GetClientsRolesReport_URL } from 'src/constants/apiURLs';
import { Icon } from '@iconify/react';
import IndividualClientReportDialog from './IndividualClientReportDialog';
import SendFollowupEmailDialog from './SendFollowupEmailDialog';
import { API_URLS } from 'src/constants/apiURLs';
import { calculateTimeTaken, parseJobBoards } from 'src/helpers';

function ClientRolesReportComponent({ fromDate, toDate, clientStatsType }) {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [rowsToRender, setRowsToRender] = useState([]);
  const [openIndividualClientReportDialog, setOpenIndividualClientReportDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientRoles, setClientRoles] = useState([]);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [openSendFollowupDialog, setOpenSendFollowupDialog] = useState(false);
  const [startDate, setStartDate] = useState(new Date(fromDate));
  const [endDate, setEndDate] = useState(new Date(toDate));
  const [subscription, setSubscription] = useState('current');

  const columns = [
    {
      accessorKey: 'client.id',
      header: 'Sr.',
    },
    {
      accessorKey: 'client.client.service.service_name',
      header: 'Service',
      minWidth: 170,
    },
    {
      accessorKey: 'client.client_number',
      header: 'Client No',
      minWidth: 170,
      Cell: ({ cell }) => (
        <Box component="span">
          <>
            {cell.row.original.totalRoles === 0 ? (
              <span
                style={{ color: '#E79D58', cursor: 'pointer', fontWeight: 'bold' }}
                onClick={() => {
                  if (cell.row.original.totalRoles === 0) {
                    setOpenSendFollowupDialog(true);
                    setSelectedClient(cell.row.original.client);
                  }
                }}
              >
                {cell.getValue()} &nbsp; <Icon icon="ph:info-duotone" style={{ color: '#0EC4FE', fontSize: '20px' }} />
              </span>
            ) : (
              <span style={{ color: '#74C111', cursor: 'pointer', fontWeight: 'bold' }}>{cell.getValue()} &nbsp;</span>
            )}
          </>
        </Box>
      ),
    },
    {
      accessorKey: 'totalRoles',
      header: 'No of Roles',
      Cell: ({ cell }) => (
        <Box component="span">
          <>
            {cell.row.original.totalRoles === 0 ? (
              <span style={{ color: '#E79D58', cursor: 'pointer', fontWeight: 'bold' }}>{cell.getValue()} &nbsp;</span>
            ) : (
              <span
                style={{ color: '#74C111', cursor: 'pointer' }}
                onClick={() => {
                  setSelectedClient(cell.row.original.client);
                  setOpenIndividualClientReportDialog(true);
                }}
              >
                {cell.getValue()} &nbsp; <Icon icon="ph:info-duotone" style={{ color: '#0EC4FE', fontSize: '20px' }} />
              </span>
            )}
          </>
        </Box>
      ),
    },
    {
      accessorKey: 'totalRoles',
      header: 'Status',
      // if totalRoles are greater then 0 show sent roles otherwise show not sent roles
      Cell: ({ cell }) => (
        <Box component="span">
          {cell.row.original.totalRoles > 0 ? (
            <Typography
              variant="body2"
              style={{
                color: 'white',
                backgroundColor: '#74C111',
                padding: '5px',
                borderRadius: '5px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              Sent
            </Typography>
          ) : (
            <Typography
              style={{
                color: 'white',
                backgroundColor: '#E79D58',
                padding: '5px',
                borderRadius: '5px',
                textAlign: 'center',
                width: '100px',
              }}
            >
              Not sent
            </Typography>
          )}
        </Box>
      ),
    },
    {
      accessorKey: 'pendingRoles',
      header: 'Pending Roles',
    },
    {
      accessorKey: 'inProgressRoles',
      header: 'Inprogress Roles',
    },
    {
      accessorKey: 'doneRoles',
      header: 'Done Roles',
    },
  ];

  const getClientRoles = useCallback(() => {
    try {
      setLoading(true);
      Post(
        { fromDate: fromDate, toDate: toDate },
        API_URLS.getClientsRolesReport,
        (resp) => {
          setClients(resp);
          setLoading(false);
          //   notify('Client Roles Report fetched successfully', 'success', 1000);
        },
        (error) => {
          notify('Failed to fetch Client Roles Report', 'error', 1000);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      notify('Something went wrong at server', 'error', 1000);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getClientRoles();
    if (clientStatsType === 'sent') {
      const temp = clients.filter((client) => client.totalRoles > 0);
      setRowsToRender(temp);
    } else if (clientStatsType === 'notSent') {
      const temp = clients.filter((client) => client.totalRoles === 0);
      setRowsToRender(temp);
    } else {
      setRowsToRender(clients);
    }
  }, [getClientRoles, clientStatsType]);

  const defaultRows = rowsToRender.length > 0 ? rowsToRender : clients;

  const getClientRolesById = useCallback(() => {
    try {
      setRolesLoading(true);
      Get(
        {
          startDate: startDate,
          endDate: endDate,
          client_number: selectedClient?.client_number,
          subscription: subscription,
        },
        API_URLS.getClientRolesSheet,
        (resp) => {
          if(subscription === 'current'){
            const data = resp?.data?.data?.roles?.map((role) => ({
              ...role,
              timeTaken: calculateTimeTaken(role.createdAt, role.updatedAt),
              jobBoardDetails: parseJobBoards(role?.job_board_used ?? []),
              doneBy: role?.first_name + ' ' + role?.last_name,
            }));
            let currentSubscription = resp?.data?.data?.subscription;
            data.forEach((role) => {
              role.subscription = currentSubscription;
            });

            setClientRoles(data);
            setRolesLoading(false);
            console.log('ClientRolesReportComponent -> resp', resp);
          }
        },
        (error) => {
          setRolesLoading(false);
          notify('Failed to fetch Client Roles Report', 'error', 1000);
        }
      );
    } catch (error) {
      setRolesLoading(false);
      notify('Something went wrong at server', 'error', 1000);
    }
  }, [startDate, endDate, selectedClient, subscription]);

  useEffect(() => {
    if (openIndividualClientReportDialog) {
      getClientRolesById();
    }
  }, [getClientRolesById, openIndividualClientReportDialog]);

  return (
    <div style={{ margin: '2rem' }}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 50,
          },
        }}
        columns={columns}
        data={defaultRows}
        defaultColumn={{
          size: 100, //make columns wider by default
        }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        enableTopToolbar={false}
        positionToolbarAlertBanner="bottom"
        manualFiltering
        state={{
          isLoading: loading,
        }}
        actionsColumnIndex={-1}
      />
      {openIndividualClientReportDialog && (
        <IndividualClientReportDialog
          open={openIndividualClientReportDialog}
          onClose={() => setOpenIndividualClientReportDialog(false)}
          data={clientRoles}
          loading={rolesLoading}
          clientNumber={selectedClient?.client_number}
          subscription={subscription}
          onCurrentMonthSelected={() => {
            setSubscription('current');
          }}
          onDateChange={(date) => {
            setStartDate(date[0]);
            setEndDate(date[1]);
          }}
          onHistoryRequested={() => {
            setSubscription('history');
          }}
        />
      )}
      {openSendFollowupDialog && (
        <SendFollowupEmailDialog
          open={openSendFollowupDialog}
          onClose={() => setOpenSendFollowupDialog(false)}
          clientData={selectedClient}
        />
      )}
    </div>
  );
}

export default ClientRolesReportComponent;
