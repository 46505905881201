
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

function UpdateLogResponse({ open, onClose, onSubmit , selectedChannel , selectedCandidate , sequenceId , roleId }) {
  
  console.log( "dsadsakjd",selectedChannel, selectedCandidate);
  const [response, setResponse] = useState();
  const userId = localStorage.getItem('userId');
  const [channel, setChannel] = useState();
  const [responseContent, setResponseContent] = useState();
  const [log, setLog] = useState();
  const channels = ['Outreach Email', 'WhatsApp Message', 'SMS', 'LI Connection', 'Call', 'InMail'];
  const responseOptions = [
    'Partially Interested',
    'Fully Interested',
    'Not Interested',
    'Auto Reply',
    'No Response',
    'NCR',
    'WFR',
  ];

  useEffect(() => {
    if(selectedCandidate){
      const log = selectedCandidate?.candidate_communication_logs?.find((log) => log.channel === selectedChannel);
      setLog(log);
    }
  }, [selectedCandidate]);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose();
    }
  };
  console.log('dasdsdasdsaed3wqewd', log, );

    const addCommunicationLogToDB = (message) => {
      console.log('mfdsfessage', message);
      const sender = message?.message?.from;
      try {
        Post(
          {
            channel: 'Call',
            message: 'call',
            sequenceId: sequenceId,
            roleId: roleId,
            candidateId: selectedCandidate?.source === 'Candidates' ? selectedCandidate?.id : null,
            li_id: selectedCandidate?.source === 'LIProfiles' ? selectedCandidate?.id : null,
            userId: userId,
            whatsapp_sender: sender,
          },
          API_URLS.addCommunicationLog,
          (resp) => {
            console.log('response', resp);
            alert('Communication log added successfully');
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            height: '350px',
            maxWidth: '600px',
            maxHeight: '500px',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Update Response
            </Typography>
            {/* <IconButton onClick={onClose}>
              <Close />
            </IconButton> */}
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          {selectedChannel === 'Outreach Email' && log && (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Email response:
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {log?.response_content}
              </Typography>
            </Box>
          )}
          {
            selectedChannel === 'Call' &&(
              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <Typography>
                  Hvae you called to this candidate ? 
                </Typography>
                <Button
                sx={{
                  background: '#00C1FE',
                  color: '#fff',
                  width: '150px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '15px',
                }}
                onClick={() => {
                  addCommunicationLogToDB();
                }}
              >
              Yes
              </Button>
              </Box>
            )
          }
          <div>
            {/* <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
              <FormControl>
                <label
                  htmlFor="select-single-native"
                  style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
                >
                  Select Channel:
                </label>
                <Autocomplete
                  options={channels} // Array of options
                  getOptionLabel={(option) => option} // Specifies which field to display
                  value={channel ? channels?.find((option) => option === channel) : null}
                  onChange={(event, newValue) => setChannel(newValue)}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select an option" // Optional placeholder
                      sx={{
                        width: '500px',
                        '.MuiOutlinedInput-root': {
                          color: '#000000', // Text color
                          padding: '8px 10px',
                          height: '50px',
                          backgroundColor: '#FFFFFF', // White background
                          border: '2px solid #D9D9D9', // Grey border
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D9D9D9', // Grey border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D9D9D9', // Hover border color
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D9D9D9', // Focus border color
                        },
                        '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                          color: '#000000', // Icon color
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box> */}
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
              <FormControl>
                <label
                  htmlFor="select-single-native"
                  style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
                >
                  Select Response:
                </label>
                <Autocomplete
                  options={responseOptions} // Array of options
                  getOptionLabel={(option) => option} // Specifies which field to display
                  value={response ? responseOptions?.find((option) => option === response) : null}
                  onChange={(event, newValue) => setResponse(newValue)}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select an option" // Optional placeholder
                      sx={{
                        width: '500px',
                        '.MuiOutlinedInput-root': {
                          color: '#000000', // Text color
                          padding: '8px 10px',
                          height: '50px',
                          backgroundColor: '#FFFFFF', // White background
                          border: '2px solid #D9D9D9', // Grey border
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D9D9D9', // Grey border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D9D9D9', // Hover border color
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D9D9D9', // Focus border color
                        },
                        '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                          color: '#000000', // Icon color
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            {/* <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
            <FormControl>
              <label
                htmlFor="select-single-native"
                style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
              >
                Reponse Content
              </label>
              <TextField
                type="text"
                id="responseContent"
                name="responseContent"
                placeholder={`Paste response content...`}
                value={responseContent}
                onChange={(e) => {
                  setResponseContent(e.target.value);
                }}
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  marginTop: '5px',
                  border: '1px solid #CAC5C5',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            </FormControl>
          </Box> */}
            <br />
            <Box display="flex" justifyContent="center">
              <Button
                sx={{
                  background: '#00C1FE',
                  color: '#fff',
                  width: '150px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '15px',
                }}
                onClick={() => {
                  onSubmit(response);
                }}
              >
                Submit response
              </Button>
            </Box>
            <br />

            <Box display="flex" alignItems="center" justifyContent="center">
              <p style={{ color: 'red', fontSize: '13px' }}>
                Note: Dialog will be auto-closed when you submit response.
              </p>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpdateLogResponse;
