import { Icon } from '@iconify/react';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import { ChangeCircleOutlined, Visibility } from '@mui/icons-material';
import { CVFormat } from 'src/sections/acm/components';
function AddLIComponent() {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const userId = localStorage.getItem('userId');

  const [openViewLIDialog, setOpenViewLIDialog] = useState(false);
  const [selectedLI, setSelectedLI] = useState(null);

  const [rows, setRows] = useState([
    // {
    //   profileType: '',
    //   liLink: '',
    //   personalNumber: '',
    //   personalEmail: '',
    //   businessNumber: '',
    //   businessEmail: '',
    //   isSubmitted: false,
    //   isChecked:false,
    // },
    // {
    //   profileType: '',
    //   liLink: '',
    //   personalNumber: '',
    //   personalEmail: '',
    //   businessNumber: '',
    //   businessEmail: '',
    //   isSubmitted: false,
    //   isChecked:false,
    // },
    // {
    //   profileType: '',
    //   liLink: '',
    //   personalNumber: '',
    //   personalEmail: '',
    //   businessNumber: '',
    //   businessEmail: '',
    //   isSubmitted: false,
    //   isChecked:false,
    // },
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  const handleSubmitRow = (index) => {
    const rowData = rows[index];
    const { liLink, profileType, personalNumber, personalEmail, businessNumber, businessEmail } = rowData;
  
    const pattern = /https:\/\/www.linkedin.com\/in\/[a-zA-Z0-9-]+/g;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  
    // Profile type validation
    if (profileType !== 'Head Hunting' && profileType !== 'openToWork') {
      enqueueSnackbar('Please select a valid profile type (Head Hunting or Open to Work)', { variant: 'error' });
      return;
    }
  
    // LinkedIn link validation
    if (!liLink || !pattern.test(liLink)) {
      enqueueSnackbar('Please enter a valid LinkedIn link', { variant: 'error' });
      return;
    }
  
    // Email validations
    if (personalEmail && !emailPattern.test(personalEmail)) {
      enqueueSnackbar('Please enter a valid personal email', { variant: 'error' });
      return;
    }
    if (businessEmail && !emailPattern.test(businessEmail)) {
      enqueueSnackbar('Please enter a valid business email', { variant: 'error' });
      return;
    }
  
    const payload = {
      profile_link: liLink,
      personal_contact: personalNumber,
      personal_email: personalEmail,
      business_number: businessNumber,
      business_email: businessEmail,
      profile_type: profileType,
      user_id: parseInt(userId),
      profile_status: 'pending',
      role_id: parseInt(roleId),
    };
  
    try {
      Post(
        payload,
        API_URLS.addLIToRole,
        (resp) => {
          enqueueSnackbar('LI added successfully', { variant: 'success' });
          console.log('Response:', resp);
  
          const updatedRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
              return {
                ...row,
                isSubmitted: true,
                isAlreadyExist: resp?.message?.includes('already exist'),
                data: resp.message.includes('already exist') ? resp.data : null,
              };
            }
            return row;
          });
  
          setRows(updatedRows);
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message || 'An error occurred', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message || 'An unexpected error occurred', { variant: 'error' });
    }
  };

  const addMoreRows = () => {
    const newRows = [
      {
        profileType: '',
        liLink: '',
        personalNumber: '',
        personalEmail: '',
        businessNumber: '',
        businessEmail: '',
        isSubmitted: false,
        isChecked: false,
        isAlreadyExist: false,
        data:null
      },
      {
        profileType: '',
        liLink: '',
        personalNumber: '',
        personalEmail: '',
        businessNumber: '',
        businessEmail: '',
        isSubmitted: false,
        isChecked: false,
        isAlreadyExist: false,
        data:null
      },
      {
        profileType: '',
        liLink: '',
        personalNumber: '',
        personalEmail: '',
        businessNumber: '',
        businessEmail: '',
        isSubmitted: false,
        isChecked: false,
        isAlreadyExist: false,
        data:null
      },
    ];
    setRows([...rows, ...newRows]);
  };

  const selectAll = () => {
    // if all are checked, uncheck all
    if (rows.every((row) => row.isChecked)) {
      const updatedRows = [...rows];
      updatedRows.map((row) => {
        row.isChecked = false;
      });
      setRows(updatedRows);
      return;
    } else {
      const updatedRows = [...rows];
      updatedRows.map((row) => {
        row.isChecked = true;
      });
      setRows(updatedRows);
    }
  };

  const addSelectedLIs = () => {
    const selectedRows = rows.filter((row) => row.isChecked);
  
    if (selectedRows.some((row) => row.isSubmitted)) {
      enqueueSnackbar('Please select only those rows which are not submitted', { variant: 'error' });
      return;
    }
  
    if (selectedRows.length === 0) {
      enqueueSnackbar('Please select at least one row to add', { variant: 'error' });
      return;
    }
  
    const pattern = /https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?/g;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  
    selectedRows.forEach((row) => {
      const { liLink, profileType, personalNumber, personalEmail, businessNumber, businessEmail } = row;
  
      if (profileType !== 'Head Hunting' && profileType !== 'openToWork') {
        enqueueSnackbar('Please select a valid profile type (Head Hunting or Open to Work)', { variant: 'error' });
        return;
      }
  
      if (personalEmail && !emailPattern.test(personalEmail)) {
        enqueueSnackbar('Please enter a valid personal email', { variant: 'error' });
        return;
      }
  
      if (businessEmail && !emailPattern.test(businessEmail)) {
        enqueueSnackbar('Please enter a valid business email', { variant: 'error' });
        return;
      }
  
      const payload = {
        profile_link: liLink,
        personal_contact: personalNumber,
        personal_email: personalEmail,
        business_number: businessNumber,
        business_email: businessEmail,
        profile_type: profileType,
        user_id: parseInt(userId),
        profile_status: 'pending',
        role_id: parseInt(roleId),
      };
  
      Post(
        payload,
        API_URLS.addLIToRole,
        (resp) => {
          enqueueSnackbar('LI added successfully', { variant: 'success' });
  
          // Update only the row matching the current API response
          setRows((prevRows) =>
            prevRows.map((r) => {
              if (r.liLink === resp?.data?.profile_link) {
                return {
                  ...r,
                  isSubmitted: true,
                  isAlreadyExist: resp?.message?.includes('already exist'),
                  data: resp.data,
                };
              }
              return r;
            })
          );
        },
        (error) => {
          enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        }
      );
    });
  };
  
  

  const handleExcelImport = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      console.log('sjkdgfsdfsdfds', jsonData);
      // Map Excel data to rows
      const newRows = jsonData
        .slice(1)
        .filter((row) => row.length > 0)
        .map((row) => ({
          profileType: row[0] == 1 ? 'openToWork' : 'Head Hunting',
          liLink: row[1] || '',
          personalNumber: row[2] || '',
          personalEmail: row[3] || '',
          businessNumber: row[4] || '',
          businessEmail: row[5] || '',
          isSubmitted: false,
          isChecked: false,
          isAlreadyExist: false,
        }));

      setRows((prev) => [...prev, ...newRows]);
    };
    reader.readAsArrayBuffer(file);
  };

  const changeLIProfileStatus = (id) => {
    console.log("ksdgfsdnfsdf",id);
    try {
      Post(
        {
          id,
          status: 'pending',
        },
        API_URLS.changeLIProfileStatus,
        (resp) => {
          enqueueSnackbar('LI reverted to pending successfully', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error reverting LI to pending', { variant: 'error' });
    }
  }


  console.log('rowsjksdgfsd', rows);
  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      <Box display="flex" marginBottom="10px" alignItems="center">
        <Icon
          icon="ion:arrow-back"
          style={{ fontSize: '24px', cursor: 'pointer', color: '#00C1FE' }}
          onClick={() => navigate(`/resourcing/roles-processing/${roleId}`)}
        />
        <p style={{ fontSize: '18px', marginLeft: '5px', color: '#00C1FE', fontWeight: 'bold' }}>Back to Role Detail</p>
      </Box>
      <Box display="flex" justifyContent="flex-end" marginBottom="20px" gap={2}>
        <Button
          variant="outlined"
          component="label"
          style={{
            backgroundColor: '#007BFF',
            color: 'white',
            height: '35px',
            borderRadius: '5px',
            padding: '2px',
          }}
          fullWidth
        >
          Import Excel
          <input type="file" accept=".xlsx, .xls" hidden onChange={handleExcelImport} />
        </Button>
        {/* button to  add all selected LIs at a time*/}
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          style={{ marginRight: '10px' }}
          onClick={() => addSelectedLIs()}
        >
          Add Selected LIs
        </Button>
        <select
          name="profileType"
          onChange={(e) => {
            // change type of all selected rows to the selected type
            const updatedRows = [...rows];
            updatedRows.map((row) => {
              if (row.isChecked) {
                row.profileType = e.target.value;
              }
            });

            setRows(updatedRows);
          }}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '5px',
            border: '1px solid #D8D8D8',
            backgroundColor: '#F9F9F9',
          }}
        >
          <option value="">Choose Type</option>
          <option value="Head Hunting">Head Hunting</option>
          <option value="openToWork">Open to Work</option>
        </select>
      </Box>
      <table
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderCollapse: 'collapse',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              S.No
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Mark all <br />{' '}
              <input type="checkbox" checked={rows.every((row) => row.isChecked)} onChange={selectAll} />
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              LI Type
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
                width: '25%',
              }}
            >
              LI Link
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Personal Number
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Personal Email
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Business Number
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Business Email
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index} style={{ borderBottom: index === rows.length - 1 ? 'none' : '1px solid #D8D8D8' }}>
              <td
                style={{
                  border: '1px solid #D8D8D8',
                  padding: '10px',
                  textAlign: 'center',
                  color: '#979797',
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  border: '1px solid #D8D8D8',
                  padding: '10px',
                  textAlign: 'center',
                  color: '#979797',
                }}
              >
                <input
                  type="checkbox"
                  checked={row.isChecked}
                  onChange={() => {
                    const updatedRows = [...rows];
                    updatedRows[index].isChecked = !updatedRows[index].isChecked;
                    setRows(updatedRows);
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#6FA9FF' }}>
                <select
                  name="profileType"
                  value={row.profileType}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                >
                  <option value="">Choose Type</option>
                  <option value="Head Hunting">Head Hunting</option>
                  <option value="openToWork">Open to Work</option>
                </select>
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="text"
                  name="liLink"
                  value={row.liLink}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="text"
                  name="personalNumber"
                  value={row.personalNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="email"
                  name="personalEmail"
                  value={row.personalEmail}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="text"
                  name="businessNumber"
                  value={row.businessNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>

              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="email"
                  name="businessEmail"
                  value={row.businessEmail}
                  onChange={(e) => handleInputChange(index, e)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px', textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleSubmitRow(index)}
                  disabled={row.isSubmitted}
                  style={{
                    backgroundColor: row.isSubmitted ? '#B0B0B0' : '#007BFF',
                  }}
                >
                  {row.isSubmitted ? 'Submitted' : 'Submit'}
                </Button>
                {row.isAlreadyExist && (
                  <Box
                    marginTop="10px"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      <Visibility
                        sx={{
                          color: 'red',
                          cursor: 'pointer',
                          fontSize: '20px',
                        }}
                        onClick={() => {
                          setSelectedLI(row?.data);
                          setOpenViewLIDialog(true);
                        }}
                      />
                    </p>
                    <ChangeCircleOutlined
                      sx={{
                        color: 'red',
                        cursor: 'pointer',
                        fontSize: '20px',
                        marginLeft: '10px',
                      }}
                      onClick={() => changeLIProfileStatus(row?.data?.id)}
                    />
                  </Box>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box marginTop="20px">
        <Button variant="outlined" onClick={addMoreRows} color="primary">
          Add More Rows
        </Button>
      </Box>

      {openViewLIDialog && (
        <CVFormat
          open={openViewLIDialog}
          onClose={() => setOpenViewLIDialog(false)}
          openToWork={true}
          candidate={selectedLI}
        />
      )}
    </div>
  );
}

export default AddLIComponent;



// -------------------------------------------------------------- Manual Approach --------------------------------------------------------------
// import { Icon } from '@iconify/react';
// import { Add } from '@mui/icons-material';
// import { Box, Button } from '@mui/material';
// import React, { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { Post } from 'src/actions/API/apiActions';
// import { API_URLS } from 'src/constants/apiURLs';
// import { enqueueSnackbar } from 'notistack';
// import ActionConfirmationDialog from 'src/components/ActionConfirmationDialog';

// function AddLIComponent() {
//   const navigate = useNavigate();
//   const [openLIAddDialog, setOpenLIAddDialog] = useState(false);
//   const { roleId } = useParams();
//   const userId = localStorage.getItem('userId');
//   const [rows, setRows] = useState([
//     {
//       profileType: '',
//       liLink: '',
//       personalNumber: '',
//       businessNumber: '',
//       personalEmail: '',
//       businessEmail: '',
//       isSubmitted: false,
//     isChecked:false,
//     },
//     {
//       profileType: '',
//       liLink: '',
//       personalNumber: '',
//       businessNumber: '',
//       personalEmail: '',
//       businessEmail: '',
//       isSubmitted: false,
//     isChecked:false,
//     },
//     {
//       profileType: '',
//       liLink: '',
//       personalNumber: '',
//       businessNumber: '',
//       personalEmail: '',
//       businessEmail: '',
//       isSubmitted: false,
//     isChecked:false,
//     },
//   ]);

//   const handleInputChange = (index, event) => {
//     const { name, value } = event.target;
//     const updatedRows = [...rows];
//     updatedRows[index][name] = value;
//     setRows(updatedRows);
//   };

//   // const handleSendLIToScrapper = (index) => {
//   //   const rowData = rows[index];
//   //   const li = rowData?.liLink
//   //   if(!li){
//   //     enqueueSnackbar('Please enter LI link', { variant: 'error' });
//   //     return
//   //   }

//   //   try {
//   //     Post(
//   //       { url  : li },
//   //       LI_Scrapper_URLs.scrapData,
//   //       (resp) => {
//   //         enqueueSnackbar('Response reeived from scrapper');
//   //         setScrappedData(resp);
//   //         handleSubmitRow(resp)
//   //       },
//   //       (error) => {
//   //         console.log(error);
//   //       }
//   //     );
//   //   } catch (error) {
//   //     console.log(error);

//   //   }
//   // }
//   const handleSubmitRow = (index) => {
//     const rowData = rows[index];
//     const { liLink, profileType, personalNumber, businessNumber, personalEmail, businessEmail } = rowData;

//     const pattern = /https:\/\/www.linkedin.com\/in\/[a-zA-Z0-9-]+/g;
//     const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

//     // Profile type validation
//     if (profileType !== 'Normal' && profileType !== 'openToWork') {
//       enqueueSnackbar('Please select a valid profile type (Normal or Open to Work)', { variant: 'error' });
//       return;
//     }

//     // LinkedIn link validation
//     if (!liLink || !pattern.test(liLink)) {
//       enqueueSnackbar('Please enter a valid LinkedIn link', { variant: 'error' });
//       return;
//     }

//     // Email validations
//     if (personalEmail && !emailPattern.test(personalEmail)) {
//       enqueueSnackbar('Please enter a valid personal email', { variant: 'error' });
//       return;
//     }
//     if (businessEmail && !emailPattern.test(businessEmail)) {
//       enqueueSnackbar('Please enter a valid business email', { variant: 'error' });
//       return;
//     }

//     const payload = {
//       profile_link: liLink,
//       personal_contact: personalNumber,
//       business_number: businessNumber,
//       personal_email: personalEmail,
//       business_email: businessEmail,
//       profile_type: profileType,
//       user_id: parseInt(userId),
//       profile_status: 'pending',
//       role_id: parseInt(roleId),
//     };

//     try {
//       Post(
//         payload,
//         API_URLS.addLIToRole,
//         (resp) => {
//           enqueueSnackbar('LI added successfully', { variant: 'success' });

//           // Update submission status
//           const updatedRows = [...rows];
//           updatedRows[index].isSubmitted = true;
//           setRows(updatedRows);
//         },
//         (error) => {
//           console.log('lsdbhfjsdfsdf', error?.response?.data?.message);
//           enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
//         }
//       );
//     } catch (error) {
//       enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
//     }
//   };

//   const addMoreRows = () => {
//     const newRows = [
//       { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
//       { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
//       { profileType: '', liLink: '', personalNumber: '', businessNumber: '', personalEmail: '', businessEmail: '' },
//     ];
//     setRows([...rows, ...newRows]);
//   };

//   return (
//     <div style={{ width: '95%', margin: 'auto' }}>
//       <Box display="flex" marginBottom="10px" alignItems="center">
//         <Icon
//           icon="ion:arrow-back"
//           style={{ fontSize: '24px', cursor: 'pointer', color: '#00C1FE' }}
//           onClick={() => navigate(`/resourcing/roles-processing/${roleId}`)}
//         />
//         <p style={{ fontSize: '18px', marginLeft: '5px', color: '#00C1FE', fontWeight: 'bold' }}>Back to Role Detail</p>
//       </Box>
//       <table style={{ width: '100%', backgroundColor: 'white', borderCollapse: 'collapse', borderRadius: '20px' }}>
//         <thead>
//           <tr>
//             <th
//               style={{
//                 border: '1px solid #D8D8D8',
//                 padding: '15px',
//                 borderLeft: 'none',
//                 color: '#979797',
//                 borderTop: 'none',
//               }}
//             >
//               S.No
//             </th>
//             <th
//               style={{
//                 border: '1px solid #D8D8D8',
//                 padding: '15px',
//                 borderLeft: 'none',
//                 color: '#979797',
//                 borderTop: 'none',
//               }}
//             >
//               LI Type
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               LI Link
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Personal Number
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Business Number
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Personal Email
//             </th>
//             <th style={{ border: '1px solid #D8D8D8', padding: '15px', color: '#979797', borderTop: 'none' }}>
//               Business Email
//             </th>
//             <th
//               style={{
//                 border: '1px solid #D8D8D8',
//                 padding: '15px',
//                 borderRight: 'none',
//                 color: '#979797',
//                 borderTop: 'none',
//               }}
//             >
//               Action
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {rows?.map((row, index) => (
//             <tr key={index} style={index === rows.length - 1 ? { borderBottom: 'none' } : {}}>
//               <td
//                 style={{
//                   border: '1px solid #D8D8D8',
//                   padding: '10px',
//                   textAlign: 'center',
//                   borderLeft: 'none',
//                   color: '#979797',
//                 }}
//               >
//                 {index + 1}
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#6FA9FF' }}>
//                 <select
//                   name="profileType"
//                   value={row.profileType}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 >
//                   <option value="choose">Choose Type</option>
//                   <option value="Normal">Normal</option>
//                   <option value="openToWork">Open to Work</option>
//                 </select>
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#6FA9FF' }}>
//                 <input
//                   type="text"
//                   name="liLink"
//                   value={row.liLink}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="text"
//                   name="personalNumber"
//                   value={row.personalNumber}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="text"
//                   name="businessNumber"
//                   value={row.businessNumber}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="email"
//                   name="personalEmail"
//                   value={row.personalEmail}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', color: '#979797' }}>
//                 <input
//                   type="email"
//                   name="businessEmail"
//                   value={row.businessEmail}
//                   onChange={(e) => handleInputChange(index, e)}
//                   style={{ width: '100%', border: 'none', outline: 'none' }}
//                 />
//               </td>
//               <td style={{ border: '1px solid #D8D8D8', padding: '10px', borderRight: 'none' }}>
//                 <Box display="flex" justifyContent="center">
//                   <Button
//                     disabled={row.isSubmitted}
//                     sx={{
//                       background: '#00C1FE',
//                       color: '#fff',
//                       width: '90px',
//                       height: '35px',
//                       border: '1px solid #00C1FE',
//                       '&:hover': {
//                         background: '#00C1FE',
//                         color: '#fff',
//                       },
//                       borderRadius: '5px',
//                       padding: '2px',
//                     }}
//                     onClick={() => {
//                       handleSubmitRow(index);
//                     }}
//                   >
//                     Submit
//                   </Button>
//                 </Box>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <Box display="flex" justifyContent="flex-end" marginTop="1rem">
//         <Button
//           sx={{
//             background: '#00C1FE',
//             color: '#fff',
//             width: '150px',
//             height: '35px',
//             marginRight: '1rem',
//             border: '1px solid #00C1FE',
//             '&:hover': {
//               background: '#00C1FE',
//               color: '#fff',
//             },
//             borderRadius: '5px',
//             padding: '10px',
//           }}
//           onClick={addMoreRows}
//           startIcon={<Add />}
//         >
//           Add more rows
//         </Button>
//       </Box>
//       {openLIAddDialog && (
//         <ActionConfirmationDialog
//           open={openLIAddDialog}
//           onClose={() => setOpenLIAddDialog(false)}
//           title={`Are you sure you want to submit LI`}
//           actionButtonText="Yes"
//           onSubmit={() => handleSubmitRow()}
//         />
//       )}
//     </div>
//   );
// }

// export default AddLIComponent;
