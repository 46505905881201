import { Icon } from '@iconify/react';
import { TextField, Button } from '@mui/material';
import React, { useState } from 'react';

function CallInterface({ selectedCandidate, onClose }) {

    const [number, setNumber] = useState('');
  const handleButtonClick = (value) => {
    setNumber((prevNumber) => prevNumber + value);
  };

  const makeCall = async (from, to) => {
    try {
        const response = await fetch('http://localhost:3001/make-call', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ from : '+442080906352', to :'923267367042' }),
        });
        const data = await response.json();
        console.log('Call response:', data);
    } catch (error) {
        console.error('Error initiating call:', error);
    }
};
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
        height: '660px',
        margin: '0',
        borderRadius: '10px',
        backgroundColor: 'white',
        overflow: 'hidden',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '15px 20px',
          zIndex: 1,
        }}
      >
        <Icon
          icon="bitcoin-icons:cross-filled"
          style={{ fontSize: '24px', cursor: 'pointer', color: 'black' }}
          onClick={() => onClose()}
        />
      </div>
      <div
        style={{ padding: '10px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <TextField
          variant="standard"
          style={{
            width: '80%',
            fontSize: '35px',
            textAlign: 'right',
          }}
          InputProps={{
            style: {
              fontSize: '40px',
              textAlign: 'right',
            },
          }}
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </div>
      <br />
      {/* Dial Pad Section */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '15px', // Use this to create space between grid items
          padding: '20px',
          justifyItems: 'center', // Center the items horizontally in each grid cell
        }}
      >
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map((value) => (
          <Button
            key={value}
            sx={{
              width: '70px',
              height: '70px',
              borderRadius: '50%',
              backgroundColor: '#ECF6FE',
              color: 'black',
              fontSize: '20px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#cfe8fc',
                color: 'black',
              },
            }}
            onClick={() => handleButtonClick(value)}
          >
            {value}
          </Button>
        ))}
      </div>

      {/* Call Button */}
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <Button
          sx={{
            backgroundColor: '#9ed1fa',
            color: 'black',
            padding: '20px',
            fontSize: '18px',
            width: '80%',
            '&:hover': {
              backgroundColor: '#9ed1fa',
              color: 'black',
            },
          }}
          onClick={() => makeCall('+44 20 8090 6352', number)} // Replace with actual call logic
        >
          Call
        </Button>
      </div>
    </div>
  );
}

export default CallInterface;
