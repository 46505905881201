import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00C1FE',
    },
    '&:hover fieldset': {
      borderColor: '#00C1FE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00C1FE',
    },
  },
  marginTop: theme.spacing(1),
}));

function AddCandidateEmailDialog({ open, onClose, selectedCandidate , onSubmitted , areMorethanTwoEmails}) {
    console.log("dasfdsdsf", selectedCandidate)
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [email, setEmail] = useState(null);
  const [emailType, setEmailType] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const emailTypes = ['Personal Email', 'Business Email'];
  const user_id = localStorage.getItem('userId');
    const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    try {
      Post(
        {
          candidate_id: selectedCandidate?.source === 'Candidates' ? selectedCandidate?.id : null,
          li_id : selectedCandidate?.source === '"LIProfiles"' ? selectedCandidate?.id : null,
          user_id: user_id,
          email: email,
          email_type: emailType,
        },
        API_URLS.addCandidateEmail,
        (response) => {
          console.log('Email added:', response);
          enqueueSnackbar('Email added successfully', { variant: 'success' });
          onClose();
          onSubmitted();
        },
        (error) => {
          console.log(error);
          enqueueSnackbar('Failed to add email, try again', { variant: 'error' });
        }
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleValidationAndSubmit = () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    setEmailError(null); // Clear any previous error
    handleSubmit();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '600px',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Add Candidate Email
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <p> Email 1</p>
            <p>{selectedCandidate?.personal_email}</p>
          </Box>
          {selectedCandidate?.candidate_contact_info?.length > 0 ? (
            selectedCandidate.candidate_contact_info.map((contactInfo, index) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={contactInfo.email || index} // Prefer unique keys if available
              >
                <p>
                  Email {index + 1 + 1}
                </p>
                <p>{contactInfo.personal_email}</p>
              </Box>
            ))
          ) : (
            <p></p>
          )}

          <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem" flexDirection="column">
            <FormControl>
              <label
                htmlFor="select-single-native"
                style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}
              >
                Select Email Type:
              </label>
              <Autocomplete
                options={emailTypes} // Array of options
                getOptionLabel={(option) => option} // Specifies which field to display
                value={emailType ? emailTypes?.find((option) => option === emailType) : null}
                onChange={(event, newValue) => setEmailType(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select an option" // Optional placeholder
                    size="medium"
                    sx={{
                      width: '500px',
                      '.MuiOutlinedInput-root': {
                        color: '#000000', // Text color
                        padding: '8px 10px',
                        height: '50px',
                        backgroundColor: '#FFFFFF', // White background
                        border: '2px solid #00C1FE', // Grey border
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00C1FE', // Grey border color
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00C1FE', // Hover border color
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00C1FE', // Focus border color
                      },
                      '.MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
                        color: '#000000', // Icon color
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft="20px" marginRight="20px">
            <label htmlFor="email" style={{ fontWeight: 'bold', marginLeft: '5px', paddingTop: '20px' }}>
              Enter Email
            </label>
            <StyledTextField
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="medium"
              placeholder="Enter Email"
              error={!!emailError}
              helperText={emailError}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={handleValidationAndSubmit}
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddCandidateEmailDialog;
