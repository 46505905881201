import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Checkbox,
  Avatar,
  Skeleton,
  Dialog,
} from '@mui/material';
import React, { useState } from 'react';
import { AddCandidateToRole, ViewResume } from './images';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssociateCandidateToOtherRoleDialog, ViewCandidateResumeDialog } from '.';
import { CVFormat } from 'src/sections/acm/components';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { Post } from 'src/actions/API/apiActions';
import ActionConfirmationDialog from 'src/components/ActionConfirmationDialog';

function CandidatePipelineTable({
  roleId,
  buttons,
  onButtonClick,
  showHiringPipeline,
  onPageChange,
  onRowsPerPageChange,
  totalCandidates,
  candidates,
  loading,
  onReload
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [selected, setSelected] = useState([]); // State for selected rows
  const navigate = useNavigate();
  const [openAssociateJobDialog, setOpenAssociateJobDialog] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [openViewCandidateCVDialog, setOpenViewCandidateCVDialog] = useState(false);
    const [openCVDialog, setOpenCVDialog] = useState(false);
  const [viewLI, setViewLI] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#7FE0FE';
      case 'Accepted':
        return '#b3ffb3';
      case 'Rejected':
        return '#FFC9C9';
      default:
        return 'black';
    }
  };
  const getColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#00b0e6';
      case 'Accepted':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'black';
    }
  };
  // Handle checkbox toggle
  const handleSelect = (id) => {
    setSelected((prev) => (prev.includes(id) ? prev.filter((candidateId) => candidateId !== id) : [...prev, id]));
  };

    const handleCVDownload = (candidateId, action, fileName) => {
      try {
        fetch(API_URLS.downloadCV, {
          method: 'POST',
          headers: {
            Accept:
              'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: candidateId }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Error downloading file');
            }
            const contentDisposition = response.headers.get('Content-Disposition');
            const filename = contentDisposition
              ? decodeURIComponent(contentDisposition.split('filename=')[1].replace(/"/g, ''))
              : fileName; // Default fallback filename
            // Fallback to candidateId if header is missing
  
            return response.blob().then((blob) => ({ blob, filename }));
          })
          .then(({ blob, filename }) => {
            if (action === 'view') {
              setOpenCVDialog(true);
              // Convert blob to File and set it in selected state
              const file = new File([blob], filename, { type: blob.type });
              if (blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                try {
                  const formData = new FormData();
                  formData.append('file', file);
                  Post(
                    formData,
                    API_URLS.extractText,
                    (resp) => {
                      // setSelectedCV({
                      //   docx: resp,
                      // });
                      setSelectedFile({
                        file: file,
                        docx: resp,
                      }); // Set the file in the desired state
                    },
                    (error) => {
                      console.log('error', error);
                    }
                  );
                } catch (error) {
                  console.log('error', error);
                }
              } else {
                setSelectedFile({
                  file: file,
                  docx: null,
                }); // Set the file in the desired state
              }
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
            }
            enqueueSnackbar('File downloaded successfully', { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar('Error downloading file', { variant: 'error' });
          });
      } catch (error) {
        enqueueSnackbar('Error downloading file' + error, { variant: 'error' });
      }
    };

    const handleApproveCandidates = () => {
      try{
        Post(
          {
            role_id: roleId,
            candidate_ids: selected
          },
          API_URLS.approveCandidatesInBulk,
          (resp) => {
            enqueueSnackbar('Candidates approved successfully', { variant: 'success' });
            setOpenAcceptDialog(false);
            setSelected([]);
            onReload();
          },
          (error) => {
            enqueueSnackbar('Error approving candidates', { variant: 'error' });
            setOpenAcceptDialog(false);
            setSelected([]);
          }
        )
      }
      catch(error){
        enqueueSnackbar('Error approving candidates', { variant: 'error' });
      }
    }

    const handleRejectCandidates = () => {
      try{
        Post(
          {
            role_id: roleId,
            candidate_ids: selected
          },
          API_URLS.rejectCandidatesInBulk,
          (resp) => {
            enqueueSnackbar('Candidates rejected successfully', { variant: 'success' });
            setOpenRejectDialog(false);
            setSelected([]);
            onReload();
          },
          (error) => {
            enqueueSnackbar('Error rejecting candidates', { variant: 'error' });
            setOpenRejectDialog(false);
            setSelected([]);
          }
        )
      }
      catch(error){
        enqueueSnackbar('Error rejecting candidates', { variant: 'error' });
      }
    }

  return (
    <div style={{ marginTop: '1rem' }}>
      {selected?.length > 0 ? (
        <>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <p style={{ fontSize: '20px', color: '#838383' }}>{selected?.length} &nbsp; Candidate Selected</p>
            {/* <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '10px',
                marginLeft: '20px',
              }}
              onClick={() => {
                setOpenAssociateJobDialog(true);
              }}
            >
              Associate to Another Role
            </Button> */}
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '10px',
                marginLeft: '20px',
              }}
              onClick={() => {
                setOpenAcceptDialog(true);
              }}
            >
              Approve candidates
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                height: '45px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '15px',
                padding: '10px',
                marginLeft: '20px',
              }}
              onClick={() => {
                setOpenRejectDialog(true);
              }}
            >
              Reject candidates
            </Button>
          </Box>
        </>
      ) : null}
      <br />
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, borderRadius: 4, backgroundColor: 'white' }}>
        {/* {showButtons && ( */}
        {buttons?.length > 0 && (
          <>
            <Box
              sx={{ width: '95%', border: '1px solid rgba(0, 0, 0, 0.12)', margin: 'auto', backgroundColor: '#F4F9FD' }}
            >
              <Stack direction="row">
                {buttons?.map((button) => (
                  <Button
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 2,
                      outline: 'none',
                      flexGrow: 1,
                      border: 'none',
                      '&:hover': {
                        backgroundColor: '#F4F9FD',
                        outline: 'none',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderTop: `7px solid ${button?.borderTop}`,
                      },
                      '&:focus, &:active': {
                        outline: 'none',
                        borderTop: `7px solid ${button?.borderTop}`,
                        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        boxShadow: 'none',
                      },
                      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                      borderTop: `7px solid ${button?.borderTop}`,
                      borderRadius: 0,
                    }}
                    onClick={() => onButtonClick(button?.buttonText)}
                  >
                    <p style={{ fontSize: '20px' }}>
                      {button?.buttonText === 'Total' || button?.buttonText === 'Database'
                        ? candidates?.data?.length
                        : 0}
                    </p>
                    <p style={{ fontSize: `${button?.buttonFontSize}`, color: '#838383' }}>{button?.buttonText}</p>
                  </Button>
                ))}
              </Stack>
            </Box>
          </>
        )}

        {/* )} */}

        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Candidate name</TableCell>
                {showHiringPipeline && <TableCell style={{ textAlign: 'center' }}>Hiring Pipeline</TableCell>}
                <TableCell style={{ textAlign: 'left' }}>Stage</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Number</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Email</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Resourcer</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from(new Array(rowsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Skeleton variant="reactangular" width={24} height={24} />
                        <Skeleton variant="rectangular" width={16} height={16} style={{ marginLeft: '8px' }} />
                        <Skeleton variant="rectangular" width={16} height={16} style={{ marginLeft: '8px' }} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Skeleton variant="text" width="60%" />{' '}
                    </TableCell>{' '}
                    {showHiringPipeline && (
                      <TableCell>
                        <Skeleton variant="text" width="40%" />
                      </TableCell>
                    )}{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="30%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="50%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>
                  </TableRow>
                ))
              ) : candidates?.data?.length > 0 ? (
                <>
                  {candidates?.data?.map((row, index) => (
                    <TableRow key={index} selected={selected.includes(row.id)}>
                      <TableCell>
                        <Box display="flex" alignItems="center" justifyContent="space-around">
                          {/* Checkbox for selection */}
                          <Checkbox
                            checked={selected?.includes(row.id)}
                            onChange={() => handleSelect(row.id)}
                            inputProps={{ 'aria-label': 'select row' }}
                            sx={{
                              color: '#808080',
                              '&.Mui-checked': {
                                color: '#00C1FE', // Set checkbox color to blue when checked
                              },
                            }}
                          />
                          {/* <Tooltip title="Add Candidate to Role" placement="top">
                            <img
                              src={AddCandidateToRole}
                              alt="Add CV to role"
                              width="16px"
                              height="16px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setOpenAssociateJobDialog(true);
                              }}
                            />
                          </Tooltip> */}
                          <Tooltip title="View CV" placement="top">
                            <img
                              src={ViewResume}
                              alt="View CV"
                              width="12px"
                              height="16px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                // setOpenViewCandidateCVDialog(true);
                                if (row?.source === 'Candidates') {
                                  // setOpenViewCandidateCVDialog(true);
                                  // setSelectedCandidate(row);
                                  handleCVDownload(row?.id, 'view', row?.full_name);
                                } else {
                                  setViewLI(true);
                                  setSelectedCandidate(row);
                                }
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          // onClick={() =>
                          //   navigate(`/recruiter/candidateInfo/${roleId}/${row?.id}`, {
                          //     state: { previousPath: location.pathname }, // Pass the current path as state
                          //   })
                          // }
                        >
                          {/* Avatar with the first character */}
                          <Avatar
                            style={{
                              marginRight: '8px', // Adds space between avatar and name
                              backgroundColor: '#4CD4FF',
                              color: '#fff',
                              width: '30px',
                              height: '30px',
                              fontSize: '16px',
                              textAlign: 'center',
                            }}
                            src={row?.profile_image ? row?.profile_image : row?.first_name?.charAt(0)}
                          >
                            {row?.profile_image ? row?.profile_image : row?.first_name?.charAt(0)}
                          </Avatar>

                          {/* Candidate name */}
                          <p
                            style={{
                              // color: '#4CD4FF',
                              fontWeight: 'bold',
                              margin: 0,
                            }}
                          >
                            {row?.first_name} {row?.last_name}
                          </p>
                        </div>
                      </TableCell>
                      {showHiringPipeline && (
                        <TableCell sx={{ textAlign: 'center' }}>
                          {/* Render the HiringPipelineStepper */}
                          <HiringPipelineStepper
                            pipeline={
                              row?.source === 'Candidates' ? row?.candidate_pipeline : row?.lis_candidate_pipeline
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <p>Screening</p>
                      </TableCell>
                      <TableCell>
                        <p>{row?.personal_phone ? row?.personal_phone : 'N/A'}</p>
                      </TableCell>
                      <TableCell>
                        <p>{row?.personal_email ? row?.personal_email : 'N/A'}</p>
                      </TableCell>
                      <TableCell>
                        <p>
                          {row?.cv_adder?.first_name} {row?.cv_adder?.last_name}
                        </p>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            backgroundColor: getStatusBackgroundColor(row?.candidate_status),
                            color: getColor(row?.candidate_status),
                            padding: '6px',
                            width: '120px',
                            margin: '5px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderRadius: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {row?.candidate_status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan={10} sx={{ textAlign: 'center' }}>
                      No records found
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display={'flex'} justifyContent="flex-end" alignItems="flex-end">
          Total :{totalCandidates ? totalCandidates : 0} Candidates
        </Box>
        {/* <TablePagination
          component="div"
          count={totalCandidates ? totalCandidates : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10]} // Optional: Different options for rows per page
        /> */}
      </Box>
      {openAssociateJobDialog && (
        <AssociateCandidateToOtherRoleDialog
          open={openAssociateJobDialog}
          onClose={() => setOpenAssociateJobDialog(false)}
        />
      )}
      {openViewCandidateCVDialog && (
        <ViewCandidateResumeDialog
          open={openViewCandidateCVDialog}
          onClose={() => setOpenViewCandidateCVDialog(false)}
          candidate={selectedCandidate}
        />
      )}
      {openAcceptDialog && (
        <ActionConfirmationDialog
          open={openAcceptDialog}
          onClose={() => setOpenAcceptDialog(false)}
          title={`Are you sure you want to approve the selected candidates for this role?`}
          actionButtonText="Yes"
          onSubmit={() => {
            handleApproveCandidates();
          }}
        />
      )}
      {openRejectDialog && (
        <ActionConfirmationDialog
          open={openRejectDialog}
          onClose={() => setOpenRejectDialog(false)}
          title={`Are you sure you want to reject the selected candidates for this role?`}
          actionButtonText="Yes"
          onSubmit={() => {
            handleRejectCandidates();
          }}
        />
      )}
      {viewLI && (
        <CVFormat
          open={viewLI}
          onClose={() => setViewLI(false)}
          openToWork={true}
          candidate={selectedCandidate}
          // downloadLiAsPdf={downloadLiAsPdf}
        />
      )}
      {openCVDialog && (
        <Dialog
          open={openCVDialog}
          onClose={() => setOpenCVDialog(false)}
          PaperProps={{
            sx: {
              width: '80vw', // Fixed width
              maxWidth: '900px', // Optional max-width for larger screens
              height: '90vh', // Fixed height
              maxHeight: '90vh', // Ensures it fits within viewport
              backgroundColor: 'rgba(255, 255, 255, 0.9)', // Background color adjustment
              overflow: 'hidden', // Ensures the Box handles scrolling
            },
          }}
        >
          {selectedFile ? (
            <Box
              sx={{
                height: '100%',
                overflowY: 'auto',
                padding: '20px',
              }}
            >
              {selectedFile?.file?.type === 'application/pdf' ? (
                <>
                  <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                    Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                  </Typography>
                  <iframe
                    title={selectedFile?.file?.name}
                    src={URL.createObjectURL(selectedFile?.file)}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                  ></iframe>
                </>
              ) : selectedFile?.file?.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                <>
                  <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                    Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                  </Typography>
                  <div
                    style={{
                      width: '100%',
                      height: '100vh',
                      overflowY: 'auto',
                      padding: '20px',
                      border: '1px solid #00C1FE',
                      borderRadius: '10px',
                    }}
                    dangerouslySetInnerHTML={{ __html: selectedFile?.docx?.htmlContent }}
                  ></div>
                </>
              ) : (
                <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                  No Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                </Typography>
              )}
            </Box>
          ) : (
            <Typography variant="subtitle1" sx={{ margin: '20px' }}>
              No CV selected for preview
            </Typography>
          )}
        </Dialog>
      )}
    </div>
  );
}

export default CandidatePipelineTable;

const HiringPipelineStepper = ({ pipeline }) => {
  // console.log('dsfjdspipeline', pipeline);
  const steps = ['Screening', 'Submission', 'Rejected', 'Interview', 'Offered', 'Hired'];
 let stageMapping = {};
  // Create a mapping of stages from the pipeline
  if(pipeline?.length > 0) {
     stageMapping = pipeline?.reduce((acc, { stage }) => {
      acc[stage] = true;
      return acc;
    }, {});
  }else{
     stageMapping = {};
  }

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      {steps?.map((step, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
          {stageMapping[step] ? (
            <Tooltip placement="top" title={step}>
              <CheckCircle sx={{ color: '#00bfff', fontSize: 22, margin: 0 }} />
            </Tooltip>
          ) : (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 0,
                marginRight: 0,
              }}
            >
              <Tooltip placement="top" title={step}>
                <RadioButtonUnchecked sx={{ color: '#00bfff', fontSize: 24 }} />
              </Tooltip>
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  fontSize: '12px',
                  color: '#00bfff',
                  fontWeight: 'bold',
                }}
              >
                {index + 1}
              </Typography>
            </Box>
          )}
          {index !== steps.length - 1 && (
            <Box sx={{ width: 20, height: 2.5, backgroundColor: '#00bfff', margin: '1px' }} />
          )}
        </Box>
      ))}
    </Stack>
  );
};
