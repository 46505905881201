import { Box, Typography } from '@mui/material';
import React from 'react';
import { OpenToWork } from 'src/assets/images';

function CandidateCVFormat({ candidate }) {
  return (
    <div>
      {/* <div> */}
      {candidate?.profile_type === 'openToWork' && (
        <a
          href="#"
          style={{
            position: 'sticky', // Change position to sticky
            top: 0, // Stick to the top
            zIndex: 1, // Ensure it's above other content
            backgroundColor: '#57CC99',
            color: 'white',
            width: '130px',
            padding: '5px 10px',
            textDecoration: 'none',
            fontSize: '14px',
            left: '0px', // Adjust the left property
          }}
        >
          Open to work
        </a>
      )}
      <div style={{ padding: '3rem', width: '95%' }}>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '80%',
            }}
          >
            {candidate?.profile_type === 'openToWork' && (
              <img
                src={OpenToWork || 'https://via.placeholder.com/150'}
                alt="profile"
                style={{ width: '150px', height: '150px', borderRadius: '50%' }}
              />
            )}
            <p style={{ fontSize: '26px', fontWeight: 'bold' }}>{candidate?.full_name || 'Dora Opare'}</p>
            <img
              src={candidate?.profile_image || 'https://via.placeholder.com/150'}
              alt="profile"
              style={{ width: '150px', height: '150px', borderRadius: '50%' }}
            />
          </Box>

          <Box display="flex" marginTop="10px">
            <p style={{ fontSize: '16px' }}>
              {candidate?.location || 'GC479, Lane 21 Oniyinase RD, Aoshie - Accra'} {/* Default location */}
              {/* Conditional rendering for personal email */}
              {candidate?.personal_email && (
                <>
                  {' | '}
                  <span style={{ color: '#3F8CFF', marginLeft: '5px', marginRight: '5px' }}>
                    {candidate.personal_email}
                  </span>
                </>
              )}
              {/* Conditional rendering for business email */}
              {candidate?.business_email && (
                <>
                  {' | '}
                  <span style={{ color: '#3F8CFF', marginLeft: '5px', marginRight: '5px' }}>
                    {candidate.business_email}
                  </span>
                </>
              )}
              {/* Conditional rendering for personal contact */}
              {candidate?.personal_contact && (
                <>
                  {' | '}
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>{candidate.personal_contact}</span>
                </>
              )}
              {/* Conditional rendering for business number */}
              {candidate?.business_number && (
                <>
                  {' | '}
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>{candidate.business_number}</span>
                </>
              )}
              {/* Conditional rendering for LinkedIn profile */}
              {candidate?.profile_link && (
                <>
                  {' | '}
                  <a
                    href={candidate.profile_link}
                    target="_blank"
                    style={{ color: '#3F8CFF', marginLeft: '5px', marginRight: '5px' }}
                  >
                    {candidate.profile_link}
                  </a>
                </>
              )}
            </p>
          </Box>
        </Box>

        {/* About section */}
        <Box display="flex" marginLeft="20px" marginTop="15px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>About</p>
        </Box>
        <hr
          style={{
            color: '#B0B0B0',
            height: '3px',
            border: 'none',
            backgroundColor: '#CAC5C5',
            marginLeft: '20px',
          }}
        />
        <Box display="flex" marginLeft="20px" marginTop="10px">
          <p style={{ fontSize: '16px' }}>{candidate?.about?.replace('Possible summary:', '') || 'N/A'}</p>
        </Box>

        <Box display="flex" marginLeft="20px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Education</p>
        </Box>
        <hr
          style={{
            color: '#B0B0B0',
            height: '3px',
            border: 'none',
            backgroundColor: '#CAC5C5',
            marginLeft: '20px',
          }}
        />
        {candidate?.education?.map((edu, index) => (
          <Box key={index} display="flex" marginLeft="20px" justifyContent="center" flexDirection="column">
            <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>{edu.institution}</p>
            <p style={{ fontSize: '14px', marginTop: '5px' }}>{edu.degree}</p>
            <p style={{ fontSize: '14px', marginTop: '5px' }}>{edu.duration}</p>
          </Box>
        ))}

        <Box display="flex" marginLeft="20px" marginTop="15px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Skills</p>
        </Box>

        <Box display="flex" marginLeft="20px" flexDirection="column">
          <hr
            style={{
              color: '#B0B0B0',
              height: '3px',
              border: 'none',
              backgroundColor: '#CAC5C5',
              marginLeft: '0px',
            }}
          />
          <Box
            component="div"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)', // 2 skills per row by default
              gap: '10px', // Space between items
              // border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '10px',

              '@media (min-width: 600px)': {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },

              '@media print': {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            }}
          >
            {candidate?.skills?.map((skill, index) => (
              <Box
                key={index}
                sx={{
                  fontSize: '16px',
                  width: '90%',
                  padding: '5px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '4px',
                  textAlign: 'center',
                }}
              >
                {skill}
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex" marginLeft="20px" marginTop="15px" justifyContent="center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '18px' }}>Experience</p>
        </Box>
        <hr
          style={{
            color: '#B0B0B0',
            height: '3px',
            border: 'none',
            backgroundColor: '#CAC5C5',
            marginLeft: '20px',
          }}
        />
        {candidate?.experience?.map((exp) =>
          exp.positions.map((position) => (
            <Box display="flex" justifyContent="space-between" flexDirection="column">
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '18px' }}>{position.title}</p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>{exp.company}</p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>
                {position?.startDate + '- ' + position?.endDate} ,
                {position.duration !== 'N/A' ? position.duration : 'Present'}
              </p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>{position.location}</p>
              <p style={{ fontSize: '14px', marginTop: '5px' }}>{position.responsibilities}</p>
            </Box>
            // </Box>
          ))
        )}
      </div>
      {/* Put Powered bu Ultimate outsourcing LTD on the bottom right corner */}
      <Box
        sx={{
          position: 'relative', // Keep it fixed to the bottom-right of the viewport
          bottom: '10px',
          left: '900px',
          color: '#B0B0B0',
          fontSize: '12px',
          zIndex: 1000, // Ensure it stays on top of other elements
        }}
      >
        <Typography>
          <a
            href="https://ultimateoutsourcing.co.uk/" // Replace with your actual website URL
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#B0B0B0' }}
          >
            Powered by Ultimate Outsourcing LTD
          </a>
        </Typography>
      </Box>
    </div>
  );
}

export default CandidateCVFormat;
