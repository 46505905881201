import React, { useEffect, useState } from 'react';
import './sms.css';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { Send } from '@mui/icons-material';
import io from 'socket.io-client';
import { smsdb } from 'src/config/firebase';
import { ref, set, onValue } from 'firebase/database';
import { smsauth } from 'src/config/firebase';
import {  signInWithEmailAndPassword } from "firebase/auth";
const db = smsdb;

const socket = io('https://btds28q8-5000.inc1.devtunnels.ms/');

function SMSInterface({ selectedCandidate, onClose, title, location, onSMSSent, candidateNumber, sender }) {
  // console.log('ejriewi', selectedCandidate);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState();
  const [senderNumber, setSenderNumber] = useState({ number: sender });
  const [openAskNumberDialog, setOpenAskNumberDialog] = useState(false);

  useEffect(() => {
    if (messages?.length === 0) {
      const message = `Hi ${
        selectedCandidate?.full_name?.split(' ')[0]
      }, My client is looking for "${title}" in "${location}". Are you interested to explore more?`;
      setInput(message);
    } else {
      setInput('');
    }
  }, [messages]);

  console.log("Dsakdjisad", sender , senderNumber)
  const handleSendMessage = async () => {
    if (input?.trim()) {
      const messageData = {
        text: input,
        sender: senderNumber?.number,
        status: 'pending',
        timestamp: new Date().toISOString(),
      };

      const to = `${candidateNumber}`;
      const name = selectedCandidate?.full_name?.split(' ')[0];
      socket.emit('send-sms', {
        sender: senderNumber?.number.replace('whatsapp:', ''),
        to,
        name,
        location,
        title,
        message: messageData,
      });
      setInput('');
    }
  };

  const fetchMessages = async (userNo, chatWith) => {
    setIsLoading(true);
  
    // Set email and password based on userNo
    let email, password;
  
    if (userNo === '+15078586181') {
      email = 'ultimate.develper123@gmail.com';
      password = 'ultimate1122';
    } else if (userNo === '+447400270472') {
      email = 't6902901@gmail.com';
      password = 'ultimate1122';
    } else {
      console.error('User number is not recognized');
      setIsLoading(false);
      return;
    }
  
    try {
      // Sign in the user with the selected email and password
      const userCredential = await signInWithEmailAndPassword(smsauth, email, password);
      const user = userCredential.user;
      console.log('Signed in as:', user.uid);
  
      const messagesPath = `users/${user.uid}/chats/${chatWith}/messages`;
      const messageRef = ref(db, messagesPath);
  
      // Real-time listener
      onValue(
        messageRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
  
            // Convert object to array and avoid duplicates
            const messagesArray = Object.values(data);
            setMessages(messagesArray);
          } else {
            console.log('No messages found.');
            setMessages([]);
          }
          setIsLoading(false);
        },
        (error) => {
          console.error('Error fetching messages:', error);
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.error('Error signing in:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (candidateNumber && sender) {
      const number = senderNumber?.number;
      fetchMessages(number, candidateNumber);
    }
  }, [selectedCandidate]);
  const isSenderNumber = () => {
    if (!sender && !senderNumber?.number) {
      setOpenAskNumberDialog(true);
    } else {
      handleSendMessage();
    }
  };

  // Write messages to Firebase
  const saveMessageToFirebase = async (userNo, chatWith, messageData) => {
    try {
      // Set email and password based on userNo
      let email, password;
  
      if (userNo === '+15078586181') {
        email = 'ultimate.develper123@gmail.com';
        password = 'ultimate1122';
      } else if (userNo === '+447400270472') {
        email = 't6902901@gmail.com';
        password = 'ultimate1122';
      } else {
        throw new Error('User number is not recognized');
      }
  
      // Sign in the user with the selected email and password
      const userCredential = await signInWithEmailAndPassword(smsauth, email, password);
      const user = userCredential.user;
      console.log('Signed in as:', user.uid);
  
      const sanitizedTimestamp = messageData?.timestamp?.replace(/[:.]/g, '-') || Date.now();
      const messagePath = `users/${user.uid}/chats/${chatWith}/messages/${sanitizedTimestamp}`;
      const messageRef = ref(db, messagePath);
  
      await set(messageRef, messageData);
      console.log('Message saved successfully!');
      fetchMessages(userNo, chatWith);
    } catch (error) {
      console.error('Error saving message to Firebase:', error);
    }
  };
  useEffect(() => {
    socket.on('add-sms-to-firebase', (msgStatus) => {
      console.log('stasmstus', msgStatus);
      const { message } = msgStatus;
      const messageToSave = {
        messageSid: message?.sid,
        sender: message?.from,
        text: message?.body,
        status: 'sent',
        msgType: 'text',
        timestamp: new Date().toISOString(),
      };
      // Remove 'whatsapp:' from the phone numbers
      const fromNumber = message?.from;
      const toNumber = message?.to;
      saveMessageToFirebase(fromNumber, toNumber, messageToSave);
    });
    return () => {
      socket.off('add-sms-to-firebase');
    };
  }, [messages]);

  useEffect(() => {
    socket.on('add-sms-log', (message) => {
      onSMSSent(message);
    });
    return () => {
      socket.off('add-sms-log');
    };
  }, []);
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
        height: '660px',
        margin: '0',
        borderRadius: '10px',

        overflow: 'hidden',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: 'flex',
          backgroundColor: '#1A84DE',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 15px',
          zIndex: 1,
          // Ensure the text is visible
        }}
      >
        <Box display="flex" alignItems="center">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              style={{
                marginRight: '8px', // Adds space between avatar and name
                backgroundColor: '',
                color: '#fff',
                width: '50px',
                height: '50px',
                fontSize: '16px',
                textAlign: 'center',
                marginLeft: '7px',
              }}
            >
              <Icon icon="heroicons:user-16-solid" style={{ fontSize: '24px' }} />
            </Avatar>
            <p style={{ fontSize: '16px', marginLeft: '7px', color: 'white' }}>{selectedCandidate?.personal_phone}</p>
          </div>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Icon
            icon="bitcoin-icons:cross-filled"
            style={{ fontSize: '22px', cursor: 'pointer', color: 'white' }}
            onClick={() => onClose()}
          />
        </Box>
      </div>
      <div
        style={{
          flex: 1,
          padding: '10px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          backgroundColor: 'white',
        }}
      >
        {isLoading ? (
          //show circular progress in center
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress thickness={2} />
          </div>
        ) : (
          <>
            {messages?.map((msg) => (
              <div
                key={msg.id}
                style={{
                  position: 'relative',
                  maxWidth: '70%',
                  padding: '10px',
                  borderRadius: '8px',
                  border:
                    msg?.sender === '+447400270472' || msg?.sender === '+15078586181'
                      ? '1px solid #1A84DE'
                      : '1px solid #E3E3E3',
                  wordWrap: 'break-word',
                  backgroundColor:
                    msg?.sender === '+447400270472' || msg?.sender === '+15078586181' ? '#1A84DE' : '#F6F6F6',
                  alignSelf:
                    msg?.sender === '+447400270472' || msg?.sender === '+15078586181' ? 'flex-end' : 'flex-start',
                }} // Add relative positioning for better control
              >
                <p
                  style={{
                    fontSize: '14px',
                    marginBottom: 0,
                    color: msg?.sender === '+447400270472' || msg?.sender === '+15078586181' ? 'white' : 'black',
                  }}
                >
                  {msg.text}
                </p>

                {/* Timestamps and ticks */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '5px',
                    fontSize: '12px',
                    color: msg?.sender === '+447400270472' || msg?.sender === '+15078586181' ? 'white' : 'black',
                  }}
                >
                  {/* Format timestamp */}
                  <span>
                    {new Date(msg.timestamp).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="chat-footer">
        <TextField
          placeholder="Text message"
          fullWidth
          value={input}
          onKeyPress={(e) => {
            e.key === 'Enter' && isSenderNumber();
          }}
          onChange={(e) => setInput(e.target.value)}
          sx={{
            borderRadius: '25px',
            padding: '5px 5px',
            textAlign: 'center',
            height: '50px',
            overflowY: 'scroll',
            minHeight: '50px',
            backgroundColor: '#E6EBF5',
            '& .MuiOutlinedInput-root': {
              borderRadius: '40px',
              '& fieldset': {
                border: 'none', // Remove border
              },
            },
            '&:hover': {
              backgroundColor: '#E6EBF5', // Keep background color same on hover
            },
          }}
          size="small"
        />

        <Button
          variant="contained"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginLeft: '5px',
            backgroundColor: '#1A84DE',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 'unset',
            '&:hover': {
              backgroundColor: '#1A84DE',
              color: 'white',
            },
          }}
          onClick={() => {
            if (!sender && !senderNumber?.number) {
              setOpenAskNumberDialog(true);
            } else {
              handleSendMessage();
            }
          }}
        >
          <Send fontSize="small" />
        </Button>
      </div>
      {
        openAskNumberDialog && (
          <AskForSenderNumber
            candidateNumber={selectedCandidate?.personal_phone}
            onSelection={(number) => {
              console.log('Selected number:', number);
              setSenderNumber((prevState) => ({ ...prevState, number: number }));
              setOpenAskNumberDialog(false);
            }}
            open={openAskNumberDialog}
            onClose={() => setOpenAskNumberDialog(false)}
          />
        ) // Ask for sender number dialog
      }
    </div>
  );
}

export default SMSInterface;

const AskForSenderNumber = ({ candidateNumber, onSelection, open, onClose }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
            minHeight: '20vh',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle>
          <Box display="flex">
            <Typography variant="h4" className="ml-8 text-center ">
              Warning
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Box display="flex">
            <Typography variant="subtitle1" className="ml-8 text-center ">
              The number to which you are trying to send SMS is an international number ({candidateNumber}). Currently , we
              do not support sending SMS messages to the international numbers.
            </Typography>
          </Box>
          <br />
          {/* <Box display="flex" alignItems="center" justifyContent="center">
            <p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>Note: Extra charges may apply.</p>
          </Box> */}
          <br />
          {/* <Box display="flex" justifyContent="space-around">
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#00C1FE',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              onClick={() => onSelection('whatsapp:+15078586181')}
            >
              +15078586181
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#00C1FE',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              onClick={() => onSelection('whatsapp:+447400270472')}
            >
              +447400270472
            </Button>
          </Box> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
