import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { styled } from '@mui/styles';
import { Icon } from '@iconify/react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { ref, set, onValue } from 'firebase/database';
import { wpdb } from 'src/config/firebase';
import { wpauth } from 'src/config/firebase';
import {  signInWithEmailAndPassword } from "firebase/auth";
const db = wpdb;

const socket = io('https://btds28q8-5000.inc1.devtunnels.ms/');

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Chat = ({ selectedCandidate, title, location, onClose, onMessageSent, candidateNumber, sender }) => {
  // console.log('selectedCandidate', selectedCandidate, candidateNumber, sender);
  console.log('dasfdsds', wpauth);
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [isError, setIsError] = useState(false);
  const [showMediaMessage, setShowMediaMessage] = useState('');
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState();
  // const [mediaUrl, setMediaUrl] = useState();
  const [senderNumber, setSenderNumber] = useState({ number: sender });
  const [openAskNumberDialog, setOpenAskNumberDialog] = useState(false);
  // Fetch messages from Firebase


  const fetchMessages = async (userNo, chatWith) => {
    setIsLoading(true);
  
    // Set email and password based on userNo
    let email, password;
  
    if (userNo === '+15078586181') {
      email = 'ultimate.develper123@gmail.com';
      password = 'ultimate1122';
    } else if (userNo === '+447400270472') {
      email = 't6902901@gmail.com';
      password = 'ultimate1122';
    } else {
      console.error('User number is not recognized');
      setIsLoading(false);
      return;
    }
  
    try {
      // Sign in the user with the selected email and password
      const userCredential = await signInWithEmailAndPassword(wpauth, email, password);
      const user = userCredential.user;
      console.log('Signed in as:', user.uid);
  
      const messagesPath = `users/${user.uid}/chats/${chatWith}/messages`;
      const messageRef = ref(db, messagesPath);
  
      // Real-time listener
      onValue(
        messageRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
  
            // Convert object to array and avoid duplicates
            const messagesArray = Object.values(data);
            setMessages(messagesArray);
          } else {
            console.log('No messages found.');
            setMessages([]);
          }
          setIsLoading(false);
        },
        (error) => {
          console.error('Error fetching messages:', error);
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.error('Error signing in:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (candidateNumber && sender) {
      const number = senderNumber?.number?.replace('whatsapp:', '');
      fetchMessages(number, candidateNumber);
    }
  }, [selectedCandidate]);

  useEffect(() => {
    // if file is selected then empty the message
    if (file) {
      setInput('');
    }
  }, []);

  useEffect(() => {
    if (messages?.length === 0) {
      const message = `
    Hi ${
      selectedCandidate?.full_name?.split(' ')[0]
    }, Coming across your profile, I thought you are a great match for my client's ${title} role in ${location}.
    
    Are you interested and open to explore more?
    `;
      setInput(message);
    } else {
      setInput('');
    }
  }, [messages]);

  // console.log('medsfsssages', messages);
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://btds28q8-5000.inc1.devtunnels.ms/uploadToTwilioAsset', {
        method: 'POST',
        body: formData,
      });

      const url = await response.json();
      console.log('Uploaded file URL:', url);
      return url?.url;
      // console.log('Uploaded file URL:', response);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSendMessage = async () => {
    console.log('candidateNumber', senderNumber);
    if (input.trim()) {
      const messageData = {
        text: input,
        // sender: '+15078586181',
        sender: senderNumber?.number,
        status: 'pending',
        timestamp: new Date().toISOString(),
      };

      const to = `whatsapp:${candidateNumber}`;
      // get the first word from full name
      const name = selectedCandidate?.full_name?.split(' ')[0];
      socket.emit('send-message', {
        from: senderNumber?.number,
        to,
        message: messageData,
        name,
        title,
        location,
        isTemplate: messages?.length === 0 ? true : false,
      });
      setInput('');
    } else if (file) {
      const to = `whatsapp:${candidateNumber}`;
      setIsSendingFile(true);
      const mediaUrl = await uploadFile(file);
      // setMediaUrl(mediaUrl);
      const filename = file?.name;
      const fileType = file?.type;
      socket.emit('send-media-message', {
        from: senderNumber?.number,
        to,
        mediaUrl,
        filename,
        fileType,
      });
      setFile(null);
    }
  };
  useEffect(() => {
    // Listen for errors from the server
    socket.on('send-error', (error) => {
      // alert(error.message); // Display a user-friendly error message
      setIsError(true);
      console.error('Detailed error:', error.errorDetails);
    });

    return () => {
      socket.off('send-error'); // Cleanup on component unmount
    };
  }, []);

  useEffect(() => {
    //Listen for bulding status
    socket.on('build-status', (status) => {
      // check the status and show the message accordingly
      if (status === 'building') {
        setIsSendingFile(false);
        setShowMediaMessage('Uploading file...');
      } else if (status === 'completed') {
        setShowMediaMessage('Preparing to send...');
      }
    });

    return () => {
      socket.off('build-status');
    };
  }, []);

  // console.log('messages', messages);
  //---------------------------------------- ADD MSG TO FIREBASE ------------------------------------------------

  // Write messages to Firebase
  const saveMessageToFirebase = async (userNo, chatWith, messageData) => {
    try {
      // Set email and password based on userNo
      let email, password;
  
      if (userNo === '+15078586181') {
        email = 'ultimate.develper123@gmail.com';
        password = 'ultimate1122';
      } else if (userNo === '+447400270472') {
        email = 't6902901@gmail.com';
        password = 'ultimate1122';
      } else {
        throw new Error('User number is not recognized');
      }
  
      // Sign in the user with the selected email and password
      const userCredential = await signInWithEmailAndPassword(wpauth, email, password);
      const user = userCredential.user;
      console.log('Signed in as:', user.uid);
  
      const sanitizedTimestamp = messageData?.timestamp?.replace(/[:.]/g, '-') || Date.now();
      const messagePath = `users/${user.uid}/chats/${chatWith}/messages/${sanitizedTimestamp}`;
      const messageRef = ref(db, messagePath);
  
      await set(messageRef, messageData);
      console.log('Message saved successfully!');
      fetchMessages(userNo, chatWith);
    } catch (error) {
      console.error('Error saving message to Firebase:', error);
    }
  };
  

  useEffect(() => {
    socket.on('add-to-firebase', (msgStatus) => {
      // console.log('status', msgStatus);
      const { message } = msgStatus;
      const messageToSave = {
        messageSid: message?.sid,
        sender: message?.from?.replace('whatsapp:', ''),
        text: message?.body,
        status: 'sent',
        msgType: 'text',
        timestamp: new Date().toISOString(),
      };
      // Remove 'whatsapp:' from the phone numbers
      const fromNumber = message?.from?.replace('whatsapp:', '');
      const toNumber = message?.to?.replace('whatsapp:', '');
      saveMessageToFirebase(fromNumber, toNumber, messageToSave);
    });
    return () => {
      socket.off('add-to-firebase');
    };
  }, [messages]);

  useEffect(() => {
    socket.on('add-media-to-firebase', (msgStatus) => {
      console.log('sdasdstatus', msgStatus);
      const { message, fileName, mediaUrl, fileType } = msgStatus;
      const messageToSave = {
        messageSid: message?.sid,
        mediaUrl: mediaUrl,
        mediaType: fileType,
        filename: fileName,
        sender: message?.from?.replace('whatsapp:', ''),
        text: fileName,
        status: 'sent',
        msgType: 'document',
        timestamp: new Date().toISOString(),
      };
      const fromNumber = message?.from?.replace('whatsapp:', '');
      const toNumber = message?.to?.replace('whatsapp:', '');
      saveMessageToFirebase(fromNumber, toNumber, messageToSave);
    });
    return () => {
      socket.off('add-media-to-firebase');
    };
  }, []);
  useEffect(() => {
    socket.on('add-log', (message) => {
      onMessageSent(message);
    });
    return () => {
      socket.off('add-log');
    };
  }, []);

  // console.log('messagedasmdnf', messages);
  const isSenderNumber = () => {
    if (!sender && !senderNumber?.number) {
      setOpenAskNumberDialog(true);
    } else {
      handleSendMessage();
    }
  };
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
        height: '660px',
        margin: '0',
        borderRadius: '10px',

        overflow: 'hidden',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.30)',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: 'flex',
          backgroundColor: '#085B53',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 15px',
          zIndex: 1,
          // Ensure the text is visible
        }}
      >
        <Box display="flex" alignItems="center">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              style={{
                marginRight: '8px', // Adds space between avatar and name
                backgroundColor: '',
                color: '#fff',
                width: '50px',
                height: '50px',
                fontSize: '16px',
                textAlign: 'center',
                marginLeft: '7px',
              }}
            >
              <Icon icon="heroicons:user-16-solid" style={{ fontSize: '24px' }} />
            </Avatar>
            <p style={{ fontSize: '16px', marginLeft: '7px', color: 'white' }}>{selectedCandidate?.personal_phone}</p>
          </div>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Icon
            icon="bitcoin-icons:cross-filled"
            style={{ fontSize: '22px', cursor: 'pointer', color: 'white' }}
            onClick={() => onClose()}
          />
        </Box>
      </div>
      <div
        style={{
          flex: 1,
          padding: '10px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          backgroundColor: '#E8E0D5',
        }}
      >
        {isLoading ? (
          //show circular progress in center
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress thickness={2} />
          </div>
        ) : (
          <>
            {messages?.map((msg) => (
              <div
                key={msg.messageSid} // Use a unique key, messageSid seems appropriate here
                style={{
                  position: 'relative',
                  maxWidth: '70%',
                  padding: '10px',
                  borderRadius: '8px',
                  wordWrap: 'break-word',
                  backgroundColor:
                    msg?.sender === '+447400270472' || msg?.sender === '+15078586181' ? '#DCF7C5' : '#FAFAFA',
                  alignSelf:
                    msg?.sender === '+447400270472' || msg?.sender === '+15078586181' ? 'flex-end' : 'flex-start',
                }} // Add relative positioning for better control
              >
                {msg.msgType === 'text' || msg.msgType === 'button' ? (
                  // Render text messages
                  <p className="chat-text">{msg?.text}</p>
                ) : msg.msgType === 'document' || msg.msgType === 'image' ? (
                  <>
                    <a
                      href={msg?.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {msg?.mediaType?.endsWith('/pdf') ? (
                        <Icon
                          icon="vscode-icons:file-type-pdf2"
                          style={{ fontSize: '22px', color: '#f82028', marginRight: '10px' }}
                        />
                      ) : msg?.mediaType?.endsWith('/msword') ||
                        msg?.mediaType?.endsWith('/vnd.openxmlformats-officedocument.wordprocessingml.document') ? (
                        <Icon icon="vscode-icons:file-type-word" style={{ fontSize: '22px', marginRight: '7px' }} />
                      ) : msg?.mediaType?.endsWith('/csv') || msg?.mediaType?.endsWith('/xlsx') ? (
                        <Icon icon="vscode-icons:file-type-excel" style={{ fontSize: '22px', marginRight: '10px' }} />
                      ) : msg?.mediaType?.endsWith('/jpg') ||
                        msg?.mediaType?.endsWith('/jpeg') ||
                        msg?.mediaType?.endsWith('/png') ? (
                        <Icon
                          icon="ic:outline-image"
                          style={{ fontSize: '24px', color: '#FFA500', marginRight: '10px' }}
                        />
                      ) : (
                        <Icon icon="line-md:file" style={{ fontSize: '22px', color: '#000', marginRight: '10px' }} />
                      )}

                      {/* File Details */}
                      <Box sx={{ flexGrow: 1 }}>
                        <p
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            whiteSpace: 'normal', // Change this to 'normal' to allow wrapping
                            overflow: 'visible', // Allow overflow to be visible
                            textOverflow: 'unset', // Remove the ellipsis for longer text
                            // maxWidth: '', // Set a maximum width
                            wordWrap: 'break-word', // Allow breaking long words
                          }}
                        >
                          {msg?.filename ? msg?.filename : 'Open file'}{' '}
                        </p>
                      </Box>
                    </a>
                  </>
                ) : null}

                {/* Timestamps and ticks */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '5px',
                    fontSize: '12px',
                    color: '#555',
                  }}
                >
                  {/* Format timestamp */}
                  <span>
                    {new Date(msg.timestamp).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </span>
                  {/* {msg.sender === '+15078586181' && ( */}
                  {msg?.sender !== candidateNumber && (
                    <>
                      {msg?.status === 'sent' ? (
                        <Icon icon="hugeicons:tick-02" style={{ fontSize: '20px', color: 'gray' }} />
                      ) : msg?.status === 'delivered' ? (
                        <Icon icon="hugeicons:tick-double-02" style={{ fontSize: '20px', color: 'gray' }} />
                      ) : msg?.status === 'read' ? (
                        <Icon icon="hugeicons:tick-double-02" style={{ fontSize: '20px', color: '#00C1FE' }} />
                      ) : msg?.status === 'undelivered' ? (
                        <Icon icon="ph:timer-thin" style={{ fontSize: '20px', color: 'red' }} />
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {file && (
        <div className="file-preview">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#F5F5F5',
              padding: '15px',
              maxWidth: '450px',
              borderRadius: '8px',
              boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
            }}
          >
            {/* File Icon */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: '#E0E0E0',
                borderRadius: '50%',
                marginRight: '10px',
              }}
            >
              {/* show icon according to file type .word icon for word file pdf icon for pdf and image icon for image and excel icon for excel and file icon for unknown extension */}
              {file?.name?.endsWith('.pdf') ? (
                <Icon icon="vscode-icons:file-type-pdf2" style={{ fontSize: '24px', color: '#f82028' }} />
              ) : file?.name?.endsWith('.doc') || file?.name?.endsWith('.docx') ? (
                <Icon icon="vscode-icons:file-type-word" style={{ fontSize: '24px' }} />
              ) : file?.name?.endsWith('.xls') || file?.name?.endsWith('.xlsx') ? (
                <Icon icon="vscode-icons:file-type-excel" style={{ fontSize: '24px' }} />
              ) : file?.name?.endsWith('.jpg') || file?.name?.endsWith('.jpeg') || file?.name?.endsWith('.png') ? (
                <Icon icon="ic:outline-image" style={{ fontSize: '24px', color: '#FFA500' }} />
              ) : (
                <Icon icon="line-md:file" style={{ fontSize: '24px', color: '#000' }} />
              )}
            </Box>

            {/* File Details */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Box component="span" display="flex" alignItems="center">
                  {file?.name?.length > 50 ? `${file?.name?.slice(0, 50)}...` : file?.name}{' '}
                </Box>
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  {file?.size ? `${(file.size / 1024).toFixed(2)} KB` : 'Unknown size'}
                </Typography>
                {isSendingFile && (
                  <>
                    <p style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={20} color="primary" />
                    </p>
                  </>
                )}
                {showMediaMessage == 'Uploading file...' && (
                  <>
                    <p style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      <Icon icon="line-md:uploading-loop" style={{ color: 'green', fontSize: '20px' }} />
                      <span style={{ fontSize: '12px', color: 'green', fontWeight: 'normal', marginLeft: '5px' }}>
                        {showMediaMessage}
                      </span>
                    </p>
                  </>
                )}
                {showMediaMessage == 'Preparing to send...' && (
                  <>
                    <p style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      <Icon
                        icon="lets-icons:done-ring-round-duotone-line"
                        style={{ color: 'green', fontSize: '20px' }}
                      />
                      <span style={{ fontSize: '12px', color: 'green', fontWeight: 'normal', marginLeft: '5px' }}>
                        {showMediaMessage}
                      </span>
                    </p>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      )}

      {/* {!file && <Typography sx={{ textAlign: 'center' }}></Typography>} */}

      {isError && (
        //show error message in snack
        <Snackbar open={isError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={() => setIsError(false)} severity="error">
            {' '}
            Message not sent{' '}
          </Alert>
        </Snackbar>
      )}
      <div className="chat-footer">
        <TextField
          placeholder="Write a message..."
          fullWidth
          disabled={file}
          value={input}
          onKeyPress={(e) => {
            e.key === 'Enter' && isSenderNumber();
          }}
          onChange={(e) => setInput(e.target.value)}
          sx={{
            borderRadius: '25px',
            padding: '5px 5px',
            textAlign: 'center',
            height: '50px',
            overflowY: 'scroll',
            minHeight: '50px',
            backgroundColor: '#E6EBF5',
            '& .MuiOutlinedInput-root': {
              borderRadius: '40px',
              '& fieldset': {
                border: 'none', // Remove border
              },
            },
            '&:hover': {
              backgroundColor: '#E6EBF5', // Keep background color same on hover
            },
          }}
          size="small"
        />

        <Button
          sx={{
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
            borderRadius: '50%',
          }}
          onClick={() => fileInputRef.current.click()}
        >
          <Icon icon="mdi:attachment-vertical" style={{ fontSize: '24px', color: 'grey' }} />
          <VisuallyHiddenInput
            type="file"
            ref={fileInputRef}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#085B53',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 'unset',
            '&:hover': {
              backgroundColor: '#085B53',
              color: 'white',
            },
          }}
          onClick={() => {
            if (!sender && !senderNumber?.number) {
              setOpenAskNumberDialog(true);
            } else {
              handleSendMessage();
            }
          }}
        >
          <Send fontSize="small" />
        </Button>
      </div>

      {
        openAskNumberDialog && (
          <AskForSenderNumber
            candidateNumber={selectedCandidate?.personal_phone}
            onSelection={(number) => {
              console.log('Selected number:', number);
              setSenderNumber((prevState) => ({ ...prevState, number: number }));
              setOpenAskNumberDialog(false);
            }}
            open={openAskNumberDialog}
            onClose={() => setOpenAskNumberDialog(false)}
          />
        ) // Ask for sender number dialog
      }
    </div>
  );
};

export default Chat;

const AskForSenderNumber = ({ candidateNumber, onSelection, open, onClose }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
            minHeight: '20vh',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle>
          <Box display="flex">
            <Typography variant="h4" className="ml-8 text-center ">
              Choose Sender Number
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Box display="flex">
            <Typography variant="subtitle1" className="ml-8 text-center ">
              The number to which you are sending message is an international number ({candidateNumber}). Please choose
              the sender number for sending message to this candidate.
            </Typography>
          </Box>
          <br />
          <Box display="flex" alignItems="center" justifyContent="center">
            <p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>Note: Extra charges may apply.</p>
          </Box>

          <br />
          <Box display="flex" justifyContent="space-around">
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#00C1FE',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              onClick={() => onSelection('whatsapp:+15078586181')}
            >
              +15078586181
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#00C1FE',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              onClick={() => onSelection('whatsapp:+447400270472')}
            >
              +447400270472
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
