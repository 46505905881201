import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import JobPostCard from './components/JobPostCard';
import { jobs_icon } from '../components/svg';
import CandidateCard from 'src/sections/candidates/CandidateCard';
import { Icon } from '@iconify/react';
import MultiDatePicker from 'src/components/MultiDatePicker';
import { direct_jobs, sr_jobs, uk_flag_wadda, world_icon } from 'src/images';

const remote = ['On-site', 'Remote', 'Hybrid'];
const jobType = ['Full-time', 'Part-time', 'Contract', 'Temporary', 'Internship'];
const experienceLevel = [
  'Entry Level',
  'Mid-Senior level',
  'Senior Level',
  'Associate',
  'Director',
  'Internship',
  'Executive',
];
function JobPostList({ country_id, sector_id, onBackClick, onSectorIdChange }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobPosts, setJobPosts] = useState([]);
  const [selectedJobPosts, setSelectedJobPosts] = useState(null);
  const [total, setTotal] = useState(0);

  // handle candidate open dialog
  const [openCandidateDialog, setOpenCandidateDialog] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);

  const [selectedCountryId, setSelectedCountryId] = useState(country_id);
  const [selectedSectorId, setSelectedSectorId] = useState(sector_id);
  const [remoteFilter, setRemoteFilter] = useState('');
  const [jobTypeFilter, setJobTypeFilter] = useState('');
  const [experienceLevelFilter, setExperienceLevelFilter] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [srCount, setSrCount] = useState(0);
  const [directCount, setDirectCount] = useState(0);

  const [selectedSector, setSelectedSector] = useState(null);
  const getAllCountriesAndSector = useCallback(() => {
    try {
      Get(
        {},
        API_URLS.getCountriesAndSector,
        (resp) => {
          setCountries(resp?.data?.countries);
          setSectors(resp?.data?.sectors);
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }, []);

  useEffect(() => {
    getAllCountriesAndSector();
  }, [getAllCountriesAndSector]);

  console.log('countries:', sectors);

  const getJobPosts = useCallback(() => {
    setLoading(true);
    Get(
      {
        page,
        pageSize,
        search,
        country_id,
        sector_id,
        // remote: remoteFilter,
        // jobType: jobTypeFilter,
        // experienceLevel: experienceLevelFilter,
        selectedCountry: selectedCountryId,
        selectedSector: selectedSectorId,
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
      API_URLS.getAllJobs,
      (resp) => {
        setLoading(false);
        setTotal(resp?.data?.totalCount);
        setJobPosts(resp?.data?.jobPosts);
        setSrCount(resp?.data?.sr_count);
        setDirectCount(resp?.data?.direct_count);
      },
      (error) => {
        setLoading(false);
        console.error('Error fetching jobPosts:', error);
      }
    );
  }, [
    page,
    pageSize,
    search,
    country_id,
    sector_id,
    // remoteFilter,
    // jobTypeFilter,
    // experienceLevelFilter,
    selectedCountryId,
    selectedSectorId,
    dateRange,
  ]);

  useEffect(() => {
    getJobPosts();
  }, [getJobPosts]);

  const handlePageSizeChange = (e) => {
    setPage(0); // Reset to the first page
    setPageSize(parseInt(e.target.value, 10)); // Update the page size
  };

  const handleGetMatchingCandidates = (skills) => {
    try {
      Get(
        {
          skills,
        },
        API_URLS.getMatchingCandidates,
        (resp) => {
          console.log('Matching Candidates:', resp);
          setOpenCandidateDialog(true);
          setCandidates(resp?.data);
        },
        (error) => {
          setOpenCandidateDialog(false);
          console.error('Error fetching matching candidates:', error);
        }
      );
    } catch (error) {
      setOpenCandidateDialog(false);
      console.error('Error fetching matching candidates:', error);
    }
  };

  console.log('fdjkdsfrewf', remoteFilter, experienceLevelFilter, jobTypeFilter);
  return (
    <>
      <div style={{ backgroundColor: '#ECF6FE' }}>
        <div style={{ paddingTop: '10px' }}>
          <Box
            display="flex"
            alignItems="center" // Ensures vertical alignment
            justifyContent="flex-start"
            marginTop="10px"
          >
            <Icon
              icon="material-symbols:arrow-back-rounded"
              style={{ fontSize: '26px', color: '#00C1FE', cursor: 'pointer' }}
              onClick={onBackClick}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="mingcute:search-line"
                    style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                  />
                ),
              }}
              variant="outlined"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                width: '30%',
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                marginLeft: '1rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {},
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          </Box>
          <br />
          <Box display="flex" flexWrap="wrap" marginLeft="2.5rem">
            {country_id && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    width: '80px',
                    height: '40px',
                    border: '2px solid #00C1FE',
                    borderRadius: '5px',
                    color: '#ffffff',
                    backgroundColor: '#00C1FE',
                    fontSize: '14px', // Adjust to match the button
                    fontWeight: '500', // Adjust to match the button
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    '&:hover': {
                      backgroundColor: '#00C1FE',
                    },
                  }}
                >
                  {country_id === 1
                    ? 'UK'
                    : country_id === 2
                    ? 'US'
                    : country_id === 3
                    ? 'Canada'
                    : country_id === 4
                    ? 'GERMANY'
                    : country_id === 5
                    ? 'Australia'
                    : country_id === 6
                    ? 'UNITED ARAB EMIRATES'
                    : country_id === 7
                    ? 'Europe'
                    : country_id === 8
                    ? 'Norway'
                    : country_id === 9
                    ? 'France'
                    : country_id === 10
                    ? 'Italy'
                    : 'Worldwide'}
                </Typography>

                {selectedSector && (
                  <>
                    <Icon
                      icon="ph:line-vertical-light"
                      style={{ marginLeft: '-13px', color: '#00C1FE', fontSize: '42px' }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        width: '80px',
                        height: '40px',
                        border: '2px solid #00C1FE',
                        borderRadius: '5px',
                        color: '#ffffff',
                        backgroundColor: '#00C1FE',
                        fontSize: '14px', // Adjust to match the button
                        fontWeight: '500', // Adjust to match the button
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: '#00C1FE',
                        },
                      }}
                    >
                      {selectedSector === 1 ? 'Direct' : 'S&R'}
                    </Typography>
                  </>
                )}

                <Icon
                  icon="ph:line-vertical-light"
                  style={{ marginLeft: '-13px', color: '#00C1FE', fontSize: '42px' }}
                />
              </>
            )}

            <Typography
              variant="body2"
              sx={{
                width: '90px',
                height: '40px',
                border: '2px solid #00C1FE',
                borderRadius: '5px',
                color: '#ffffff',
                backgroundColor: '#00C1FE',
                fontSize: '14px', // Adjust to match the button
                fontWeight: '500', // Adjust to match the button
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                },
              }}
            >
              Jobs
            </Typography>

            <Icon icon="ph:line-vertical-light" style={{ color: '#00C1FE', fontSize: '42px', marginLeft: '-13px' }} />
            {/* <MultiDatePicker onChange={(range) => setDateRange(range)} numberOfMonths={6} height="40px" /> */}
            {/* <Autocomplete
              options={remote}
              getOptionLabel={(option) => option} // Adjust based on your backend response
              onChange={(e, newValue) => {
                if (newValue) {
                  setRemoteFilter(newValue);
                } else {
                  setRemoteFilter('');
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Remote"
                  size="small"
                  sx={{
                    backgroundColor: '#ECF6FE',
                    borderRadius: '0.3rem',
                    border: '2px solid #00C1FE',
                    width: '150px',
                    height: '40px',
                    marginRight: '5px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              )}
            /> */}
            {/* <Autocomplete
              options={jobType}
              getOptionLabel={(option) => option} // Adjust based on your backend response
              onChange={(e, newValue) => {
                if (newValue) {
                  console.log('fsdifjerte', newValue);
                  setJobTypeFilter(newValue);
                } else {
                  setJobTypeFilter('');
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Job type"
                  size="small"
                  sx={{
                    backgroundColor: '#ECF6FE',
                    borderRadius: '0.3rem',
                    border: '2px solid #00C1FE',
                    width: '150px',
                    height: '40px',
                    marginRight: '5px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              )}
            /> */}
            {/* <Autocomplete
              options={experienceLevel}
              getOptionLabel={(option) => option} // Adjust based on your backend response
              onChange={(e, newValue) => {
                if (newValue) {
                  setExperienceLevelFilter(newValue);
                } else {
                  setExperienceLevelFilter('');
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Experience level"
                  size="small"
                  sx={{
                    backgroundColor: '#ECF6FE',
                    borderRadius: '0.3rem',
                    border: '2px solid #00C1FE',
                    width: '190px',
                    height: '40px',
                    marginRight: '5px',
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              )}
            /> */}
            {!country_id && !sector_id && (
              <>
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => option?.country} // Adjust based on your backend response
                  onChange={(event, newValue) => {
                    setSelectedCountryId(newValue?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Country"
                      size="small"
                      sx={{
                        backgroundColor: '#ECF6FE',
                        borderRadius: '0.3rem',
                        width: '190px',
                        height: '40px',
                        border: '2px solid #00C1FE',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
                <Autocomplete
                  options={sectors}
                  getOptionLabel={(option) => option?.sector} // Adjust based on your backend response
                  onChange={(event, newValue) => {
                    setSelectedSectorId(newValue?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Sector"
                      size="small"
                      sx={{
                        backgroundColor: '#ECF6FE',
                        borderRadius: '0.3rem',
                        marginLeft: '5px',
                        width: '190px',
                        height: '40px',
                        marginRight: '5px',
                        border: '2px solid #00C1FE',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </>
            )}
            {country_id && !sector_id && (
              <Autocomplete
                options={sectors}
                getOptionLabel={(option) => option?.sector} // Adjust based on your backend response
                onChange={(event, newValue) => {
                  setSelectedSectorId(newValue?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sector"
                    size="small"
                    sx={{
                      backgroundColor: '#ECF6FE',
                      borderRadius: '0.3rem',
                      marginLeft: '5px',
                      width: '190px',
                      height: '40px',
                      marginRight: '5px',
                      border: '2px solid #00C1FE',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            )}
            <MultiDatePicker onChange={(range) => setDateRange(range)} numberOfMonths={2} height="40px" width="230px" />
          </Box>
          <br />
          <Grid container spacing={2} style={{ marginLeft: '2.5rem' }}>
            <Grid item xs="none" sm={12} md={2}>
              <Card
                style={{
                  width: '100%',
                  height: '100px',
                  borderRadius: '0.3rem',
                }}
              >
                <img
                  src={
                    country_id === 1
                      ? uk_flag_wadda
                      : country_id === 2
                      ? 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
                      : country_id === 3
                      ? 'https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg'
                      : country_id === 4
                      ? 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg'
                      : country_id === 6
                      ? 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg'
                      : country_id === 7
                      ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                      : country_id === 5
                      ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                      : country_id === 8
                      ? 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
                      : country_id === 9
                      ? 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg'
                      : country_id === 10
                      ? 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg'
                      : world_icon
                  }
                  alt="companies"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '0.3rem',
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs="none" sm={12} md={9}>
              <Card
                style={{
                  width: '100%',
                  height: '100px',
                  borderRadius: '0.3rem',
                }}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    color: '#3f51b5',
                    paddingTop: '5px',
                  }}
                >
                  {country_id === 1
                    ? 'UK '
                    : country_id === 2
                    ? 'US '
                    : country_id === 3
                    ? 'Canada '
                    : country_id === 3
                    ? 'UAE '
                    : 'Total '}{' '}
                  Jobs Stats
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="90%" margin="auto">
                  <Tooltip title="S&R Jobs" placement="top">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedSector(2);
                        onSectorIdChange(2);
                      }}
                    >
                      <img src={sr_jobs} alt="companies" style={{ width: '25px', height: '25px', objectFit: 'fill' }} />
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: '20px',
                          color: '#3f51b5',
                          paddingTop: '5px',
                        }}
                      >
                        {srCount || 0}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip title="All Jobs" placement="top">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedSector(null);
                        onSectorIdChange(null);
                      }}
                    >
                      <img
                        src={jobs_icon}
                        alt="companies"
                        style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                      />
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: '20px',
                          color: '#3f51b5',
                          paddingTop: '5px',
                        }}
                      >
                        {srCount + directCount || 0}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip title="Direct Jobs" placement="top">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedSector(1);
                        onSectorIdChange(1);
                      }}
                    >
                      <img
                        src={direct_jobs}
                        alt="companies"
                        style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                      />
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: '20px',
                          color: '#3f51b5',
                          paddingTop: '5px',
                        }}
                      >
                        {directCount || 0}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <br />
          <Box display="flex" marginLeft="2.5rem">
            <p
              style={{
                textAlign: 'start',
                fontSize: '15px',
                color: '#3f51b5',
                paddingTop: '5px',
              }}
            >
              About {total || 0} results
            </p>
          </Box>

          <br />

          {loading && <p style={{ marginLeft: '2.5rem', fontWeight: 'bold' }}>Loading...</p>}
          <TablePagination
            component="div"
            count={total}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />

          {jobPosts?.length === 0 ? (
            <p style={{ marginLeft: '2.5rem', fontWeight: 'bold', paddingBottom: '2rem' }}>No job posts found</p>
          ) : (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                gap: 20,
                padding: 20,
                width: '100%',
                margin: 'auto',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={selectedJobPosts ? 4 : 12} lg={selectedJobPosts ? 4 : 12}>
                  {jobPosts.map((jobPost) => (
                    <JobPostCard key={jobPost.id} job={jobPost} onSelect={setSelectedJobPosts} />
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  style={{
                    borderLeft: '1px solid #3f51b5',
                  }}
                >
                  {selectedJobPosts && (
                    <div
                      style={{
                        padding: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 4,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: 4,
                            cursor: 'pointer',
                          }}
                        >
                          <Typography variant="h4" style={{ color: '#3f51b5' }}>
                            {selectedJobPosts?.job_title}
                          </Typography>
                          <Typography variant="body1">
                            {selectedJobPosts?.industry}, {selectedJobPosts?.city}
                          </Typography>
                        </div>
                        <button
                          style={{
                            padding: 10,
                            borderRadius: 5,
                            backgroundColor: '#00C1FE',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            const skills = selectedJobPosts?.skills_required.split(',').map((skill, index, arr) => {
                              if (index === arr.length - 1) {
                                return skill.replace(/(and|,|""|'')/g, '').trim();
                              }
                              if (skill === '') return;
                              return skill.trim();
                            });

                            handleGetMatchingCandidates(skills);
                          }}
                        >
                          Get Machting Candidates
                        </button>
                      </div>
                      <hr />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 4,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          // open company linkedin link in new tab
                          window.open(selectedJobPosts?.company?.profile_url, '_blank');
                        }}
                      >
                        <img
                          src={selectedJobPosts?.company?.logo}
                          alt="company avatar"
                          height={40}
                          width={40}
                          style={{
                            border: '1px solid #3f51b5',
                            padding: 2,
                            borderRadius: '50%',
                            cursor: 'pointer',
                          }}
                        />
                        <p> {selectedJobPosts?.company?.name}</p>
                      </div>
                      <Typography variant="h4" style={{ color: '#3f51b5' }}>
                        {selectedJobPosts?.job_title}
                      </Typography>
                      <Typography variant="body1">
                        {selectedJobPosts?.industry}, {selectedJobPosts?.city}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          gap: 10,
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <img src={jobs_icon} alt="job logo" style={{ width: 30, height: 30 }} />
                        <p
                          style={{
                            background: '#E1F7FC',
                            padding: 10,
                            borderRadius: 5,
                          }}
                        >
                          {selectedJobPosts?.remote}
                        </p>
                        <p
                          style={{
                            background: '#E1F7FC',
                            padding: 10,
                            borderRadius: 5,
                          }}
                        >
                          {selectedJobPosts?.job_type}
                        </p>
                        <p
                          style={{
                            background: '#E1F7FC',
                            padding: 10,
                            borderRadius: 5,
                          }}
                        >
                          {selectedJobPosts?.experience_level}
                        </p>
                      </div>
                      <Typography variant="h6"> About</Typography>
                      <Typography variant="body1">{selectedJobPosts?.job_description}</Typography>
                      <Typography variant="h6"> Skills Required</Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 10,
                        }}
                      >
                        {selectedJobPosts?.skills_required?.split(', ')?.map((skill, index, arr) => {
                          // Clean up the last skill
                          if (index === arr.length - 1) {
                            skill = skill.replace(/(and|,)/g, '').trim();
                          }
                          return (
                            <p
                              key={index}
                              style={{
                                background: '#E1F7FC',
                                padding: 10,
                                borderRadius: 5,
                              }}
                            >
                              {skill !== '' && skill}
                            </p>
                          );
                        })}
                      </div>
                      {/*  */}
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          width: 60, // Set equal width and height
          height: 60, // Set equal width and height
          borderRadius: '50%', // Circular shape
          backgroundColor: '#00C1FE',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Center the icon
        }}
      >
        <Tooltip arrow placement="top" title="Scroll to top">
          <Icon icon="ph:arrow-up-bold" style={{ fontSize: '30px' }} />
        </Tooltip>
      </div>

      {/* Candidate Dialog */}
      {openCandidateDialog && (
        <Dialog open={openCandidateDialog} onClose={() => setOpenCandidateDialog(false)} fullWidth maxWidth="md">
          <DialogTitle>Matching Candidates</DialogTitle>
          <DialogContent>
            {candidates?.length === 0 && <p>No matching candidates found</p>}
            {candidates?.length > 0 && (
              <Grid container spacing={2}>
                {candidates?.map((candidate, index) => (
                  <Grid item xs={12} md={12} key={index}>
                    <p
                      style={{
                        background: '#E1F7FC',
                        padding: 10,
                        borderRadius: 5,
                        textAlign: 'center',
                      }}
                    >
                      skills matched {candidate?.matchCount}
                    </p>
                    <CandidateCard candidate={candidate} />
                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCandidateDialog(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default JobPostList;
