export const parseSalary = (looking_for) => {
  if (!looking_for || looking_for.length === 0) return 'Salary not available';

  try {
    const parsedData = JSON.parse(looking_for[0]); // Parse the first element
    return parsedData.salary; // Return the salary value
  } catch (error) {
    console.error('Error parsing salary:', error);
    return 'Invalid salary data';
  }
};
export const parseJobType = (looking_for) => {
  if (!looking_for || looking_for.length === 0) return 'Salary not available';

  try {
    const parsedData = JSON.parse(looking_for[0]); // Parse the first element
    return parsedData.job_type; // Return the salary value
  } catch (error) {
    console.error('Error parsing salary:', error);
    return 'Invalid salary data';
  }
};

export const parseWorkHistory = (work_history) => {
  // "{"headline":"Talent Acquisition PartneratLloydsPharmacy","title":"Talent Acquisition Partner","company":"LloydsPharmacy","date":"Oct 2021","responsibilities":""}"
  try {
    const parsedData = JSON.parse(work_history); // Parse the first element
    const headline = parsedData.headline;
    const title = parsedData.title;
    const company = parsedData.company;
    const date = parsedData.date;
    const responsibilities = parsedData.responsibilities;
    const workHistory = {
      headline,
      title,
      company,
      date,
      responsibilities,
      workHistory: title + ' at ' + company + ' (' + date + ')',
    };

    return workHistory; // Return the salary value
  } catch (error) {
    console.error('Error parsing salary:', error);
    return 'Invalid salary data';
  }
};

export function parseEducation(qualification) {
  //   return qualifications.map((qualification) => {
  try {
    // Parse the JSON string
    const parsed = JSON.parse(qualification);

    // Extract details
    const universityDetail = parsed.university_detail || 'Unknown';
    const date = parsed.date || 'Unknown';

    // Format the output
    return {
      universityDetail,
      date,
    };
  } catch (error) {
    console.error('Failed to parse qualification:', qualification, error);
    return { universityDetail: 'Invalid Data', date: 'Invalid Data' };
  }
  //   });
}
