import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

function MarkTaskAsDone({ open, onClose, roleId , onReload }) {
  const userId = localStorage.getItem('userId');
  const [task, setTask] = useState();
  const getTask = () => {
    try {
      Get(
        {
          roleId: roleId,
          userId: userId,
        },
        API_URLS.getTaskByUserId,
        (resp) => {
          console.log(resp);
          setTask(resp?.data?.data[0]);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTask();
  }, [open]);
  const updateToDone = () => {
    try {
      Post(
        {
          taskId: task?.id,
          userId: userId,
        },
        API_URLS.markTaskAsDone,
        (resp) => {
          console.log(resp);
          // setTask(resp?.data?.data);
          enqueueSnackbar('Task marked as done', { variant: 'success' });
          onReload();
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mb: 'auto',
            mr: 'auto',
            width: '100%',
            maxWidth: '600px',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#E2F2FF',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className="ml-8">
              Mark Task as Done
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br/>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            Ongoing primary task : {task?.primary_task}
            Ongoing sub-task : {task?.sub_task}
            Status : {task?.status}
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              onClick={() => {
                updateToDone();
                onClose();
              }}
            >
              Mark as Done
            </Button>
          </Box>
        </DialogContent>
      </Dialog>{' '}
    </div>
  );
}

export default MarkTaskAsDone;
