import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress, Button, TextField } from '@mui/material';
import AWS from 'aws-sdk';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Download } from '@mui/icons-material';
import { SearchHistory } from 'src/sections/resourcing';

function SearchHistoryPage() {
  // const [files, setFiles] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [openFolders, setOpenFolders] = useState({});
  // const [searchQuery, setSearchQuery] = useState('');

  // useEffect(() => {
  //   // Configure AWS SDK with secure credentials
  //   AWS.config.update({
  //     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Use environment variables
  //     secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // Use environment variables
  //     region: 'eu-west-2',
  //   });

  //   const s3 = new AWS.S3();
  //   const params = {
  //     Bucket: 'uol123', // Your S3 bucket name
  //     Prefix: 'Client 17 IT-20240402T073734Z-001/', // Specify the folder prefix
  //   };

  //   // Fetch S3 bucket contents
  //   s3.listObjectsV2(params, (err, data) => {
  //     if (err) {
  //       console.error('Error fetching S3 objects:', err);
  //       setLoading(false);
  //     } else {
  //       const filesData = data.Contents.map((item) => item.Key);
  //       setFiles(filesData);
  //       setLoading(false);
  //     }
  //   });
  // }, []);

  // // Converts file list into a nested directory structure
  // const buildFileStructure = (files) => {
  //   const root = {};

  //   files.forEach((file) => {
  //     const parts = file.split('/');
  //     let current = root;
  //     parts.forEach((part, index) => {
  //       console.log('part', part);
  //       if (!current[part]) {
  //         current[part] = index === parts.length - 1 ? null : {};
  //       }
  //       current = current[part];
  //     });
  //   });

  //   return root;
  // };

  // // Toggle folder open/close state
  // const toggleFolder = (folderPath) => {
  //   setOpenFolders((prevState) => ({
  //     ...prevState,
  //     [folderPath]: !prevState[folderPath],
  //   }));
  // };

  // // Download a file
  // const downloadFile = (fileKey) => {
  //   const s3 = new AWS.S3();
  //   const params = {
  //     Bucket: 'uol123',
  //     Key: fileKey,
  //   };

  //   s3.getObject(params, (err, data) => {
  //     if (err) {
  //       console.error('Error downloading file:', err);
  //     } else {
  //       const url = window.URL.createObjectURL(new Blob([data.Body]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', fileKey.split('/').pop());
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);
  //     }
  //   });
  // };

  // // Download a folder as a ZIP file
  // const downloadFolder = async (folderKey) => {
  //   const s3 = new AWS.S3();
  //   const params = {
  //     Bucket: 'uol123',
  //     Prefix: folderKey,
  //   };

  //   s3.listObjectsV2(params, async (err, data) => {
  //     if (err) {
  //       console.error('Error listing folder contents:', err);
  //     } else {
  //       const zip = new JSZip();
  //       const folder = zip.folder(folderKey);

  //       const downloadPromises = data.Contents.map((item) => {
  //         const fileParams = {
  //           Bucket: 'uol123',
  //           Key: item.Key,
  //         };

  //         return new Promise((resolve, reject) => {
  //           s3.getObject(fileParams, (fileErr, fileData) => {
  //             if (fileErr) {
  //               console.error('Error downloading file:', fileErr);
  //               reject(fileErr);
  //             } else {
  //               const fileName = item.Key.replace(folderKey, ''); // Remove folder prefix
  //               folder.file(fileName, fileData.Body);
  //               resolve();
  //             }
  //           });
  //         });
  //       });

  //       try {
  //         await Promise.all(downloadPromises);
  //         const zipContent = await zip.generateAsync({ type: 'blob' });
  //         saveAs(zipContent, `${folderKey.replace(/\/$/, '')}.zip`);
  //       } catch (zipErr) {
  //         console.error('Error creating ZIP file:', zipErr);
  //       }
  //     }
  //   });
  // };

  // // Renders the directory structure as nested elements
  // const renderFileTree = (node, path = '') => {
  //   return Object.keys(node).map((key) => {
  //     const currentPath = path ? `${path}/${key}` : key;
  //     if (node[key] === null) {
  //       // File
  //       return (
  //         <div key={currentPath} style={{ marginLeft: 20 }}>
  //           📄 {key}{' '}
  //           <Button size="small" onClick={() => downloadFile(currentPath)}>
  //             <Download
  //               sx={{
  //                 fontSize: '20px',
  //                 color: 'blue',
  //               }}
  //             />
  //           </Button>
  //         </div>
  //       );
  //     }
  //     // Folder
  //     const isOpen = openFolders[currentPath];
  //     return (
  //       <div key={currentPath} style={{ marginLeft: 20 }}>
  //         <div style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => toggleFolder(currentPath)}>
  //           {isOpen ? '📂' : '📁'} {key}{' '}
  //           <Button size="small" onClick={() => downloadFolder(currentPath)}>
  //             <Download
  //               sx={{
  //                 fontSize: '20px',
  //                 color: 'blue',
  //               }}
  //             />
  //           </Button>
  //         </div>
  //         {isOpen && <div style={{ marginLeft: 20 }}>{renderFileTree(node[key], currentPath)}</div>}
  //       </div>
  //     );
  //   });
  // };

  // if (loading)
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
  //       <CircularProgress />
  //     </div>
  //   );

  // const fileStructure = buildFileStructure(files);

  // console.log('fileStructure', files);

  return (
    <div>
      {/* <TextField
        label="Search files or folders"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <Typography variant="h5" gutterBottom>
        Client 17 Data
      </Typography>
      {renderFileTree(fileStructure)} */}
      <SearchHistory />
    </div>
  );
}

export default SearchHistoryPage;
