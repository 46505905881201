import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import CheckEmailForVerification from './pages/auth/CheckEmailForVerification';
import DashboardPage from './pages/DashboardPage';
import Page404 from './pages/Page404';
import VerifiedAccountPage from './pages/auth/AccountVerificationPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import { CompanyDetailsPage, CompanyListPage, MainLeadGenerationPage } from './pages/leads';
import ForgetPasswordPage from './pages/auth/ForgetPasswordPage';
import AdminDashboardPage from './pages/admin/AdminDashboardPage';
import CampaignsPage from './pages/admin/CampaignsPage';
import CampaignSettings from './pages/admin/CampaignSettings';
import TemplatesPage from './pages/admin/TemplatesPage';
import { ClientUnsubscriptionPage } from './pages/public/subscriptions';
import BlacklistPage from './pages/admin/BlacklistPage';
import { CandidateList } from './sections/candidates';
import { EmailResearcherPage } from './pages/leadFinder';
import UsersPage from './pages/admin/UsersPage';
import { ReportSheetsPage, ACMManageClientsPage, ACMManageRoles, ACMPage } from './pages/acm';
import { CandidateSearchPage, RolesProcessing, ShowCandidateDetailsPage } from './sections/acm/components';
import { ResourcingCandidatePage, ResourcingRoles, SearchHistoryPage } from './pages/resourcing';
import { ResourcerDashboardPage, ResourcingRolesProcessing, RolesPage } from './sections/resourcing';
import { AddLIComponent, CVUploadComponent } from './sections/resourcing/components';
import ReedsSearchPage from './pages/admin/ReedsSearchPage';
import JobBoards from './pages/admin/JobBoards';
import { LimitSettingsPage } from './pages/settings/acm';
import {
  AllCandidates,
  ApplicationsPage,
  CandidatesPage,
  Direct360Page,
  InterviewsPage,
  JobOpeningsPage,
  RecruiterCampaigns,
  ToDoPage,
} from './pages/direct360';
import { AddCandidate, AddJob, CallDetails, CallHistory, CandidateDetails, CreateCall, CreateTask, SendEmailToCandidate } from './sections/direct360/components';
import CandidatesList from './sections/acm/candidates/CandidatesList';
import {
  HiredCandidates,
  IntenalInterview,
  InterviewCandidates,
  InterviewDetails,
  JobDetails,
  OfferedCandidates,
  OnlineInterview,
  RejectedCandidates,
  RoleApplications,
  ScreeningCandidates,
  SubmissionCandidates,
} from './sections/direct360';
import BDMPage from './pages/bdm/BDMPage';
import RQAPage from './pages/rqa/RQAPage';
import ClientDetails from './pages/acm/ClientDetails';
import { CandidateWorking, RecruiterReports } from './sections/direct360/candidates-working';
import { CandidateCommunicationDetails, LIConnectionSending, RoleSequence, UpdateResponse } from './sections/direct360/candidates-working/components';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    // Admin Routes

    {
      path: '/leadExpert',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/leadExpert/app" />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'leads', element: null },
        { path: 'findJobs', element: <MainLeadGenerationPage role="leadExpert" /> },
        { path: 'findEmails', element: <EmailResearcherPage /> },
      ],
    },
    // Admin Routes

    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admin/app" />, index: true }, // Default route for `/admin`
        { path: 'app', element: <AdminDashboardPage /> },
        { path: 'campaigns', element: <CampaignsPage /> },
        { path: 'create-campaign', element: <CampaignSettings /> },
        { path: 'templates', element: <TemplatesPage /> },
        { path: 'leads', element: <MainLeadGenerationPage role="admin" /> },
        { path: 'blacklist', element: <BlacklistPage /> },
        { path: 'candidates', element: <CandidateList /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'companies', element: <CompanyListPage /> },
        { path: 'company', element: <CompanyDetailsPage /> },
        { path: 'resources', element: <ResourcingCandidatePage /> },
        { path: 'jobboards', element: <JobBoards /> },
        { path: 'dataSearch', element: <ReedsSearchPage /> },
        { path: 'reeds', element: <ReedsSearchPage /> },
        { path: 'settings', element: <LimitSettingsPage /> },
      ],
    },
    {
      path: '/acm',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/acm/app" />, index: true },
        { path: 'app', element: <ACMPage /> },
        { path: 'reports', element: <ReportSheetsPage /> },
        { path: 'roles', element: <ACMManageRoles /> },
        { path: 'clients', element: <ACMManageClientsPage /> },
        { path: 'clients/client-details/:client_number', element: <ClientDetails /> },
        { path: 'roles-processing/:roleId', element: <ResourcingRolesProcessing userRole="acm" /> },
        { path: 'candidates/:roleId', element: <CandidateSearchPage /> },
        { path: 'candidateDetail/:roleId/:candidateId', element: <ShowCandidateDetailsPage /> },
        { path: 'candidates', element: <CandidatesList /> },
      ],
    },
    {
      path: '/resourcing',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/resourcing/app" />, index: true },
        { path: 'app', element: <ResourcerDashboardPage /> },
        { path: 'roles', element: <ResourcingRoles /> },
        { path: 'roles-processing/:roleId', element: <ResourcingRolesProcessing userRole="resourcer" /> },
        { path: 'candidates/:roleId', element: <CandidateSearchPage /> },
        { path: 'candidateDetail/:roleId/:source/:candidateId', element: <ShowCandidateDetailsPage /> },
        { path: 'uploadCV/:roleId', element: <CVUploadComponent /> },
        { path: 'searchHistory', element: <SearchHistoryPage /> },
        { path: 'addLI/:roleId', element: <AddLIComponent /> },
      ],
    },
    {
      path: '/recruiter',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/recruiter/app" />, index: true },
        { path: 'app', element: <Direct360Page /> },
        { path: 'myReports', element: <RecruiterReports /> },
        { path: 'allCandidates/:roleId', element: <AllCandidates /> },
        { path: 'candidateInfo/:roleId/:candidateId', element: <CandidateDetails /> },
        { path: 'submittedCandidates/:roleId', element: <SubmissionCandidates /> },
        { path: 'screeningCandidates/:roleId', element: <ScreeningCandidates /> },
        { path: 'rejectedCandidates/:roleId', element: <RejectedCandidates /> },
        { path: 'interviewCandidates/:roleId', element: <InterviewCandidates /> },
        { path: 'offeredCandidates/:roleId', element: <OfferedCandidates /> },
        { path: 'hiredCandidates/:roleId', element: <HiredCandidates /> },
        { path: 'jobDetails/:roleId', element: <JobDetails /> },
        { path: 'jobApplicants/:roleId', element: <RoleApplications /> },
        { path: 'internalInterview', element: <IntenalInterview /> },
        { path: 'onlineInterview', element: <OnlineInterview /> },
        { path: 'interviews', element: <InterviewsPage /> },
        { path: 'interviewDetails/:interviewId', element: <InterviewDetails /> },
        { path: 'toDos', element: <ToDoPage /> },
        { path: 'toDos/callHistory', element: <CallHistory /> },
        { path: 'toDos/createCall', element: <CreateCall /> },
        { path: 'sendEmailToCandidate/:candidateId', element: <SendEmailToCandidate /> },
        { path: 'jobOpenings', element: <JobOpeningsPage /> },
        { path: 'candidates', element: <CandidatesPage /> },
        { path: 'applications', element: <ApplicationsPage /> },
        { path: 'candidates/addCandidate', element: <AddCandidate /> },
        { path: 'toDos/createTask', element: <CreateTask /> },
        { path: 'toDos/addJob', element: <AddJob /> },
        { path: 'recruiterCampaigns', element: <RecruiterCampaigns /> },
        { path: 'toDos/callDetails/:callId', element: <CallDetails /> },
        { path: 'jobApplicants/candidateWorking/:roleId', element: <CandidateWorking /> },
        { path: 'updateReponse/:roleId/:candidateId/:channel', element: <UpdateResponse /> },
        { path: 'jobApplicants/roleSequence/:roleId', element: <RoleSequence /> },
        { path: 'candidateWorkingDetails/:candidateId', element: <CandidateCommunicationDetails /> },
        { path: 'sendLiConnection/:roleId', element: <LIConnectionSending /> },
      ],
    },
    {
      path: '/bdm',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/bdm/app" />, index: true },
        { path: 'app', element: <BDMPage /> },
      ],
    },
    {
      path: '/rqa',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/rqa/app" />, index: true },
        { path: 'app', element: <RQAPage /> },
        { path: 'roles', element: <ResourcingRoles /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'verify/:verificationtoken',
      element: <VerifiedAccountPage />,
    },
    {
      path: 'verifyEmail',
      element: <CheckEmailForVerification />,
    },
    {
      path: 'forgetPassword',
      element: <ForgetPasswordPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'unsubscribe', element: <ClientUnsubscriptionPage /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
