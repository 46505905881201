import { Icon } from '@iconify/react';
import { Box, Button, CircularProgress, Divider, Grid, Modal, Popover, TablePagination, Tooltip, Typography } from '@mui/material';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { JobDescription, SearchComponent, ShowCandidateDetailsPage, ShowCandidateResults } from '.';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

export const searchContext = createContext();
function CandidateSearchPage() {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [search, setSearch] = useState(false);
  const { state } = useLocation();
  const [candidates, setCandidates] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [showDetails, setShowDetails] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState('');
  const [loadingCandidates, setLoadingCandidates] = useState(false);

  const [showJd, setShowJd] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchString, setSearchString] = useState('');
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // get candidates against search

  const searchCandidatesBySearchString = useCallback(() => {
    try {
      setLoadingCandidates(true);
      Get(
        {
          page: page,
          pageSize: pageSize,
          searchString: searchString,
        },
        API_URLS.searchCandidateBySearchString,
        (resp) => {
          setLoadingCandidates(false);
          if (resp?.data) {
            setCandidates(resp?.data?.candidates);
            setTotal(resp?.data?.resultCount);
          } else {
            setCandidates([]);
          }
        },
        (error) => {
          setLoadingCandidates(false);
          console.log('erdfdfdfdfdror', error);
          enqueueSnackbar('Cannot get Candidates! Refine your search or try again later', { variant: 'error' });
        }
      );
    } catch (error) {
      setLoadingCandidates(false);
      console.log('erdfdfdfdfdror catch', error);
      enqueueSnackbar('Cannot get Candidates! Refine your search or try again later', { variant: 'error' });
    }
  }, [enqueueSnackbar, page, pageSize, searchString]);

  useEffect(() => {
    searchCandidatesBySearchString(searchString); // Pass the current search string if applicable
  }, [pageSize, searchCandidatesBySearchString]);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  console.log('khsdgvfsdgfsdfsd', candidates);
  return (
    <div style={{ marginTop: '5px' }}>
      {selectedLayout === 'left' ? (
        <>
          <div style={{ width: '98%', margin: 'auto' }}>
            <Grid container spacing={2}>
              {showJd && (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <JobDescription />
                </Grid>
              )}
              {showJd ? (
                <Grid item xs={12} sm={6} md={8} lg={8}>
                  <Box
                    display="flex"
                    sx={{ backgroundColor: '#D6F4FB', height: '80px' }}
                    justifyContent="space-between"
                    alignItems="center"
                    padding="0 20px"
                  >
                    {/* Icon and Back Arrow */}
                    <Icon
                      icon="material-symbols:arrow-back"
                      style={{ fontSize: '22px', cursor: 'pointer' }}
                      onClick={() => navigate(`/acm/roles-processing/${roleId}`)}
                    />

                    {/* Client No */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        Client No:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>345</Typography>
                    </Box>

                    {/* Role Name */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        Role Title:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>UI UX Designer</Typography>
                    </Box>

                    {/* Status */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        Status:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>In Progress</Typography>
                    </Box>

                    {/* LIs */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        LIs:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>30</Typography>
                    </Box>

                    {/* CVs */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        CVs:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>30</Typography>
                    </Box>

                    {/* Button */}
                    <Button
                      sx={{
                        background: '#00C1FE',
                        color: '#fff',
                        width: '100px',
                        height: '45px',
                        border: '1px solid #00C1FE',
                        '&:hover': {
                          background: '#00C1FE',
                          color: '#fff',
                        },
                        borderRadius: '5px',
                        padding: '5px',
                        marginLeft: '20px',
                      }}
                      // onClick={() => setOpenModal(true)}
                      onClick={(event) => {
                        if (showJd) {
                          setShowJd(false);
                        } else {
                          handleOpen(event);
                        }
                      }}
                    >
                      {showJd ? 'Hide JD' : 'Show JD'}
                    </Button>
                  </Box>
                  {showDetails ? (
                    <>
                      <ShowCandidateDetailsPage candidate={candidates[0]} />
                    </>
                  ) : (
                    <>
                      <SearchComponent
                        onSearch={(search) => {
                          if (search) {
                            setSearch(true);
                            setSearchString(search);
                          } else {
                            setSearchString(null);
                            setSearch(false);
                          }
                        }}
                        queryString={state?.queryString}
                      />
                    </>
                  )}
                </Grid>
              ) : (
                <div style={{ width: '95%', margin: 'auto' }}>
                  <Box
                    display="flex"
                    sx={{ backgroundColor: '#D6F4FB', height: '80px' }}
                    justifyContent="space-between"
                    alignItems="center"
                    padding="0 20px"
                  >
                    {/* Icon and Back Arrow */}
                    <Icon
                      icon="material-symbols:arrow-back"
                      style={{ fontSize: '22px', cursor: 'pointer' }}
                      onClick={() => navigate(`/acm/roles-processing/${roleId}`)}
                    />

                    {/* Client No */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        Client No:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>345</Typography>
                    </Box>

                    {/* Role Name */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        Role Title:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>UI UX Designer</Typography>
                    </Box>

                    {/* Status */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        Status:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>In Progress</Typography>
                    </Box>

                    {/* LIs */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        LIs:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>30</Typography>
                    </Box>

                    {/* CVs */}
                    <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                      <Typography variant="h5" sx={{ marginRight: '10px' }}>
                        CVs:
                      </Typography>
                      <Typography sx={{ fontSize: '18px' }}>30</Typography>
                    </Box>

                    {/* Button */}
                    <Button
                      sx={{
                        background: '#00C1FE',
                        color: '#fff',
                        width: '100px',
                        height: '45px',
                        border: '1px solid #00C1FE',
                        '&:hover': {
                          background: '#00C1FE',
                          color: '#fff',
                        },
                        borderRadius: '5px',
                        padding: '5px',
                        marginLeft: '20px',
                      }}
                      // onClick={() => setOpenModal(true)}
                      onClick={(event) => {
                        if (showJd) {
                          setShowJd(false);
                        } else {
                          handleOpen(event);
                        }
                      }}
                    >
                      {showJd ? 'Hide JD' : 'Show JD'}
                    </Button>
                  </Box>
                  {showDetails ? (
                    <>
                      <ShowCandidateDetailsPage candidate={candidates[0]} />
                    </>
                  ) : (
                    <>
                      <SearchComponent
                        onSearch={(search) => {
                          if (search) {
                            setSearch(true);
                            setSearchString(search);
                          } else {
                            setSearchString(null);
                            setSearch(false);
                          }
                        }}
                        queryString={state.queryString}
                      />
                    </>
                  )}
                </div>
              )}
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: '95%', margin: 'auto' }}>
            {showJd && <JobDescription />}
            <br />
          </div>
          <div style={{ width: '95%', margin: 'auto' }}>
            <Box
              display="flex"
              sx={{ backgroundColor: '#D6F4FB', height: '80px' }}
              justifyContent="space-between"
              alignItems="center"
              padding="0 20px"
            >
              {/* Icon and Back Arrow */}
              <Icon
                icon="material-symbols:arrow-back"
                style={{ fontSize: '22px', cursor: 'pointer' }}
                onClick={() => navigate(`/acm/roles-processing/${roleId}`)}
              />

              {/* Client No */}
              <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                <Typography variant="h5" sx={{ marginRight: '10px' }}>
                  Client No:
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>345</Typography>
              </Box>

              {/* Role Name */}
              <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                <Typography variant="h5" sx={{ marginRight: '10px' }}>
                  Role Title:
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>UI UX Designer</Typography>
              </Box>

              {/* Status */}
              <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                <Typography variant="h5" sx={{ marginRight: '10px' }}>
                  Status:
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>In Progress</Typography>
              </Box>

              {/* LIs */}
              <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                <Typography variant="h5" sx={{ marginRight: '10px' }}>
                  LIs:
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>30</Typography>
              </Box>

              {/* CVs */}
              <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
                <Typography variant="h5" sx={{ marginRight: '10px' }}>
                  CVs:
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>30</Typography>
              </Box>

              {/* Button */}
              <Button
                sx={{
                  background: '#00C1FE',
                  color: '#fff',
                  width: '100px',
                  height: '45px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                  borderRadius: '5px',
                  padding: '5px',
                  marginLeft: '20px',
                }}
                onClick={(event) => {
                  if (showJd) {
                    setShowJd(false);
                  } else {
                    handleOpen(event);
                  }
                }}
              >
                {showJd ? 'Hide JD' : 'Show JD'}
              </Button>
            </Box>
            {showDetails ? (
              <>
                <ShowCandidateDetailsPage candidate={candidates[0]} />
              </>
            ) : (
              <>
                <SearchComponent
                  onSearch={(search) => {
                    if (search) {
                      setSearch(true);
                      setSearchString(search);
                    } else {
                      setSearchString(null);
                      setSearch(false);
                    }
                  }}
                  queryString={state?.queryString}
                />
              </>
            )}
          </div>
        </>
      )}

      <br />
      {loadingCandidates ? (
        <CircularProgress
          thickness={3}
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#00C1FE',
            zIndex: 9999,
          }}
        />
      ) : (
        <>
          <TablePagination
            component="div"
            count={total}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />
          {!showDetails && search && (
            <searchContext.Provider value={{ candidates, searchString }}>
              <ShowCandidateResults
                candidates={candidates}
                total={total}
                onShowDetails={() => {
                  setShowDetails(true);
                }}
              />
            </searchContext.Provider>
          )}
          <TablePagination
            component="div"
            count={total}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: 0,
            marginTop: '5px',
            width: anchorEl ? anchorEl.offsetWidth : 100, // Set Popover width based on button width
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
          <Tooltip title="Dock to left" placement="bottom">
            <Icon
              icon="hugeicons:layout-left"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => {
                setSelectedLayout('left');
                handleClose();
                setShowJd(true);
              }}
            />
          </Tooltip>
          <Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed', mx: 1 }} />
          <Tooltip title="Dock to top" placement="bottom">
            <Icon
              icon="gravity-ui:layout-header"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => {
                setSelectedLayout('top');
                handleClose();
                setShowJd(true);
              }}
            />
          </Tooltip>
        </Box>
      </Popover>
    </div>
  );
}

export default CandidateSearchPage;
