import { Box, Grid, Tooltip } from '@mui/material';
import { useState } from 'react';
import { AddPersonEmailDialog, AddPersonPhoneNumberDialog, AssociateJobPostWithPerson } from '.';
import { company_icon, email_icon, linkedin_icon, phone_icon, sms, whatsapp } from './svg';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { ProfileNotFound } from 'src/images';
import { Icon } from '@iconify/react';

export default function PersonCard({ person, onClick }) {
  const [openAddJobPostDialog, setOpenAddJobPostDialog] = useState(false);
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [openAddPhoneDialog, setOpenAddPhoneDialog] = useState(false);
  const user_id = localStorage.getItem('userId');

console.log('person', person);
  const handleEmailSave = (personalEmails, businessEmails) => {
    try {
      Post(
        {
          user_id: user_id,
          person_id: person?.id,
          businessEmails: businessEmails,
          personalEmails: personalEmails,
        },
        API_URLS.addPersonEmails,
        (resp) => {
          enqueueSnackbar('Email info added successfully', { variant: 'success' });
          setOpenAddEmailDialog(false);
        },
        (error) => {
          if (error?.response?.data?.existingEmails) {
            const existingEmails = error.response.data.existingEmails;
            const emailMessages = existingEmails
              .map((e) => `The ${e.type} email "${e.email}" already exists for this person.`)
              .join('\n');

            enqueueSnackbar(emailMessages, { variant: 'warning' });
          } else {
            console.log('error', error?.response?.data);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };


  const handlePhoneSave = (phones) => {
    try {
      Post(
          {
              user_id: user_id,
              person_id: person?.id,
              businessPhones: phones.businessPhones,
              personalPhones: phones.personalPhones,
          },
          API_URLS.addPersonPhones,
          (resp) => {
              enqueueSnackbar('Phone info added successfully', { variant: 'success' });
              setOpenAddPhoneDialog(false)
          },
          (error) => {
            console.log('fdsfd', error?.response?.data);
              if (error?.response?.data?.existingPhones) {
                  const existingPhones = error.response.data.existingPhones;
                  const phoneMessages = existingPhones.map(e => 
                      `The ${e.type} phone number "${e.phone}" already exists for this person.`
                  ).join('\n');
                  
                  enqueueSnackbar(phoneMessages, { variant: 'warning' });
              } else {
                  console.log('error', error?.response?.data);
                  enqueueSnackbar('Failed to add phone numbers', { variant: 'error' });
              }
          }
      );
  } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
  }
  };

  console.log('person', person);
  return (
    <div
      style={{
        background: person?.is_hiring_person ? '#D8FFC6' : 'white',
        width: '98%',
        margin: '5px',
        padding: '0.5rem',
        borderRadius: '1rem',
        border: '1px solid #D9D9D9',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={1} lg={1}>
            {person?.avator?.includes('http') ? (
              <img
                src={person?.avator}
                alt="Profile"
                style={{ width: '6rem', height: '6rem', borderRadius: '50%', cursor: 'pointer' }}
                onClick={() => {
                  window.open(person?.profile_url, '_blank');
                }}
              />
            ) : (
              <img
                src={ProfileNotFound}
                alt="Profile"
                style={{ width: '6rem', height: '6rem', borderRadius: '50%', cursor: 'pointer' }}
                onClick={() => {
                  window.open(person?.profile_url, '_blank');
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={9} md={11} lg={11}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {/* Column 1 */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 2,
                  width: '80%',
                }}
              >
                <p
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={onClick}
                >
                  {person?.first_name} {person?.last_name}
                </p>
                |
                <p
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={onClick}
                >
                  {person?.first_name} {person?.current_title?.slice(0, 20)}
                </p>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                  }}
                >
                  {/* </Tooltip> */}
                </Box>
              </Box>
              {/* Column 2 */}
              <Box>
                <a
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                  href={person?.company?.profile_url}
                  target="_blank"
                >
                  <img src={company_icon} alt="company" />
                  <p style={{ fontSize: '1rem' }}>{person?.company?.name}</p>
                </a>
              </Box>
              {/* Column 3 */}
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '90%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '10px',
                    padding: '0.5rem',
                    backgroundColor: '#EBF7FA',
                    width: '15%',
                    height: 'auto',
                    gap: '0.5rem',
                  }}
                >
                  <Icon icon="carbon:email" style={{ fontSize: '18px', color: '#00C1FE' }} />
                  &nbsp;
                  <p>To Do</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#EBF7FA',
                    borderRadius: '1rem',
                    padding: '0.5rem',
                    width: '15%',
                    height: 'auto',
                    gap: '0.5rem',
                  }}
                >
                  <img src={whatsapp} alt="email" width={20} height={20} />
                  <p>To Do</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#EBF7FA',
                    borderRadius: '1rem',
                    padding: '0.5rem',
                    width: '15%',
                    height: 'auto',
                    gap: '0.5rem',
                  }}
                >
                  <img src={sms} alt="email" width={20} height={20} />
                  <p>To Do</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#EBF7FA',
                    borderRadius: '1rem',
                    padding: '0.5rem',
                    width: '15%',
                    height: 'auto',
                    gap: '0.5rem',
                  }}
                >
                  <img src={linkedin_icon} alt="email" width={20} height={20} />
                  <p>To Do</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#EBF7FA',
                    borderRadius: '1rem',
                    padding: '0.5rem',
                    width: '15%',
                    height: 'auto',
                    gap: '0.5rem',
                  }}
                >
                  <img src={phone_icon} alt="email" width={20} height={20} />
                  <p>To Do</p>
                </div>
              </Box>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  backgroundColor: '#EBF7FA',
                  padding: '5px',
                  width: 'fit-content',
                }}
              >
                {person?.email?.map((email, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '1rem',
                      backgroundColor: '#EBF7FA',
                      // padding: '3px',
                    }}
                  >
                    <Icon icon="carbon:email" style={{ fontSize: '18px', color: '#00C1FE' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                      }}
                    >
                      <p
                        style={{ margin: 0, fontSize: '12px', cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(email?.email_id);
                          enqueueSnackbar('Email copied to clipboard', { variant: 'success' });
                        }}
                      >
                        {email?.email_id}
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                        }}
                      >
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {email?.email_user?.first_name} {email?.email_user?.last_name}
                        </p>
                        <p style={{ margin: 0, fontSize: '12px' }}>{email?.createdAt?.slice(0, 10)}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {person?.email?.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      cursor: person?.email?.length > 0 ? 'not-allowed' : 'pointer',
                    }}
                    // onClick={() => person?.email?.length === 0 && setOpenAddEmailDialog(true)}
                  >
                    <Tooltip arrow placement="top" title="Email not added">
                      <Icon
                        icon="carbon:email"
                        style={{
                          fontSize: '20px',
                          color: person?.email?.length > 0 ? '1px solid green' : '1px solid red',
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                {/* {person?.email?.map((email, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      cursor: 'pointer',
                    }}
                    // make this div copyable
                    key={index}
                    onClick={() => {
                      navigator.clipboard.writeText(email?.email_id);
                      enqueueSnackbar('Email copied to clipboard', { variant: 'success' });
                    }}
                  >
                    <Icon icon="carbon:email" style={{ fontSize: '18px', color: '#00C1FE' }} />
                    <p style={{ margin: 0, fontSize: '12px' }}>{email?.email_id}</p>
                  </div>
                ))} */}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      {openAddJobPostDialog && (
        <AssociateJobPostWithPerson
          open={openAddJobPostDialog}
          onClose={() => setOpenAddJobPostDialog(false)}
          person={person}
        />
      )}
      <AddPersonEmailDialog
        open={openAddEmailDialog}
        onClose={() => setOpenAddEmailDialog(false)}
        onSave={(personalEmails, businessEmails) => {
          handleEmailSave(personalEmails, businessEmails);
        }}
      />
      <AddPersonPhoneNumberDialog
        open={openAddPhoneDialog}
        onClose={() => setOpenAddPhoneDialog(false)}
        onSave={handlePhoneSave}
      />
    </div>
  );
}


{/* <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0.5rem',
                marginTop: '1rem',
                gap: '1rem',
              }}
            >
              <Tooltip title={person?.phone_number?.length > 0 ? '' : 'Add phone number'} placement="top">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: person?.phone_number?.length > 0 ? '2px solid green' : '2px solid red',
                    borderRadius: '1rem',
                    padding: '0.5rem',
                    cursor: person?.phone_number?.length > 0 ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => person?.phone_number?.length === 0 && setOpenAddPhoneDialog(true)}
                >
                  <img src={phone_icon} alt="phone" className="w-6 h-6" />
                </div>
              </Tooltip>
              <Tooltip title={person?.email?.length > 0 ? '' : 'Add email'} placement="top">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: person?.email?.length > 0 ? '2px solid green' : '2px solid red',
                    borderRadius: '1rem',
                    padding: '0.5rem',
                    cursor: person?.email?.length > 0 ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => person?.email?.length === 0 && setOpenAddEmailDialog(true)}
                >
                  <img src={email_icon} alt="email" className="w-6 h-6" />
                  {person?.email?.length > 0 && <p className="ml-2">{person?.email[0]?.email_id}</p>}
                </div>
              </Tooltip>
            </div> */}

            {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className={`flex items-center ml-4 ${
              person?.email[0]?.is_email_sent === true
                ? 'border-green-500 cursor-not-allowed'
                : 'border-red-500 cursor-pointer'
            } border-2 rounded-full p-1`}
          >
            <img src={email_icon} alt="email" className="w-6 h-6" />
          </div>
          <div
            className={`flex items-center ml-4 ${
              person?.email?.is_email_sent ? 'border-green-500 cursor-not-allowed' : 'border-red-500 cursor-pointer'
            } border-2 rounded-full p-1`}
          >
            <img src={whatsapp} alt="email" className="w-6 h-6" />
          </div>
          <div
            className={`flex items-center ml-4 ${
              person?.email?.is_email_sent ? 'border-green-500 cursor-not-allowed' : 'border-red-500 cursor-pointer'
            } border-2 rounded-full p-1`}
          >
            <img src={sms} alt="email" className="w-6 h-6" />
          </div>
          <div
            className={`flex items-center ml-4 ${
              person?.email?.is_email_sent ? 'border-green-500 cursor-not-allowed' : 'border-red-500 cursor-pointer'
            } border-2 rounded-full p-1`}
          >
            <img src={linkedin_icon} alt="email" className="w-6 h-6" />
          </div>
          <div
            className={`flex items-center ml-4 ${
              person?.email?.is_email_sent ? 'border-green-500 cursor-not-allowed' : 'border-red-500 cursor-pointer'
            } border-2 rounded-full p-1`}
          >
            <img src={phone_icon} alt="email" className="w-6 h-6" />
          </div>
        </div> */}
        {/* <div>
          <Button
            variant="outlined"
            size="large"
            className="text-white border-[#00C1FE] bg-[#00C1FE] hover:border-[#00C1FE] hover:bg-[#00C1FE] w-[160px]"
            style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)', marginRight: '1rem' }}
            onClick={() => setOpenAddJobPostDialog(true)}
          >
            Default
          </Button>
        </div> */}