import { ArrowBack, Refresh } from '@mui/icons-material';
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CandidatePipelineTable } from './components';
import { StartRoleWorkingDialog } from './candidates-working';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import { MarkTaskAsDone } from './candidates-working/components';

function RoleApplications(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const userId = localStorage.getItem('userId');
  // const location = useLocation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [openStartRoleWorkingDialog, setOpenStartRoleWorkingDialog] = useState(false);
  const [roleCandidates, setRoleCandidates] = useState([]);
  const [roleStatus, setRoleStatus] = useState();
  const [canStartRole, setCanStartRole] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ongoingTask, setOngoingTask] = useState();
  const [taskStartTime, setTaskStartTime] = useState();
  const [sequence, setSequence] = useState();
  const [elapsedTime, setElapsedTime] = useState(); // New state for counter
  const [timerInterval, setTimerInterval] = useState(null); // Track the interval
  const [openMarkDoneDialog, setOpenMarkDoneDialog] = useState(false);
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F688F2',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'New',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'In Review',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Available',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Engaged',
      value: 25,
      borderTop: '#EBB794',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Hired',
      value: 25,
      borderTop: '#67E4BB',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Offered',
      value: 25,
      borderTop: '#E0CC38',
      buttonFontSize: '20px',
    },
    {
      buttonText: 'Rejected',
      value: 25,
      borderTop: '#B0CDCF',
      buttonFontSize: '20px',
    },
  ];

  const getCandidateByRoleId = useCallback(() => {
    setLoading(true);
    try {
      Get(
        {
          roleId: roleId,
          page: page,
          limit: limit,
        },
        API_URLS.getRoleCandidatesById,
        (resp) => {
          setRoleCandidates(resp?.data);
          setRoleStatus(resp?.data?.roleStatus);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId]);
  const formatElapsedTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const startCounter = (startTime) => {
    const start = new Date(startTime).getTime();
    const interval = setInterval(() => {
      const now = Date.now();
      const diff = now - start;
      setElapsedTime(formatElapsedTime(diff));
    }, 1000);

    setTimerInterval(interval);
  };

  const stopCounter = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  const getRoleStatus = useCallback(
    (roleStatus) => {
      if (roleStatus?.length === 0) {
        setCanStartRole(true);
        stopCounter();
        return;
      } else {
        let isInProgress = false;
        let taskDetails = null;

        roleStatus?.forEach((status) => {
          if (status?.status === 'In-Progress' && status?.user_id == userId) {
            isInProgress = true;
            taskDetails = status;
          }
        });

        if (isInProgress && taskDetails) {
          setCanStartRole(false);
          setOngoingTask(taskDetails?.sub_task ? taskDetails?.sub_task : taskDetails?.primary_task);

          const readableTime = new Date(taskDetails.start_time).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
          setTaskStartTime(readableTime);

          // Start the counter
          startCounter(taskDetails.start_time);
        } else {
          setCanStartRole(true);
          setOngoingTask(null);
          setTaskStartTime(null);
          setElapsedTime(null);
          stopCounter(); // Stop counter when no task is in progress
        }
      }
    },
    [userId, timerInterval]
  );

  useEffect(() => {
    return () => stopCounter(); // Cleanup interval on unmount
  }, []);

  // Simulate fetching roleStatus and updating based on API response
  useEffect(() => {
    if (roleStatus?.length > 0) {
      getRoleStatus(roleStatus);
    }
  }, [roleStatus, userId]); // Avoid redundant dependency like `getRoleStatus` or `userId`.

  // implement the fade loader from react-spinners until the data is fetched
  const startRole = (payload) => {
    try {
      Post(
        {
          roleId: roleId,
          userId: userId,
          primaryTask: payload?.primaryTask ? payload?.primaryTask : null,
          subTask: payload?.subTask ? payload?.subTask : null,
        },
        API_URLS.startRoleByRecruiter,
        (resp) => {
          enqueueSnackbar('Role started successfully', { variant: 'success' });
          setOpenStartRoleWorkingDialog(false);
          getCandidateByRoleId();
        },
        (error) => {
          console.log(error);
          enqueueSnackbar(error?.response?.data?.message || 'Something went wrong', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const getSequence = () => {
    try {
      Get(
        {
          roleId: roleId,
          userId: userId,
        },
        API_URLS.findSequenceByRoleId,
        (resp) => {
          // console.log("djsjdksajdksa", resp)
          setSequence(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSequence();
  }, [roleId, userId]);
  useEffect(() => {
    console.log('Page:', page, limit);
  }, [page, limit]);
  
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
  }
  const debouncedPage = useDebounce(page, 300);
  const debouncedLimit = useDebounce(limit, 300);

  useEffect(() => {
    getCandidateByRoleId();
  }, [debouncedPage, debouncedLimit, getCandidateByRoleId]);

  return (
    <div style={{ width: '97%', margin: 'auto', height: '90vh' }}>
      <>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <ArrowBack
            fontSize="small"
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              color: '#00C1FE',
              cursor: 'pointer',
            }}
          />
          &nbsp;&nbsp;{' '}
          <Typography
            variant="subtitle2"
            sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Dashboard
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>Candidates associated to</p>
            {/* {loading ? (
              <Skeleton variant="text" width="10%" style={{ textAlign: 'left' }} />
            ) : ( */}
            <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px', marginLeft: '10px' }}>
              {roleCandidates?.roleTitle}
            </p>
            {/* )} */}
          </Box>

          <Box display="flex" alignItems="center" marginRight="1rem">
            <Box
              sx={{
                backgroundColor: '#FFC9C9',
                color: 'red',
                padding: '15px',
                display: 'flex',
                alignItems: 'center',
                marginRight: '5px',
                borderRadius: '10px',
                marginTop: '15px',
              }}
            >
              <Typography
                variant="subtitle"
                sx={{
                  color: 'red',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                {!canStartRole && ongoingTask && elapsedTime ? `${ongoingTask}, ${elapsedTime}` : 'No task In-Progress'}
              </Typography>
            </Box>

            {!canStartRole && (
              <Button
                disabled={!sequence}
                sx={{
                  background: '#00C1FE',
                  color: '#fff',
                  marginRight: '10px',
                  width: '150px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: '#00C1FE',
                    color: '#fff',
                  },
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '15px',
                }}
                onClick={() => {
                  setOpenMarkDoneDialog(true);
                }}
              >
                Mark Task as Done
              </Button>
            )}
            {!canStartRole && (
              <Tooltip title={!sequence ? 'Define sequence for the role to access working' : ''}>
                <Button
                  disabled={!sequence}
                  sx={{
                    background: '#00C1FE',
                    color: '#fff',
                    width: '150px',
                    border: '1px solid #00C1FE',
                    '&:hover': {
                      background: '#00C1FE',
                      color: '#fff',
                    },
                    borderRadius: '5px',
                    padding: '10px',
                    marginTop: '15px',
                  }}
                  onClick={() => {
                    navigate(`/recruiter/jobApplicants/candidateWorking/${roleId}`);
                  }}
                >
                  Role Working
                </Button>
              </Tooltip>
            )}

            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                marginLeft: '10px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              disabled={canStartRole}
              onClick={() => {
                navigate(`/recruiter/jobApplicants/roleSequence/${roleId}`);
              }}
            >
              Sequences
            </Button>
            <Button
              sx={{
                background: '#00C1FE',
                color: '#fff',
                width: '150px',
                marginLeft: '10px',
                border: '1px solid #00C1FE',
                '&:hover': {
                  background: '#00C1FE',
                  color: '#fff',
                },
                borderRadius: '5px',
                padding: '10px',
                marginTop: '15px',
              }}
              disabled={!canStartRole}
              onClick={() => {
                setOpenStartRoleWorkingDialog(true);
              }}
            >
              {canStartRole ? 'Start a Task' : 'In Progress'}
            </Button>
          </Box>
        </Box>

        <br/>
          <Button
                sx={{
                  background: '#00C1FE',
                  color: '#fff',
                  width: '110px',
                  height: '40px',
                  border: '1px solid #00C1FE',
                  '&:hover': {
                    background: '#00C1FE',
                    color: 'white',
                  },
                  borderRadius: '5px',
                  padding: '5px',
                }}
                onClick={() => {
                  getCandidateByRoleId();
                }}
              >
                <Refresh />
              </Button>
        {/* <br /> */}
        <CandidatePipelineTable
          buttons={null}
          onButtonClick={(value) => {}}
          showHiringPipeline={true}
          onPageChange={(newPage) => {
            if (newPage !== page) setPage(newPage);
          }}
          onRowsPerPageChange={(newLimit) => {
            if (newLimit !== limit) setLimit(newLimit);
          }}
          candidates={roleCandidates}
          totalCandidates={roleCandidates?.pagination?.totalCandidates}
          showButtons={false}
          loading={loading}
          roleId={roleId}
          onReload={() => getCandidateByRoleId()}
        />
      </>
      {/* )} */}

      {openMarkDoneDialog && (
        <MarkTaskAsDone
          open={openMarkDoneDialog}
          onClose={() => {
            setOpenMarkDoneDialog(false);
          }}
          roleId={roleId}
          onReload={() => getCandidateByRoleId()}
        />
      )}
      {openStartRoleWorkingDialog && (
        <StartRoleWorkingDialog
          open={openStartRoleWorkingDialog}
          onClose={() => {
            setOpenStartRoleWorkingDialog(false);
          }}
          onSubmit={(payload) => startRole(payload)}
        />
      )}
    </div>
  );
}

export default RoleApplications;
