import { Icon } from '@iconify/react';
import { Badge, Box, Button, List, ListItem, ListItemIcon, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function RoleSequenceSteps({ onSelectedStep, communicationData, onStepRemoved, onStepAdded, logs, totalCandidates }) {
  console.log('dsdsads', logs , totalCandidates);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addIndex, setAddIndex] = useState(null);
  const [selectedStep, setSelectedStep] = useState();

  const [outreachEmail, setOutreachEmail] = useState(0);
  useEffect(() => {
    if(logs?.length > 0){
      const outreachEmailCount = logs.filter((log) => log.channel === 'Outreach Email').length;
      setOutreachEmail(outreachEmailCount);
      console.log('outreachEmailCount', outreachEmailCount);
    }
  }, [logs]);
  useEffect(() => {
    if (!selectedStep) {
      onSelectedStep(communicationData[0]);
    }
  }, [selectedStep]);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget); // Set the popover anchor element
    setAddIndex(index); // Store the index of the button that was clicked
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAddStep = (type) => {
    let newStep;
    const emailFollowUps = communicationData.filter((step) => step?.title?.includes('Follow Up Email'));
    const whatsappFollowUps = communicationData.filter((step) => step?.title?.includes('WhatsApp Message'));
    const callFollowUps = communicationData.filter((step) => step?.title?.includes('Call'));
    const liFollowUps = communicationData.filter((step) => step?.title?.includes('LI Connection'));
    const smsFollowUps = communicationData.filter((step) => step?.title?.includes('SMS'));
    const inMailFollowUps = communicationData.filter((step) => step?.title?.includes('InMail'));
    if (type === 'Email') {
      const nextEmailIndex = emailFollowUps.length + 1;
      newStep = {
        title: `Follow Up Email ${nextEmailIndex}`,
        icon: 'ic:baseline-email',
        color: '#00C1FE',
        isSaved: false,
        details: [
          { label: 'Subject line', value: '' },
          { label: 'Content', value: '' },
        ],
        stats: [
          { label: 'Emailed', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
          { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 0 },
          { label: 'Auto reply', icon: 'mdi:auto-mode', color: '#3F8CFF', count: 0 },
          { label: 'Potential reply', icon: 'tabler:message-up', color: '#43EE3F', count: 0 },
        ],
        instance: nextEmailIndex,
      };
    } else if (type === 'WhatsApp') {
      newStep = {
        title: `WhatsApp Message ${whatsappFollowUps.length + 1}`,
        icon: 'logos:whatsapp-icon',
        color: '#25D366',
        isSaved: false,
        details: [
          { label: 'Subject line', value: '' },
          { label: 'Content', value: '' },
        ],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 0 },
          { label: 'Replied', icon: 'hugeicons:message-02', color: 'blue', count: 0 },
          { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 0 },
        ],
        instance: whatsappFollowUps.length + 1,
      };
    } else if (type === 'Connection Request') {
      newStep = {
        title: `LI Connection ${liFollowUps.length + 1}`,
        icon: 'ri:linkedin-fill',
        color: '#0076B2',
        isSaved: false,
        details: [
          { label: 'Subject line', value: '' },
          { label: 'Content', value: '' },
        ],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Replied', icon: 'codicon:verified-filled', color: '#E5B137', count: 60 },
          { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 60 },
        ],
        instance: liFollowUps.length + 1,
      };
    } else if (type === 'SMS') {
      newStep = {
        title: `SMS ${smsFollowUps.length + 1}`,
        icon: 'material-symbols:sms',
        color: '#009688',
        isSaved: false,
        details: [
          { label: 'Subject line', value: '' },
          { label: 'Content', value: '' },
        ],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Replied', icon: 'hugeicons:message-02', color: 'blue', count: 60 },
          { label: 'No response', icon: 'bitcoin-icons:cross-filled', color: 'red', count: 60 },
        ],
        instance: smsFollowUps.length + 1,
      };
    } else if (type === 'Call') {
      newStep = {
        title: `Call ${callFollowUps.length + 1}`,
        icon: 'material-symbols:call',
        color: '#60D669',
        isSaved: false,
        details: [
          { label: 'Subject line', value: '' },
          { label: 'Content', value: 'Any' },
        ],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Responded', icon: 'solar:outgoing-call-bold', color: '#F7CB45', count: 60 },
          { label: 'No response', icon: 'subway:call-3', color: 'red', count: 60 },
        ],
        instance: callFollowUps.length + 1,
      };
    } else if (type === 'InMail') {
      newStep = {
        title: `InMail ${inMailFollowUps.length + 1}`,
        icon: 'mdi:linkedin',
        color: '#C8773F',
        isSaved: false,
        details: [
          { label: 'Subject line', value: '' },
          { label: 'Content', value: '' },
        ],
        stats: [
          { label: 'Messaged', icon: 'mdi:tick', color: '#3FC28A', count: 90 },
          { label: 'Replied', icon: 'tabler:message-up', color: '#43EE3F', count: 60 },
          { label: 'No response', icon: 'lucide:refresh-cw-off', color: 'red', count: 60 },
        ],
        instance: inMailFollowUps.length + 1,
      };
    }
    // const addIndex = communicationData.findIndex((step) => step.title.includes(type));

    const updatedData = [
      ...communicationData.slice(0, addIndex + 1),
      newStep,
      ...communicationData.slice(addIndex + 1),
    ];

    onStepAdded(updatedData);
    handlePopoverClose();
  };

  const handleStepClick = (index) => {
    setSelectedStep(communicationData[index]);
    onSelectedStep(communicationData[index]);
  };
  const handleRemoveStep = (index) => {
    // if index is 0 return
    if (index === 0) return;
    const updatedData = communicationData?.filter((_, i) => i !== index);
    // Reassign instances for follow-up emails
    const reindexedData = updatedData.map((item, i) => {
      if (item.title.includes('Follow Up Email')) {
        return { ...item, instance: i + 1 }; // Update instance numbers based on order
      } else if (item.title.includes('WhatsApp Message')) {
        return { ...item, instance: i + 1 }; // Update instance numbers based on order
      } else if (item.title.includes('Call')) {
        return { ...item, instance: i + 1 }; // Update instance numbers based on order
      } else if (item.title.includes('LI Connection')) {
        return { ...item, instance: i + 1 }; // Update instance numbers based on order
      } else if (item.title.includes('SMS')) {
        return { ...item, instance: i + 1 }; // Update instance numbers based on order
      } else if (item.title.includes('InMail')) {
        return { ...item, instance: i + 1 }; // Update instance numbers based on order
      }
      return item;
    });

    onStepRemoved(reindexedData);
  };

  console.log('selectedstep', selectedStep);
  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
          Total Candidates: {totalCandidates}
        </p>
      </Box>
      {/* map the communication data to the communication card */}
      {communicationData?.map((step, index) => (
        <>
          <CommunicationCard
            key={index}
            step={step}
            onClick={() => handleStepClick(index)}
            onRemove={() => handleRemoveStep(index)}
            outreachEmailCount={outreachEmail}
          />
          <Box display="flex" justifyContent="center" alignItems="center" margin="1.5rem">
            <Button
              sx={{
                backgroundColor: '#DADAF9',
                width: '5%',
                maxWidth: '5%',
                '&:hover': { backgroundColor: '#DADAF9 !important' },
              }}
              onClick={(event) => handlePopoverOpen(event, index)}
            >
              <Icon icon="pajamas:plus" style={{ fontSize: '25px' }} />
            </Button>
          </Box>
        </>
      ))}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <List>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('Email')}>
            {/* Icon and text for "Sourced" */}
            <ListItemIcon>
              <Icon icon="ic:baseline-email" style={{ fontSize: '20px', color: '#00C1FE' }} />
            </ListItemIcon>
            <ListItemText primary="Email" sx={{ fontSize: '8px', color: '#00C1FE' }} />
          </ListItem>

          <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('WhatsApp')}>
            {/* Icon and text for "Messaged" */}
            <ListItemIcon>
              <Icon icon="logos:whatsapp-icon" style={{ fontSize: '20px' }} />
            </ListItemIcon>
            <ListItemText primary="WhatsApp" sx={{ fontSize: '12px', color: '#60D669' }} />
          </ListItem>

          <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('SMS')}>
            {/* Icon and text for "Opened" */}
            <ListItemIcon>
              <Icon icon="material-symbols:sms" style={{ fontSize: '20px', color: '#009688' }} />
            </ListItemIcon>
            <ListItemText primary="SMS" sx={{ fontSize: '12px', color: '#009688' }} />
          </ListItem>

          <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('Connection Request')}>
            {/* Icon and text for "Clicked" */}
            <ListItemIcon>
              <Icon icon="flowbite:linkedin-solid" style={{ fontSize: '20px', color: '#007' }} />
            </ListItemIcon>
            <ListItemText primary="Connection Request" sx={{ fontSize: '12px', color: '#7D8592' }} />
          </ListItem>

          <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('Call')}>
            {/* Icon and text for "Replied" */}
            <ListItemIcon>
              <Icon icon="material-symbols:call" style={{ fontSize: '20px', color: '#D1D42B' }} />
            </ListItemIcon>
            <ListItemText primary="Call" sx={{ fontSize: '12px', color: '#7D8592' }} />
          </ListItem>

          <ListItem style={{ cursor: 'pointer' }} onClick={() => handleAddStep('InMail')}>
            {/* Icon and text for "Interested" */}
            <ListItemIcon>
              <Icon icon="mdi:linkedin" style={{ fontSize: '20px', color: '#C8773F' }} />
            </ListItemIcon>
            <ListItemText primary="InMail" sx={{ fontSize: '12px', color: '#7D8592' }} />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}

export default RoleSequenceSteps;

function CommunicationCard({ step, onRemove, onClick, outreachEmailCount }) {
  const { title, icon, color, details, stats } = step;
  console.log('step', step);
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to handle the toggle for expanding/collapsing content
  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ backgroundColor: 'white', borderRadius: 1, border: `1px solid ${color}`, mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <Box display="flex">
          {step?.isSaved ? (
            <Icon icon={icon} style={{ fontSize: '30px', color }} />
          ) : (
            <Tooltip title="Step unsaved" placement="top" arrow>
              <Badge color="error" variant="dot">
                {' '}
                <Icon icon={icon} style={{ fontSize: '30px', color }} />{' '}
              </Badge>
            </Tooltip>
          )}
          <Typography variant="h6" sx={{ ml: 2, fontWeight: 'normal', cursor: 'pointer' }} onClick={onClick}>
            {title}
          </Typography>
        </Box>
        <Tooltip title={title === 'Outreach Email' ? 'Step cannot be removed' : 'Remove step'} placement="top">
          <Icon
            icon="bitcoin-icons:cross-filled"
            style={{
              fontSize: '25px',
              cursor: 'pointer',
              color: title === 'Outreach Email' ? 'grey' : 'red',
            }}
            disabled={title === 'Outreach Email'}
            onClick={onRemove}
          />
        </Tooltip>
      </Box>
      <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '5px' }} />

      <>
        {details?.map((detail, index) => {
          // Truncate logic for message and subject
          const shouldTruncate = detail?.value?.length > 50;
          const displayedValue = isExpanded || !shouldTruncate ? detail?.value : detail?.value?.substring(0, 50) + '...';

          // Render only if there are values for subject or message
          const isSubject = detail?.label === 'Subject line' && detail?.value;
          const isMessage = detail?.label === 'Content' && detail?.value;

          // Only show if the detail is either subject or content
          if (isSubject || isMessage) {
            return (
              <Box
                display="flex"
                sx={{ paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px' }}
                key={index}
                onClick={shouldTruncate ? handleExpandToggle : undefined}
              >
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '5px' }}>
                  {detail.label}:
                </Typography>
                <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: displayedValue,
                    }}
                  />
                  {shouldTruncate && !isExpanded && (
                    <span style={{ cursor: 'pointer', color: 'blue', marginLeft: '5px' }}> (show more)</span>
                  )}
                </span>
              </Box>
            );
          } else if (isSubject && isMessage) {
            return (
              <Box
                display="flex"
                sx={{ paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px' }}
                key={index}
                onClick={shouldTruncate ? handleExpandToggle : undefined}
              >
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '5px' }}>
                  {detail.label}:
                </Typography>
                <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: displayedValue,
                    }}
                  />
                  {shouldTruncate && !isExpanded && (
                    <span style={{ cursor: 'pointer', color: 'blue', marginLeft: '5px' }}> (show more)</span>
                  )}
                </span>
              </Box>
            );
          }

          return null; // If neither subject nor message, do not render anything
        })}
        <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
      </>

      <Box display="flex" sx={{ padding: '20px' }} justifyContent="space-between" alignItems="center">
        {stats?.map((stat, index) => (
          <Tooltip key={index} title={stat.label} placement="bottom">
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <Icon icon={stat.icon} style={{ fontSize: '25px', color: stat.color, marginRight: '5px' }} />
              {step?.title === 'Outreach Email' && stat.label === 'Emailed' ? outreachEmailCount  : 0}
            </p>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
}
