import { Icon } from '@iconify/react';
import { CheckBox, Upload } from '@mui/icons-material';
import { Box, Button, CircularProgress, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Get, Post } from 'src/actions/API/apiActions';
import CandidateCVFormat from 'src/components/CandidateCVFormat';
import { notify } from 'src/components/notify';
import { API_URLS } from 'src/constants/apiURLs';
import { parseEducation, parseWorkHistory } from 'src/helpers/JSONParsors';
import ResumeForm from 'src/sections/resourcing/components/ResumeForm';
import UploadCVs from 'src/sections/resourcing/components/UploadCVs';

function ShowCandidateDetailsPage() {
  const { roleId, source, candidateId } = useParams();
  const [toggleInfo, setToggleInfo] = useState('cv');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [resumeData, setResumeData] = useState(null);
  const userId = parseInt(localStorage.getItem('userId'));

  
  const [dragOver, setDragOver] = useState(false);
  const [file, setFile] = useState();
  const [changeCandidateStatus, setChangeCandidateStatus] = useState(false);

  const handleFileUpload = (file) => {
    setFile(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the single file
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files[0]; // Get the single file
    if (file) {
      handleFileUpload(file);
    }
  };

  const cellStyle = {
    padding: '8px',
    textAlign: 'left',
    fontWeight: 'bold',
  };

  const [candidate, setCandidate] = useState(null);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);
  // const [openCVDialog, setOpenCVDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const getCandidateFromCVorLI = useCallback(() => {
    try {
      setIsCandidateLoading(true);
      Get(
        {
          id: candidateId,
          source: source,
          role_id: roleId,
        },
        API_URLS.searchCVorLI,
        (resp) => {
          setIsCandidateLoading(false);
          console.log('respljkasdgf', resp);
          if (source === 'LIProfiles') {
            setCandidate(resp?.data?.liProfile);
          } else if (source === 'Candidates') {
            setCandidate(resp?.data?.candidate);
          } else {
            setCandidate(null);
          }
          enqueueSnackbar(resp.message, { variant: 'success' });
        },
        (error) => {
          setIsCandidateLoading(false);
        }
      );
    } catch (error) {
      setIsCandidateLoading(false);
    }
  }, [candidateId, roleId, source]);

  const handleCVDownload = (candidateId, action, fileName) => {
    try {
      fetch(API_URLS.downloadCV, {
        method: 'POST',
        headers: {
          Accept:
            'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: candidateId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error downloading file');
          }
          const contentDisposition = response.headers.get('Content-Disposition');
          const filename = contentDisposition
            ? decodeURIComponent(contentDisposition.split('filename=')[1].replace(/"/g, ''))
            : fileName; // Default fallback filename
          // Fallback to candidateId if header is missing

          return response.blob().then((blob) => ({ blob, filename }));
        })
        .then(({ blob, filename }) => {
          if (action === 'view') {
            const file = new File([blob], filename, { type: blob.type });
            if (blob.type === 'application/pdf') {
              setSelectedFile({
                file: file,
                docx: null,
              }); // Set the file in the desired state
            }
            if (blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              try {
                const formData = new FormData();
                formData.append('file', file);
                Post(
                  formData,
                  API_URLS.extractText,
                  (resp) => {
                    // setSelectedCV({
                    //   docx: resp,
                    // });
                    setSelectedFile({
                      file: file,
                      docx: resp,
                    }); // Set the file in the desired state
                  },
                  (error) => {
                    console.log('error', error);
                  }
                );
              } catch (error) {
                console.log('error', error);
              }
            } else {
              setSelectedFile({
                file: file,
                docx: null,
              }); // Set the file in the desired state
            }
          } 
          // else {
          //   const url = window.URL.createObjectURL(blob);
          //   const a = document.createElement('a');
          //   a.href = url;
          //   a.download = filename;
          //   document.body.appendChild(a);
          //   a.click();
          //   a.remove();
          //   window.URL.revokeObjectURL(url);
          // }
          notify('File downloaded successfully', 'success', 1000);
        })
        .catch((error) => {
          notify('Error downloading file', 'error', 1000);
        });
    } catch (error) {
      notify('Error downloading file', 'error', 1000);
    }
  };

  const handleUpdateCandidateCV = (candidateId, file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('candidate_id', candidateId);
      formData.append('update_candidate', changeCandidateStatus);
      Post(
        formData,
        API_URLS.updateCV,
        (resp) => {
          notify('Canddiate Updated Succefully', 'success', 1000);
          getCandidateFromCVorLI();
        },
        (error) => {
          notify(error.message, 'error', 1000);
        }
      );
    } catch (error) {
      notify(error.message, 'error', 1000);
    }
  }

  const handleResumeData = (data, index) => {
    // setprocessedFile(index);
    if (data.type === 'application/pdf') {
      setResumeData(data);
    } else if (data.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      try {
        const formData = new FormData();
        formData.append('file', data.file);
        Post(
          formData,
          API_URLS.extractText,
          (resp) => {
            setResumeData({
              ...data,
              docx: resp,
            });
          },
          (error) => {
            console.log('error', error);
          }
        );
      } catch (error) {
        console.log('error', error);
      }
    } else if (data.type === 'application/msword') {
      setResumeData(data);
    } else {
      setResumeData(data);
    }
  };

  const handleUploadCV = (values, actions)=>{
    console.log("ksdgfvksdfsdf", values)
    const formData = new FormData();
    formData.append('file', resumeData?.file);
    formData.append('role_id', roleId);
    formData.append('user_id', userId);
    formData.append('attachment_date', new Date());
    formData.append('full_name', values?.fullName);
    formData.append('first_name', values?.fullName.split(' ')[0]);
    formData.append('last_name', values?.fullName.split(' ')[1]);
    formData.append('current_title', values?.currentJobTitle);
    formData.append('personal_phone', values?.phoneNumber);
    formData.append('current_company', values?.currentCompany);
    formData.append('personal_email', values?.email);
    formData.append('work_history', JSON.stringify(values?.experience));
    formData.append('qualifications', JSON.stringify(values?.education));
    formData.append('languages', []);
    formData.append('skills', JSON.stringify(values?.skills || []));
    formData.append('candidate_source', 'manual');
    formData.append('cv_job_board', values?.cv_job_board);
    formData.append('months_back', values?.months_back);
    formData.append('radius_miles', values?.radius_miles);
    formData.append('salary', values?.salary);

    console.log("hsfsdfsdfsdf", values, actions);
    try {
      Post(
        formData, 
        API_URLS.uploadCV,
        (resp) => {
          console.log('resp', resp);
          enqueueSnackbar('CV uploaded successfully', { variant: 'success' });
        },
        (error) => {
          console.log('error', error);
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    getCandidateFromCVorLI();
  }, [getCandidateFromCVorLI]);

  console.log('jhsdgvfbsdfsdf', candidate);
  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      {isCandidateLoading ? (
        <CircularProgress
          thickness={3}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-20px',
            marginLeft: '-20px',
          }}
        />
      ) : (
        <>
          <Box
            display="flex"
            sx={{ backgroundColor: '#D6F4FB', height: '80px' }}
            justifyContent="space-between"
            alignItems="center"
            padding="0 20px"
          >
            {/* Icon and Back Arrow */}
            <Icon
              icon="material-symbols:arrow-back"
              style={{ fontSize: '22px', cursor: 'pointer' }}
              onClick={() => navigate(`/resourcing/candidates/${roleId}`)}
            />

            {/* Client No */}
            <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
              <Typography variant="h5" sx={{ marginRight: '10px' }}>
                Client No:
              </Typography>
              <Typography sx={{ fontSize: '18px' }}>345</Typography>
            </Box>

            {/* Role Name */}
            <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
              <Typography variant="h5" sx={{ marginRight: '10px' }}>
                Role Title:
              </Typography>
              <Typography sx={{ fontSize: '18px' }}>UI UX Designer</Typography>
            </Box>

            {/* Status */}
            <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
              <Typography variant="h5" sx={{ marginRight: '10px' }}>
                Status:
              </Typography>
              <Typography sx={{ fontSize: '18px' }}>In Progress</Typography>
            </Box>

            {/* LIs */}
            <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
              <Typography variant="h5" sx={{ marginRight: '10px' }}>
                LIs:
              </Typography>
              <Typography sx={{ fontSize: '18px' }}>30</Typography>
            </Box>

            {/* CVs */}
            <Box display="flex" alignItems="center" sx={{ marginLeft: '20px' }}>
              <Typography variant="h5" sx={{ marginRight: '10px' }}>
                CVs:
              </Typography>
              <Typography sx={{ fontSize: '18px' }}>30</Typography>
            </Box>
          </Box>
          {source === 'Candidates' && (
            <>
              <Box display="flex" flexDirection="column" sx={{ backgroundColor: 'white', height: 'auto' }}>
                <Box display="flex" justifyContent="space-between">
                  <p style={{ fontSize: '1.3rem', paddingLeft: '20px', paddingTop: '20px', fontWeight: 'bold' }}>
                    {candidate?.full_name}: {candidate?.current_title}
                  </p>
                  <Button
                    sx={{
                      border: '2px solid #00C1FE',
                      padding: '5px 10px',
                      color: '#00C1FE',
                      width: '100px',
                      marginTop: '20px',
                      marginRight: '20px',
                    }}
                  >
                    Add CV
                  </Button>
                </Box>

                <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                  <Icon icon="ic:outline-email" style={{ fontSize: '18px' }} />
                  <p style={{ fontSize: '16px', marginLeft: '5px', color: '00C1FE' }}>
                    {' '}
                    {candidate?.personal_email || 'N/A'}{' '}
                  </p>
                </Box>
                <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                  <Icon icon="flowbite:mobile-phone-outline" style={{ fontSize: '18px' }} />
                  <p style={{ fontSize: '16px', marginLeft: '5px' }}>
                    {candidate?.personal_phone || candidate?.personal_contact || 'N/A'}
                  </p>
                </Box>
                <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
                <Box>
                  <ToggleButtonGroup
                    value={toggleInfo}
                    exclusive
                    onChange={(e, value) => {
                      if (value !== null) {
                        setToggleInfo(value);
                      }
                    }}
                    aria-label="company sections"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginLeft: '20px',
                      marginBottom: '10px', // Add margin bottom for spacing
                      paddingLeft: '20px',
                      paddingTop: '20px',
                    }}
                  >
                    <ToggleButton
                      value="cv"
                      aria-label="cv"
                      style={{
                        border: 'none',
                        color: toggleInfo === 'cv' ? '#000000' : '#b6afaf',
                        borderBottom: toggleInfo === 'cv' ? '3px solid #000000' : 'none',
                        borderRadius: 0,
                        paddingBottom: 2,
                        backgroundColor: 'white',
                        fontSize: '18px',
                        fontWeight: 'normal',
                      }}
                    >
                      CV
                    </ToggleButton>
                    <ToggleButton
                      value="activity"
                      aria-label="activity"
                      style={{
                        border: 'none',
                        color: toggleInfo === 'activity' ? '#000000' : '#b6afaf',
                        borderBottom: toggleInfo === 'activity' ? '3px solid #000000' : 'none',
                        borderRadius: 0,
                        paddingBottom: 2,
                        marginLeft: '10px',
                        backgroundColor: 'white',
                        fontSize: '18px',
                        fontWeight: 'normal',
                      }}
                    >
                      Activity
                    </ToggleButton>
                    <ToggleButton
                      value="experience"
                      aria-label="experience"
                      style={{
                        border: 'none',
                        color: toggleInfo === 'experience' ? '#000000' : '#b6afaf',
                        borderBottom: toggleInfo === 'experience' ? '3px solid #000000' : 'none',
                        borderRadius: 0,
                        paddingBottom: 2,
                        marginLeft: '10px',
                        backgroundColor: 'white',
                        fontSize: '18px',
                        fontWeight: 'normal',
                      }}
                    >
                      Experience
                    </ToggleButton>
                    <ToggleButton
                      value="preview"
                      aria-label="preview"
                      style={{
                        border: 'none',
                        color: toggleInfo === 'preview' ? '#000000' : '#b6afaf',
                        borderBottom: toggleInfo === 'preview' ? '3px solid #000000' : 'none',
                        borderRadius: 0,
                        paddingBottom: 2,
                        marginLeft: '10px',
                        backgroundColor: 'white',
                        fontSize: '18px',
                        fontWeight: 'normal',
                      }}
                    >
                      Preview
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <hr
                    style={{
                      width: '20%',
                      marginLeft: '20px',
                      color: '#CAC5C5',
                      border: 'none',
                      borderTop: '1px solid #CAC5C5',
                      marginTop: '-10px',
                    }}
                  />

                  <>
                    {toggleInfo === 'cv' && (
                      <>
                        <p
                          style={{
                            fontSize: '26px',
                            marginLeft: '20px',
                            textTransform: 'capitalize',
                            marginTop: '25px',
                            color: '#A90B0B',
                            fontWeight: 'bold',
                            letterSpacing: '4px',
                          }}
                        >
                          {candidate?.full_name}
                        </p>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          width="80%"
                        >
                          <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.personal_email} </p>
                          <p style={{ fontSize: '18px', marginLeft: '5px' }}>
                            {' '}
                            {candidate?.personal_phone || candidate?.personal_contact || 'N/A'}{' '}
                          </p>
                        </Box>
                        <Box display="flex" flexDirection="column" marginLeft="20px" marginTop="20px">
                          <p
                            style={{
                              fontSize: '22px',
                              textTransform: 'capitalize',
                              color: '#A90B0B',
                              fontWeight: 'bold',
                              letterSpacing: '4px',
                            }}
                          >
                            SUMMARY
                          </p>
                          <p style={{ fontSize: '18px', marginTop: '5px' }}>{candidate?.about || 'About Not found'}</p>
                        </Box>
                        <Box display="flex" flexDirection="column" marginLeft="20px" marginTop="20px">
                          <p
                            style={{
                              fontSize: '22px',
                              width: '90%',
                              textTransform: 'capitalize',
                              color: '#A90B0B',
                              fontWeight: 'bold',
                              letterSpacing: '4px',
                            }}
                          >
                            WORK EXPERIENCES
                          </p>
                          <p style={{ fontSize: '18px', marginTop: '5px', fontWeight: 'bold' }}>
                            {candidate?.current_title || 'N/A'}
                          </p>
                          {source === 'Candidates' &&
                            candidate?.work_history &&
                            candidate?.work_history?.map((work, index) => (
                              <>
                                {console.log('kjsdhfsdfsdf', candidate?.work_history)}
                                <Box key={index} display="flex" justifyContent="space-between" width="100%">
                                  <p style={{ fontSize: '16px', marginLeft: '10px', color: 'blue' }}>
                                    {parseWorkHistory(work)?.title}
                                  </p>
                                  <p style={{ fontSize: '16px', marginLeft: '10px', color: 'blue' }}>
                                    {parseWorkHistory(work)?.date}
                                  </p>
                                </Box>
                                <hr style={{ width: '100%', color: '#CAC5C5' }} />
                                {/* resposibilities */}
                                <p
                                  style={{
                                    fontSize: '14px',
                                    marginLeft: '10px',
                                    color: '#6E7787',
                                    marginTop: '10px',
                                  }}
                                >
                                  {parseWorkHistory(work)?.responsibilities}
                                </p>
                              </>
                            ))}
                          {/* <Box display="flex" marginLeft="20px" justifyContent="space-between" marginTop="10px">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                          {parseWorkHistory(candidate?.work_history[0])?.title}
                        </p>
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                          {parseWorkHistory(candidate?.work_history[0])?.date}
                        </p>
                      </Box> */}
                          {/* <p style={{ fontSize: '18px', marginTop: '5px', fontWeight: 'bold' }}>01/2019 - PRESENT</p> */}
                          {/* <p style={{ fontSize: '18px', marginTop: '5px', marginLeft: '10px' }}>
                        Strategically utilize various user research methods , such as customer journey mapping , user
                        interviews , and user testing , to provide valuable insights that inform design decisions and
                        optimize.
                      </p> */}
                        </Box>
                        <Box display="flex" flexDirection="column" marginLeft="20px" marginTop="20px">
                          <p
                            style={{
                              fontSize: '22px',
                              textTransform: 'capitalize',
                              color: '#A90B0B',
                              fontWeight: 'bold',
                              letterSpacing: '4px',
                            }}
                          >
                            EDUCATION
                          </p>
                          {source === 'Candidates' &&
                            candidate?.qualifications &&
                            candidate?.qualifications?.map((qualification, index) => (
                              <>
                                <Box key={index} display="flex" justifyContent="space-between" width="100%">
                                  <p style={{ fontSize: '16px', marginLeft: '10px', color: 'blue' }}>
                                    {parseEducation(qualification)?.universityDetail}
                                  </p>
                                  <p style={{ fontSize: '16px', marginLeft: '10px', color: 'blue' }}>
                                    {parseEducation(qualification)?.date}
                                  </p>
                                </Box>
                                <hr style={{ width: '100%', color: '#CAC5C5' }} />
                                {/* resposibilities */}
                                {/* <p
                              style={{
                                fontSize: '14px',
                                marginLeft: '10px',
                                color: '#6E7787',
                                marginTop: '10px',
                              }}
                            >
                              {parseEducation(work)?.responsibilities}
                            </p> */}
                              </>
                            ))}
                        </Box>
                      </>
                    )}
                    {toggleInfo === 'activity' && (
                      <>
                        <Box display="flex" marginTop="20px" marginLeft="20px" flexDirection="column">
                          {/* create an un ordered list with 3 items */}
                          <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                            <li style={{ fontWeight: 'bold', fontSize: '18px' }}>
                              {candidate?.registered_on_job_board || 'Job board registraion date not provided'}
                            </li>
                            <li style={{ fontWeight: 'bold', fontSize: '18px' }}>
                              {candidate?.last_active_on_job_board || 'Last Active not provided'}
                            </li>
                            <li style={{ fontWeight: 'bold', fontSize: '18px' }}>
                              Updated on CRM: {candidate?.updatedAt?.split('T')[0]}
                            </li>
                          </ul>
                          <div
                            style={{
                              maxHeight: '200px', // Approximate height for 3 rows
                              overflowY: 'auto',
                              border: '1px solid #ddd',
                              marginTop: '20px',
                              border: '1px solid #EBEBEB',
                              marginRight: '20px',
                              marginBottom: '20px',
                            }}
                          >
                            <table
                              style={{
                                borderCollapse: 'collapse',
                                width: '100%',
                                fontFamily: 'Arial, sans-serif',
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    position: 'sticky',
                                    top: 0,
                                    textAlign: 'left',
                                    padding: '14px',
                                    fontSize: '14px',
                                    backgroundColor: '#EBEBEB',
                                  }}
                                >
                                  <th style={cellStyle}>Job Title</th>
                                  <th style={cellStyle}>Locations</th>
                                  <th style={cellStyle}>Date Applied</th>
                                </tr>
                                <tr
                                  style={{
                                    textAlign: 'left',
                                    padding: '14px',
                                    fontSize: '14px',
                                  }}
                                >
                                  <td style={cellStyle}>{candidate?.current_title || 'N/A'}</td>
                                  <td style={cellStyle}>
                                    {candidate?.location || candidate?.current_location || 'N/A'}
                                  </td>
                                  <td style={cellStyle}> 01 Sep 2024</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Box>
                      </>
                    )}
                    {toggleInfo === 'experience' && (
                      <>
                        <Box display="flex" marginLeft="20px" justifyContent="space-between" marginTop="10px">
                          <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                            {parseWorkHistory(candidate?.work_history[0])?.title}
                          </p>
                          <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                            {parseWorkHistory(candidate?.work_history[0])?.date}
                          </p>
                        </Box>
                        <br />
                      </>
                    )}
                    {toggleInfo === 'preview' && (
                      <>
                        {candidate?.candidate_source === 'manual' && (
                          <>
                            {selectedFile ? (
                              <Box
                                sx={{
                                  height: '100%',
                                  overflowY: 'auto',
                                  padding: '20px',
                                }}
                              >
                                {selectedFile?.file?.type === 'application/pdf' ? (
                                  <>
                                    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                                      Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                                    </Typography>
                                    <iframe
                                      title={selectedFile?.file?.name}
                                      src={URL.createObjectURL(selectedFile?.file)}
                                      width="100%"
                                      height="100%"
                                      style={{ border: 'none' }}
                                    ></iframe>
                                  </>
                                ) : selectedFile?.file?.type ===
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                  <>
                                    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                                      Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                                    </Typography>
                                    <div
                                      style={{
                                        width: '100%',
                                        height: '100vh',
                                        overflowY: 'auto',
                                        padding: '20px',
                                        border: '1px solid #00C1FE',
                                        borderRadius: '10px',
                                      }}
                                      dangerouslySetInnerHTML={{ __html: selectedFile?.docx?.htmlContent }}
                                    ></div>
                                  </>
                                ) : (
                                  <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                                    No Preview: <span style={{ color: '#00C1FE' }}>{selectedFile?.file?.name}</span>
                                  </Typography>
                                )}
                              </Box>
                            ) : (
                              <Typography variant="subtitle1" sx={{ margin: '20px' }}>
                                No CV selected for preview
                              </Typography>
                            )}
                          </>
                        )}
                        <br />
                        {candidate?.candidate_source === 'reed' && (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '20px',
                              }}
                            >
                              <a
                                href={candidate?.source_link}
                                target="_blank"
                                style={{
                                  fontSize: '20px',
                                  marginLeft: '20px',
                                  fontWeight: 'bold',
                                  marginTop: '15px',
                                  color: '#00C1FE',
                                }}
                              >
                                View on Reed
                              </a>
                            </Box>
                            <UploadCVs
                              onFileSelected={(data, index) => {
                                handleResumeData(data, index);
                              }}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'space-between',
                                gap: '20px',
                              }}
                            >
                              {resumeData ? (
                                <Box
                                  sx={{
                                    height: 'auto',
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: '2px dashed #00C1FE',
                                    padding: '20px',
                                    overflowY: 'scroll',
                                  }}
                                >
                                  {resumeData?.type === 'application/pdf' ? (
                                    <>
                                      <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                                        Preview: <span style={{ color: '#00C1FE' }}>{resumeData?.file?.name}</span>
                                      </Typography>
                                      <iframe
                                        title={resumeData?.file?.name}
                                        src={URL.createObjectURL(resumeData?.file)}
                                        width="100%"
                                        height="710px"
                                      ></iframe>
                                    </>
                                  ) : resumeData?.type ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                    <>
                                      {/* set html content dangrously */}
                                      <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                                        Preview: <span style={{ color: '#00C1FE' }}>{resumeData?.file?.name}</span>
                                      </Typography>
                                      <div
                                        style={{
                                          width: '100%',
                                          height: 'auto',
                                          overflowY: 'scroll',
                                          padding: '20px',
                                          border: '1px solid #00C1FE',
                                          borderRadius: '10px',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: resumeData?.docx?.htmlContent }}
                                      ></div>
                                    </>
                                  ) : (
                                    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                                      No Preview: <span style={{ color: '#00C1FE' }}>{resumeData?.file?.name}</span>
                                    </Typography>
                                  )}
                                </Box>
                              ) : (
                                <Typography variant="subtitle1" sx={{ margin: '20px' }}>
                                  No CV selected for preview
                                </Typography>
                              )}
                              <Box
                                display="flex"
                                sx={{
                                  backgroundColor: 'white',
                                  height: '100vh',
                                  padding: '20px',
                                  overflowY: 'scroll',
                                  marginBottom: '5px',
                                }}
                              >
                                <ResumeForm
                                  initialValues={resumeData?.parsed_resume}
                                  onSubmit={(values, actions) => {
                                    handleUploadCV(values, actions);
                                  }}
                                />
                              </Box>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />

                    <p style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold', marginTop: '15px' }}>
                      Curent Status
                    </p>
                    <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="carbon:location" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.location || 'N/A'} </p>
                    </Box>
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="uil:suitcase-alt" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.current_title || 'N/A'} </p>
                    </Box>
                    {/* <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                  <Icon icon="fluent-mdl2:money" style={{ fontSize: '20px' }} />
                  <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.expected_salary} </p>
                </Box> */}
                    {/* <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                  <Icon icon="fluent-mdl2:office-chat" style={{ fontSize: '20px' }} />
                  <p style={{ fontSize: '18px', marginLeft: '5px' }}>English </p>
                </Box> */}
                    <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />

                    <p style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold', marginTop: '15px' }}>
                      Desired role
                    </p>
                    <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="carbon:location" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.preferred_locations[0]} </p>
                    </Box>
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="uil:suitcase-alt" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.desired_title || 'N/A'} </p>
                    </Box>
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="fluent-mdl2:money" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.expected_salary || 'N/A'} </p>
                    </Box>
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="solar:suitcase-line-duotone" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> Job Level not supplied </p>
                    </Box>
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="carbon:time" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.last_active_on_job_board} </p>
                    </Box>
                    {/* <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                  <Icon icon="solar:calendar-outline" style={{ fontSize: '20px' }} />
                  <p style={{ fontSize: '18px', marginLeft: '5px' }}>
                    {' '}
                    {parseEducation(candidate?.qualifications[0]?.date) }{' '}
                  </p>
                </Box> */}
                    <Box display="flex" marginLeft="20px" marginTop="10px" alignItems="center">
                      <Icon icon="ph:car-thin" style={{ fontSize: '20px' }} />
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}> {candidate?.transport_license} </p>
                    </Box>
                    <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />

                    <p style={{ fontSize: '20px', marginLeft: '20px', fontWeight: 'bold', marginTop: '15px' }}>
                      Key Skills
                    </p>
                    <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
                    <p style={{ fontSize: '18px', marginTop: '5px', marginLeft: '20px' }}>
                      {candidate?.skills &&
                        candidate?.skills?.map((skill) => (
                          <p
                            style={{
                              display: 'flex',
                              margin: '5px',
                              fontSize: '18px',
                              marginLeft: '5px',
                              // color: '#00C1FE',
                              display: 'inline-block',
                              marginRight: '10px',
                              border: '1px solid #00C1FE',
                              padding: '5px',
                              borderRadius: '5px',
                            }}
                          >
                            {skill}
                          </p>
                        ))}
                    </p>
                    <br />
                  </>
                </Box>
              </Box>
            </>
          )}
          {source === 'LIProfiles' && (
            <>
              <CandidateCVFormat candidate={candidate} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ShowCandidateDetailsPage;
