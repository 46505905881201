import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Tooltip, Box, IconButton } from '@mui/material';
import { UKFlag, USFlag } from 'src/assets/images';
import { CAFlag, company_icon, jobs_icon, person_general, UAEFlag } from './svg';
import { direct_companies, direct_jobs, direct_persons, sr_companies, sr_jobs, sr_persons, uk_flag_wadda, world_icon } from 'src/images';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const LeadStatsCard = ({ countryData, onCompanySelect, onPeopleSelect, onJobPostSelected, isExpanded, onExpandChange }) => {
  const { id, country, companies, persons, jobposts, sectors } = countryData;
  console.log("hjsdgfsdfsdfs", countryData)
  // State to control expansion of all cards
  const [expanded, setExpanded] = useState(isExpanded);

  //  set expanded state to true or false based on the value of isExpanded prop
  useEffect(() => {
    setExpanded(isExpanded);

    return () => {
      setExpanded(false);
    };
  }, [isExpanded]);

  return (
    <>
      {companies !== 0 && (
        <Card variant="outlined">
          <CardContent>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                <img
                  src={
                    id === 1
                      ? uk_flag_wadda
                      : id === 2
                      ? 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
                      : id === 3
                      ? 'https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg'
                      : id === 4
                      ? 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg'
                      : id === 6
                      ? 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg'
                      : id === 7
                      ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                      : id === 5
                      ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                      : id === 8
                      ? 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
                      : id === 9
                      ? 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg'
                      : id === 10
                      ? 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg'
                      : world_icon
                  }
                  alt={country}
                  style={{ marginRight: '8px' }}
                  height={30}
                  width={30}
                />{' '}
                {country}
              </Box>
              <IconButton onClick={() => onExpandChange()}>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </div>
            <br />
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => onCompanySelect(null, id)}
                >
                  <img src={company_icon} alt={country} style={{ marginRight: '8px' }} height={25} width={25} />
                  <Typography variant="body1" color="textSecondary" sx={{ cursor: 'pointer' }}>
                    {companies}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => onPeopleSelect(null, id)}>
                  <img src={person_general} alt={country} style={{ marginRight: '8px' }} height={25} width={25} />
                  <Typography variant="body1" color="textSecondary" sx={{ cursor: 'pointer' }}>
                    {persons}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => onJobPostSelected(null, id)}>
                  <img src={jobs_icon} alt={country} style={{ marginRight: '8px' }} height={25} width={25} />
                  <Typography variant="body1" color="textSecondary" sx={{ cursor: 'pointer' }}>
                    {jobposts}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            {expanded && (
              <>
                {sectors.map((sector) => (
                  <>
                    {sector?.companies !== 0 && (
                      <Grid container spacing={2} key={sector.id} sx={{ mb: 1 }}>
                        <Grid item xs={4}>
                          <Tooltip title={sector?.sector}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => onCompanySelect(sector?.id, id)}
                            >
                              <img
                                src={sector?.id === 1 ? direct_companies : sr_companies}
                                alt={country}
                                style={{ marginRight: '8px' }}
                                height={20}
                                width={20}
                              />
                              <Typography variant="body2" color="textSecondary" sx={{ cursor: 'pointer' }}>
                                {sector?.companies}
                              </Typography>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Tooltip title={sector?.sector}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => onPeopleSelect(sector?.id, id)}
                            >
                              <img
                                src={sector?.id === 1 ? direct_persons : sr_persons}
                                alt={country}
                                style={{ marginRight: '8px' }}
                                height={20}
                                width={20}
                              />
                              <Typography variant="body2" color="textSecondary" sx={{ cursor: 'pointer' }}>
                                {sector.persons}
                              </Typography>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Tooltip title={sector?.sector}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => onJobPostSelected(sector?.id, id)}
                            >
                              <img
                                src={sector?.id === 1 ? direct_jobs : sr_jobs}
                                alt={country}
                                style={{ marginRight: '8px' }}
                                height={20}
                                width={20}
                              />
                              <Typography variant="body2" color="textSecondary" sx={{ cursor: 'pointer' }}>
                                {sector?.jobposts}
                              </Typography>
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ))}
              </>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default LeadStatsCard;
