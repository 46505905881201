import React, { useCallback, useEffect, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { notify } from 'src/components/notify';
import { API_URLS } from 'src/constants/apiURLs';
import { LeadStatsCard } from './components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import Slider from 'react-slick';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  direct_companies,
  direct_jobs,
  direct_persons,
  sr_companies,
  sr_jobs,
  sr_persons,
  world_icon,
} from 'src/images';
import { company_icon, jobs_icon, person_general } from './components/svg';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import RegionStatsCard from './components/RegionStatsCard';

function LeadStats({ onCompanyClick, onPersonClick, onJobClick, startDate, endDate, findPeople, withContactInfo }) {
  const [leadStats, setLeadStats] = useState([]);
  const [leadStatsLoading, setLeadStatsLoading] = useState(false);
  const [sectorStats, setSectorStats] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [regionStats, setRegionStats] = useState([]);
  const [regions, setRegions] = useState([]);

  const [showRegionWiseCountries, setShowRegionWiseCountries] = useState(false);

  const getRegionStats = useCallback(() => {
    setLeadStatsLoading(true);
    try {
      Get(
        {
          startDate,
          endDate,
        },
        API_URLS.getRegionWiseLeadsContactStats,
        (res) => {
          console.log('jhdgfsdfsdfsdf', res?.data?.regionWiseLeads);
          setRegionStats(res?.data?.regionWiseLeads);
          setRegions(res?.data?.regionWiseLeads);
          setLeadStatsLoading(false);
        },
        (error) => {
          console.log(error);
          notify('Error in fetching job posts', 'error', 1000);
          setLeadStatsLoading(false);
        }
      );
    } catch (error) {
      notify('Error in fetching job posts', 'error', 1000);
      setLeadStatsLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getRegionStats();
  }, [getRegionStats]);

  const getLeadStats = (region) => {
    if (region === 'Total') {
      alert('Invalid Region');
    } else {
      setShowRegionWiseCountries(true);
      setLeadStatsLoading(true);
      try {
        Get(
          {
            startDate,
            endDate,
            region,
          },
          API_URLS.getCountryAndSectorWiseLeadsByRegion,
          (res) => {
            setLeadStats(res?.data?.countrywiseLeads);
            setSectorStats(res?.data?.sectorwiseLeads);
            setLeadStatsLoading(false);
          },
          (error) => {
            console.log(error);
            notify('Error in fetching job posts', 'error', 1000);
            setLeadStatsLoading(false);
          }
        );
      } catch (error) {
        notify('Error in fetching job posts', 'error', 1000);
        setLeadStatsLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   getLeadStats();
  // }, [getLeadStats]);

  // Filter out empty lead stats
  const validLeadStats = leadStats?.filter(
    (leadStat) => leadStat?.companies !== 0 && leadStat?.persons !== 0 && leadStat?.jobposts !== 0
  );

  const validRegionStats = regionStats?.filter(
    (leadStat) => leadStat?.companies !== 0 && leadStat?.persons !== 0 && leadStat?.jobposts !== 0
  );
  console.log('regiodsfsdfsdfnStats', regions);
  // Custom arrow components
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: '#00C1FE',
          borderRadius: '50%',
          padding: '5px',
          width: '30px',
          height: '30px',
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: '#00C1FE',
          borderRadius: '50%',
          padding: '5px',
          width: '30px',
          height: '30px',
          color: 'white',
        }}
        onClick={onClick}
      />
    );
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show three slides
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      {leadStatsLoading ? (
        <div className="flex items-center justify-center h-64">
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2}>
          {/* Total Card */}
          {showRegionWiseCountries && (
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card variant="outlined" sx={{ marginLeft: '5px' }}>
                <CardContent
                  style={{
                    height: expanded ? '210px' : '150px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'space-between',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onClick={() => setShowRegionWiseCountries(false)}
                    >
                      <img src={world_icon} alt="global" style={{ marginRight: '2px' }} height={30} width={30} /> &nbsp;
                      All Companies
                    </Box>
                    <IconButton onClick={() => setExpanded(!expanded)}>
                      {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                  <br />
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    {sectorStats?.map((sectorStat) => (
                      <>
                        {sectorStat?.sector === 'Total' && sectorStat?.companies !== 0 && (
                          <>
                            <Grid item xs={4}>
                              <div
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => {
                                  onCompanyClick(null, null);
                                }}
                              >
                                <img
                                  src={company_icon}
                                  alt="global"
                                  style={{ marginRight: '8px' }}
                                  height={25}
                                  width={25}
                                />
                                <Typography variant="body1" color="textSecondary">
                                  {sectorStat?.companies}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => {
                                  onPersonClick(null, null);
                                }}
                              >
                                <img
                                  src={person_general}
                                  alt="global"
                                  style={{ marginRight: '8px' }}
                                  height={25}
                                  width={25}
                                />
                                <Typography variant="body1" color="textSecondary">
                                  {sectorStat?.persons}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => {
                                  onJobClick(null);
                                }}
                              >
                                <img
                                  src={jobs_icon}
                                  alt="global"
                                  style={{ marginRight: '8px' }}
                                  height={25}
                                  width={25}
                                />
                                <Typography variant="body1" color="textSecondary">
                                  {sectorStat?.jobposts}
                                </Typography>
                              </div>
                            </Grid>
                          </>
                        )}
                      </>
                    ))}

                    {sectorStats?.map((sectorStat) => (
                      <>
                        {sectorStat?.companies !== 0 && sectorStat.sector !== 'Total' && (
                          <>
                            {expanded && (
                              <>
                                <Grid item xs={4}>
                                  <div
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                      onCompanyClick(sectorStat?.id, null);
                                    }}
                                  >
                                    <img
                                      src={sectorStat.id === 1 ? direct_companies : sr_companies}
                                      alt="global"
                                      style={{ marginRight: '8px' }}
                                      height={25}
                                      width={25}
                                    />
                                    <Typography variant="body1" color="textSecondary">
                                      {sectorStat?.companies}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                      onPersonClick(sectorStat?.id, null);
                                    }}
                                  >
                                    <img
                                      src={sectorStat.id === 1 ? direct_persons : sr_persons}
                                      alt="global"
                                      style={{ marginRight: '8px' }}
                                      height={25}
                                      width={25}
                                    />
                                    <Typography variant="body1" color="textSecondary">
                                      {sectorStat?.persons}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={4}>
                                  <div
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                      onJobClick(sectorStat?.id);
                                    }}
                                  >
                                    <img
                                      src={sectorStat.id === 1 ? direct_jobs : sr_jobs}
                                      alt="global"
                                      style={{ marginRight: '8px' }}
                                      height={25}
                                      width={25}
                                    />
                                    <Typography variant="body1" color="textSecondary">
                                      {sectorStat?.jobposts}
                                    </Typography>
                                  </div>
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {/* Slider for Other Cards */}
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Carousel
              responsive={responsive}
              arrows
              className="carousel"
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              itemClass="project-item"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              partialVisible={false}
              rewind={true}
              rewindWithAnimation={false}
              rtl={false}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {!showRegionWiseCountries &&
                validRegionStats?.map((regionStat) => (
                  <RegionStatsCard
                    key={regionStat?.region}
                    onRegionCountriesSelected={(region) => getLeadStats(region)}
                    regionData={regionStat}
                    onCompanySelect={(sector_id, country_id) => onCompanyClick(sector_id, country_id)}
                    onPeopleSelect={(sector_id, country_id) => onPersonClick(sector_id, country_id)}
                    onJobPostSelected={(sector_id, country_id) => onJobClick(sector_id, country_id)}
                    isExpanded={expanded}
                    onExpandChange={() => setExpanded(!expanded)}
                  />
                ))}
              {showRegionWiseCountries &&
                validLeadStats?.map((leadStat) => (
                  // <div key={leadStat.id} style={{ padding: '0 10px', marginLeft: '5px' }}>
                  <LeadStatsCard
                    key={leadStat.id}
                    countryData={leadStat}
                    onCompanySelect={(sector_id, country_id) => onCompanyClick(sector_id, country_id)}
                    onPeopleSelect={(sector_id, country_id) => onPersonClick(sector_id, country_id)}
                    onJobPostSelected={(sector_id, country_id) => onJobClick(sector_id, country_id)}
                    isExpanded={expanded}
                    onExpandChange={() => setExpanded(!expanded)}
                  />
                  // </div>
                ))}
            </Carousel>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default LeadStats;
