import { Box, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Morning } from './images';
import { Search } from '@mui/icons-material';
import { Icon } from '@iconify/react';

function SearchHistory(props) {
  const [selectedState, setSelectedState] = useState('recent');
  const showGreetings = () => {
    // Get the current time
    const now = new Date();
    const hours = now.getHours(); // Get the current hour (0-23)

    let greeting;

    // Determine the greeting based on the time of day
    if (hours >= 5 && hours < 12) {
      greeting = 'Good Morning,';
    } else if (hours >= 12 && hours < 17) {
      greeting = 'Good Afternoon,';
    } else if (hours >= 17 && hours < 21) {
      greeting = 'Good Evening,';
    } else {
      greeting = 'Good night,';
    }
    return greeting;
  };

  const message = showGreetings();
  const cellStyle = {
    padding: '8px',
    textAlign: 'left',
  };
  return (
    <div>
      <div
        style={{
          width: '95%',
          margin: 'auto',
          backgroundColor: 'white',
          border: '10px solid white',
          borderRadius: '10px',
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <img src={Morning} alt="morning" height="350px" width="350px" />
          <Typography sx={{ marginTop: '20px', fontSize: '32px' }}>{message} John</Typography>
        </Box>

        <Box display="flex" width="95%" margin="auto" marginTop="3rem">
          <TextField
            type="text"
            id="searchString"
            name="searchString"
            fullWidth
            placeholder="Get Started by typing anything here"
            onChange={(e) => {
              console.log("kjgfkdnmklfbsdfsd",e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="teenyicons:search-outline" style={{ fontSize: '24px', color: 'black' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: 'white',
              borderRadius: '3rem',
              '& .MuiOutlinedInput-root': {
                borderRadius: '3rem',
                '& fieldset': {
                  borderRadius: '3rem',
                  borderColor: '#CAC5C5',
                },
                '&:hover fieldset': {
                  borderColor: '#CAC5C5',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#CAC5C5',
                },
              },
              '& .MuiInputBase-root': {
                '&.Mui-focused': {
                  boxShadow: '0 0 0 1px #CAC5C5',
                },
              },
            }}
          />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="2rem">
          <ToggleButtonGroup
            value={selectedState}
            exclusive
            onChange={(e, value) => {
              if (value !== null) {
                setSelectedState(value);
              }
            }}
            aria-label="company sections"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              padding: 15,
              marginLeft: '20px',
            }}
          >
            <ToggleButton
              value="recent"
              aria-label="recent"
              style={{
                border: 'none',
                color: selectedState === 'recent' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selectedState === 'recent' ? '3px solid #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                backgroundColor: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Recent Searches
            </ToggleButton>
            <ToggleButton
              value="saved"
              aria-label="saved"
              style={{
                border: 'none',
                color: selectedState === 'saved' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selectedState === 'saved' ? '3px solid  #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                marginLeft: '10px',
                backgroundColor: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Saved Searched
            </ToggleButton>
            <ToggleButton
              value="history"
              aria-label="history"
              style={{
                border: 'none',
                color: selectedState === 'history' ? '3px solid  #00C1FE' : 'black',
                borderBottom: selectedState === 'history' ? '3px solid  #00C1FE' : 'none',
                borderRadius: 0,
                paddingBottom: 2,
                marginLeft: '10px',
                backgroundColor: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              History
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <hr style={{ width: '95%', margin: 'auto', marginTop: '-8px', color: '#CAC5C5' }} />

        {selectedState === 'recent' && (
          <>
            <Box display="flex" width="95%" margin="auto">
              <div
                style={{
                  height: 'auto',
                  width: '100%',
                  overflowY: 'auto',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <table
                  style={{
                    borderCollapse: 'collapse',
                    width: '100%',
                    fontFamily: 'Arial, sans-serif',
                  }}
                >
                  <tbody>
                    <tr
                      style={{
                        position: 'sticky',
                        top: 0,
                        textAlign: 'left',
                        padding: '18px',
                        fontSize: '14px',
                        backgroundColor: '#EBEBEB',
                      }}
                    >
                      <th style={cellStyle}>Recent Searches</th>
                      <th style={cellStyle}>Location</th>
                      <th style={cellStyle}>Date</th>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'left',
                        padding: '14px',
                        fontSize: '14px',
                        borderBottom: '1px solid #EBEBEB',
                      }}
                    >
                      <td style={cellStyle}>Freelance UX/UI Designer</td>
                      <td style={cellStyle}>South West London (SW1)</td>
                      <td style={cellStyle}> 01 Sep 2024</td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'left',
                        padding: '14px',
                        fontSize: '14px',
                        borderBottom: '1px solid #EBEBEB',
                      }}
                    >
                      <td style={cellStyle}>Freelance UX/UI Designer</td>
                      <td style={cellStyle}>South West London (SW1)</td>
                      <td style={cellStyle}> 01 Sep 2024</td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'left',
                        padding: '14px',
                        fontSize: '14px',
                        borderBottom: '1px solid #EBEBEB',
                      }}
                    >
                      <td style={cellStyle}>Freelance UX/UI Designer</td>
                      <td style={cellStyle}>South West London (SW1)</td>
                      <td style={cellStyle}> 01 Sep 2024</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Box>
          </>
        )}
        {selectedState === 'saved' && (
          <>
            <Box display="flex" width="95%" margin="auto">
              <div
                style={{
                  height: 'auto',
                  width: '100%',
                  overflowY: 'auto',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <table
                  style={{
                    borderCollapse: 'collapse',
                    width: '100%',
                    fontFamily: 'Arial, sans-serif',
                  }}
                >
                  <tbody>
                    <tr
                      style={{
                        position: 'sticky',
                        top: 0,
                        textAlign: 'left',
                        padding: '18px',
                        fontSize: '14px',
                        backgroundColor: '#EBEBEB',
                      }}
                    >
                      <th style={cellStyle}>Run saved searches</th>
                      <th style={cellStyle}>Location</th>
                      <th style={cellStyle}>Date</th>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'left',
                        padding: '14px',
                        fontSize: '14px',
                        borderBottom: '1px solid #EBEBEB',
                      }}
                    >
                      <td style={cellStyle}>Freelance UX/UI Designer</td>
                      <td style={cellStyle}>South West London (SW1)</td>
                      <td style={cellStyle}> 01 Sep 2024</td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'left',
                        padding: '14px',
                        fontSize: '14px',
                        borderBottom: '1px solid #EBEBEB',
                      }}
                    >
                      <td style={cellStyle}>Freelance UX/UI Designer</td>
                      <td style={cellStyle}>South West London (SW1)</td>
                      <td style={cellStyle}> 01 Sep 2024</td>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'left',
                        padding: '14px',
                        fontSize: '14px',
                        borderBottom: '1px solid #EBEBEB',
                      }}
                    >
                      <td style={cellStyle}>Freelance UX/UI Designer</td>
                      <td style={cellStyle}>South West London (SW1)</td>
                      <td style={cellStyle}> 01 Sep 2024</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Box>
          </>
        )}
        {selectedState === 'history' && (
          <>
            <Box display="flex" width="95%" margin="auto">
              <div
                style={{
                  height: '30VH',
                  width: '100%',
                  overflowY: 'auto',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <p
                  style={{
                    position: 'sticky',
                    top: 0,
                    textAlign: 'left',
                    padding: '18px',
                    fontSize: '14px',
                    backgroundColor: '#EBEBEB',
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  Saturday, Sep 7 , 2024
                </p>
                <br />
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <p style={{ fontSize: '18px' }}>3:13 PM</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <Box display="flex" flexDirection="column" width="100%">
                      <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
                        <Icon icon="teenyicons:search-outline" style={{ fontSize: '18px' }} />
                        <p style={{ fontSize: '18px', marginLeft: '5px', color: '00C1FE' }}>Search</p>
                      </Box>
                      <p style={{ fontSize: '18px', marginLeft: '5px' }}>
                        AND ("Agile" OR "Scrum" OR "Waterfall" OR "Kanban" OR "Prince2" OR "PMP" OR "Project
                        Management") AND ("Stakeholder Management" OR "Risk Management" OR "Budget Management" OR "Scope
                        Management") AND ("Software" OR "IT" OR "Technology" OR "Engineering")
                      </p>
                    </Box>
                  </Grid>
                  <br />
                </Grid>
                <hr style={{ width: '95%', margin: 'auto', marginTop: '1rem', color: '#CAC5C5' }} />
              </div>
            </Box>
          </>
        )}
      </div>
    </div>
  );
}

export default SearchHistory;
