import { ArrowBack, Refresh } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

function LIConnectionSending(props) {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [rows, setRows] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

  const getCandidateByRoleId = useCallback(() => {
    try {
      Get(
        {
          roleId: roleId,
        },
        API_URLS.getRoleCandidatesByIdWithoutPagination,
        (resp) => {
          //filter the candidates whose source is LIProfiles 
          const data = resp?.data?.data?.filter((candidate) => candidate.source === 'LIProfiles');
          setRows(data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      // enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId]);

  useEffect(() => {
    getCandidateByRoleId();
  }, [getCandidateByRoleId]);

  const handleSendRequest = (li)=>{
    try{
      Post(
        {
          linkedinProfileId: li,
        },
        API_URLS.updateLIConnectionStatusToPending,
        (resp) => {
          console.log('Connection request sent:', resp);
          enqueueSnackbar('LI profile converted to pending', { variant: 'success' });
          getCandidateByRoleId();
        },
        (error) => {
          console.log(error);
          enqueueSnackbar('Failed to send connection request', { variant: 'error' });
        }
      )
    }
    catch(error){
      console.log(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <br />
      <Button
        sx={{
          background: '#00C1FE',
          color: '#fff',
          width: '110px',
          height: '40px',
          border: '1px solid #00C1FE',
          '&:hover': {
            background: '#00C1FE',
            color: 'white',
          },
          borderRadius: '5px',
          padding: '5px',
        }}
        onClick={() => {
          getCandidateByRoleId();
        }}
      >
        <Refresh />
      </Button>
      <br />
      <br />
      <table
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderCollapse: 'collapse',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              S.No
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Candidate Name
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
                width: '25%',
              }}
            >
              LI Link
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Last Activity
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Status
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Recruiter
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Response Status
            </th>
            <th
              style={{
                border: '1px solid #D8D8D8',
                padding: '0px',
                color: '#6FA9FF',
                backgroundColor: '#F4F6FA',
                textAlign: 'center',
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index} style={{ borderBottom: index === rows.length - 1 ? 'none' : '1px solid #D8D8D8' }}>
              <td
                style={{
                  border: '1px solid #D8D8D8',
                  padding: '10px',
                  textAlign: 'center',
                  color: '#979797',
                }}
              >
                {index + 1}
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="text"
                  name="candidateName"
                  value={row?.full_name}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <a
                  href={row?.profile_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'block',
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  {row?.profile_link}
                </a>
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="email"
                  name="lastActivity"
                  value={
                    row?.lis_communication_logs?.length > 0
                      ? row.lis_communication_logs[row.lis_communication_logs.length - 1]?.channel
                      : null
                  }
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>

              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <p
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                >
                  {row?.connection_status ? row?.connection_status : 'N/A'}
                  {row?.connection_status === 'In-Progress' && (
                    <CircularProgress size={20} style={{ color: '#FFC107' }} />
                  )}
                </p>
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="email"
                  name="lastActivity"
                  disabled
                  value={row?.connection_sending_profile ? row?.connection_sending_profile : 'N/A'}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>
              <td style={{ border: '1px solid #D8D8D8', padding: '10px' }}>
                <input
                  type="email"
                  name="lastActivity"
                  disabled
                  value={row?.connection_response_status ? row?.connection_response_status : 'N/A'}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '5px',
                    border: '1px solid #D8D8D8',
                    backgroundColor: '#F9F9F9',
                  }}
                />
              </td>

              <td style={{ border: '1px solid #D8D8D8', padding: '10px', textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={row.isSubmitted}
                  style={{
                    backgroundColor: row.isSubmitted ? '#B0B0B0' : '#007BFF',
                  }}
                  onClick={() => handleSendRequest(row.profile_link)}
                >
                  {'Send Connection Request'}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LIConnectionSending;
