// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig1 = {
  apiKey: "AIzaSyDdr5BAwcLjepaej1v6TNmrTJu7ZUrDeNg",
  authDomain: "ultimate-whatsapp-d7278.firebaseapp.com",
  databaseURL: "https://ultimate-whatsapp-d7278-default-rtdb.firebaseio.com",
  projectId: "ultimate-whatsapp-d7278",
  storageBucket: "ultimate-whatsapp-d7278.firebasestorage.app",
  messagingSenderId: "876576742826",
  appId: "1:876576742826:web:83947eb6f520af44cedfd3",
  measurementId: "G-DH62HGQ638"
};

const firebaseConfig2 = {
  apiKey: 'AIzaSyDdr5BAwcLjepaej1v6TNmrTJu7ZUrDeNg',
  authDomain: 'ultimate-whatsapp-d7278.firebaseapp.com',
  databaseURL: 'https://ultimate-sms-d7278.firebaseio.com',
  projectId: 'ultimate-whatsapp-d7278',
  storageBucket: 'ultimate-whatsapp-d7278.firebasestorage.app',
  messagingSenderId: '876576742826',
  appId: '1:876576742826:web:13da4c6338c15508cedfd3',
  measurementId: 'G-W5072N4WNG',
};

// Initialize Firebase
const app1 = initializeApp(firebaseConfig1);

const wpauth = getAuth(app1);
// Initialize the second app (with a unique name)
const app2 = initializeApp(firebaseConfig2, 'smsApp');

const smsauth = getAuth(app2);
// Initialize Realtime Database and get a reference to the service
// Get references to the databases
const wpdb = getDatabase(app1);
const smsdb = getDatabase(app2);

export { wpdb, smsdb  , wpauth , smsauth};
