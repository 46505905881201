import React, { useCallback, useEffect, useState } from 'react';
import { RolePipelinesTable } from './components';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import { Box, Button, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

function RecruiterDashboard(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [preRoles, setPreRoles] = useState([]);
  const [prePage, setPrePage] = useState(0);
  const [preLimit, setPreLimit] = useState(10);
  const [preCount, setPreCount] = useState(0);
  const [directRoles, setDirectRoles] = useState([]);
  const [directPage, setDirectPage] = useState(0);
  const [directLimit, setDirectLimit] = useState(10);
  const [directCount, setDirectCount] = useState(0);
  const [isPreRolesLoading, setIsPreRolesLoading] = useState(false);
  const [isDirectRolesLoading, setIsDirectRolesLoading] = useState(false);
  const getPrequalificationRoles = useCallback(() => {
    setIsPreRolesLoading(true)
    try {
      Get(
        {
          page: prePage,
          limit: preLimit,
        },
        API_URLS.getPreQualificationRoles,
        (resp) => {
          setPreRoles(resp?.data?.data);
          setPreCount(resp?.data?.pagination?.totalRoles);
          setIsPreRolesLoading(false)
        },
        (error) => {
          console.log(error);
          setIsPreRolesLoading(false)
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsPreRolesLoading(false)
    }
  }, [prePage, preLimit]);

  useEffect(() => {
    getPrequalificationRoles();
  }, [getPrequalificationRoles]);

  const get360Roles = useCallback(() => {
    setIsDirectRolesLoading(true)
    try {
      Get(
        {
          page: directPage,
          limit: directLimit,
        },
        API_URLS.get360RolesWithPagination,
        (resp) => {
          setDirectRoles(resp?.data?.data);
          setDirectCount(resp?.data?.pagination?.totalRoles);
          setIsDirectRolesLoading(false)
        },
        (error) => {
          console.log(error);
          setIsDirectRolesLoading(false)
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
         setIsDirectRolesLoading(false);
    }
  }, [directPage, directLimit]);

  useEffect(() => {
    get360Roles();
  }, [get360Roles]);
  return (
    <div style={{ width: '97%', margin: 'auto' }}>
      <p style={{ fontSize: '18px', color: '#A0A9B4' }}>Welcome back, John!</p>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontSize: '36px', fontWeight: 'bold', marginTop: '10px' }}>Dashboard</p>
        <Button
          size="large"
          sx={{
            backgroundColor: '#0ec4fe',
            color: 'white',
            borderRadius: 0,
            marginRight: '10px',
            padding: '15px',
            '&:hover': {
              borderRadius: 0,
              color: 'white !important',
              backgroundColor: '#0ec4fe !important',
            },
          }}
          onClick={() => {
            navigate('/recruiter/myReports');
          }}
        >
          <Icon icon="carbon:analytics" />
          &nbsp; &nbsp; My Reports
        </Button>
      </Box>
      <br />
      <RolePipelinesTable
        title="Pre-Qualification"
        roles={preRoles}
        onPageChange={(page) => {
          setPrePage(page);
        }}
        onRowsPerPageChange={(limit) => {
          setPreLimit(limit);
        }}
        totalCount={preCount}
        isLoading={isPreRolesLoading}
      />
      <br />
      <RolePipelinesTable
        title="360 Direct"
        roles={directRoles}
        onPageChange={(page) => {
          setDirectPage(page);
        }}
        onRowsPerPageChange={(limit) => {
          setDirectLimit(limit);
        }}
        totalCount={directCount}
        isLoading={isDirectRolesLoading}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: '400px' }}>
            <p style={{ fontSize: '18px', margin: '4px' }}>My Unattended Calls</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #CAC5C5' }}>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Candidate Name
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Candidate Role
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Call Time
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Call Type
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* You can add rows here as needed */}
                {[...Array(4)].map((_, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #CAC5C5', marginTop: '10px' }}>
                    <td style={{ padding: '20px 10px', color: '#3CD0FE', fontWeight: 'normal' }}>Miss xyz</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>System Analyst</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>
                      15 May 2024 8:30 am
                    </td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>WhatsApp</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: '400px' }}>
            <p style={{ fontSize: '18px', margin: '4px' }}>Up Coming Interviews</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #CAC5C5' }}>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Interview Type
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>From</th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>To</th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Interview Status
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Candidate Name
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Candidate Role
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* You can add rows here as needed */}
                {[...Array(4)].map((_, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #CAC5C5', marginTop: '10px' }}>
                    <td style={{ padding: '20px 10px', color: '#3CD0FE', fontWeight: 'normal' }}>Online Interview</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Miss xyz</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Miss xyz</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Pending</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Jane</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Designer</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: '400px' }}>
            <p style={{ fontSize: '18px', margin: '4px' }}>Completed Interviews</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '5px' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #CAC5C5' }}>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Interview Type
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>From</th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>To</th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Interview Status
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Candidate Name
                  </th>
                  <th style={{ padding: '10px', textAlign: 'left', color: '#838383', fontWeight: 'normal' }}>
                    Candidate Role
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* You can add rows here as needed */}
                {[...Array(4)].map((_, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #CAC5C5', marginTop: '10px' }}>
                    <td style={{ padding: '20px 10px', color: '#3CD0FE', fontWeight: 'normal' }}>Online Interview</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Miss xyz</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Miss xyz</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Pending</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Jane</td>
                    <td style={{ padding: '20px 10px', color: '#838383', fontWeight: 'normal' }}>Designer</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ backgroundColor: 'white', padding: '20px', borderRadius: 4, height: '400px' }}>
            <p style={{ fontSize: '18px', margin: '4px' }}>My Actions</p>
            <hr style={{ width: '100%', color: '#CAC5C5', marginTop: '10px' }} />
            <p style={{ fontSize: '16px', color: '#838383', marginTop: '10px' }}>
              You have no pending actions. Keep up the good work!
            </p>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default RecruiterDashboard;
