import { ArrowBack , Refresh} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CandidateWorkingTable } from './components';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';

// const ws = new WebSocket("ws://localhost:8081");

function CandidateWorking(props) {

  // const updateEmailResponse =(response , receivedAt , email)=>{
  //   try{
  //     Post(
  //       {
  //         candidate_email: email,
  //         response_content: response,
  //         received_at: receivedAt,
  //       },
  //       API_URLS.updateEmailResponse,
  //       (resp) => {
  //         console.log('Email response updated:', resp);

  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  //   }
  //   catch(error){
  //     // enqueueSnackbar('Something went wrong', { variant: 'error' });
  //     console.log(error);
  //   }
  // }
  // ws.onmessage = (event) => {
  //   const data = JSON.parse(event.data);
  //   console.log('New email reply detected:', data);
  //   updateEmailResponse(data.reply_excerpt, data.received_at, data.candidate_email);
  // };

  // ws.onclose = () => {
  //   console.log('Disconnected from WebSocket server');
  // };

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userId = localStorage.getItem('userId');
  const { roleId } = useParams();
  const [roleCandidates, setRoleCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [canStartRole, setCanStartRole] = useState(true);
  const [limit, setLimit] = useState(10);
  const [roleStatus, setRoleStatus] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [ongoingTask, setOngoingTask] = useState();
  const [elapsedTime, setElapsedTime] = useState(); // New state for counter
  const [timerInterval, setTimerInterval] = useState(null); // Track the interval
  // const [taskStartTime, setTaskStartTime] = useState();
  const getCandidateByRoleId = useCallback(() => {
    setLoading(true);
    try {
      Get(
        {
          roleId: roleId,
          page: page,
          limit: limit,
        },
        API_URLS.getAcceptedCandidatesByRoleId,
        (resp) => {
          setRoleCandidates((prev) => (JSON.stringify(prev) !== JSON.stringify(resp?.data) ? resp?.data : prev)); // Update only if data changes
          setTotalCount(resp?.data?.pagination?.totalCandidates || 0);
          setRoleStatus(resp?.data?.roleStatus || []);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId]);

  useEffect(() => {
    getCandidateByRoleId();
  }, [getCandidateByRoleId]);

  const formatElapsedTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const startCounter = (startTime) => {
    const start = new Date(startTime).getTime();
    const interval = setInterval(() => {
      const now = Date.now();
      const diff = now - start;
      setElapsedTime(formatElapsedTime(diff));
    }, 1000);

    setTimerInterval(interval);
  };

  const stopCounter = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  const getRoleStatus = useCallback(
    (roleStatus) => {
      if (roleStatus?.length === 0) {
        setCanStartRole(true);
        stopCounter();
        return;
      } else {
        let isInProgress = false;
        let taskDetails = null;

        roleStatus?.forEach((status) => {
          if (status?.status === 'In-Progress' && status?.user_id == userId) {
            isInProgress = true;
            taskDetails = status;
          }
        });

        if (isInProgress && taskDetails) {
          setCanStartRole(false);
          setOngoingTask(taskDetails.sub_task);

          const readableTime = new Date(taskDetails.start_time).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
          });
          // setTaskStartTime(readableTime);

          // Start the counter
          startCounter(taskDetails.start_time);
        } else {
          setCanStartRole(true);
          setOngoingTask(null);
          // setTaskStartTime(null);
          setElapsedTime(null);
          stopCounter(); // Stop counter when no task is in progress
        }
      }
    },
    [userId, timerInterval]
  );

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
  }
  useEffect(() => {
    if (roleStatus) {
      getRoleStatus(roleStatus);
    }
  }, [roleStatus]); // Avoid redundant dependency like `getRoleStatus` or `userId`.

  const debouncedPage = useDebounce(page, 300);
  const debouncedLimit = useDebounce(limit, 300);

  useEffect(() => {
    getCandidateByRoleId();
  }, [debouncedPage, debouncedLimit, getCandidateByRoleId]);
  return (
    <div style={{ width: '97%', margin: 'auto', height: '100%' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mt={1} justifyContent="space-between">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>
          Candidates for {roleCandidates?.roleTitle}
        </p>
        <Box display="flex" alignItems="center">
          {/* <Box
            sx={{
              backgroundColor: '#FFC9C9',
              color: 'red',
              padding: '15px',
              display: 'flex',
              alignItems: 'center',
              marginRight: '5px',
              borderRadius: '10px',
              marginTop: '15px',
            }}
          >
            <Typography
              variant="subtitle"
              sx={{
                color: 'red',
                fontSize: '14px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            >
              {!canStartRole && ongoingTask && elapsedTime ? `${ongoingTask}, ${elapsedTime}` : 'Task not defined'}
            </Typography>
          </Box> */}
          <Button
            sx={{
              background: '#00C1FE',
              color: '#fff',
              width: '150px',
              border: '1px solid #00C1FE',
              '&:hover': {
                background: '#00C1FE',
                color: '#fff',
              },
              borderRadius: '5px',
              padding: '10px',
              marginTop: '15px',
            }}
            onClick={() => {
              navigate(`/recruiter/sendLiConnection/${roleId}`);
            }}
          >
            LI Connections
          </Button>
        </Box>
      </Box>
<br/>
  <Button
        sx={{
          background: '#00C1FE',
          color: '#fff',
          width: '110px',
          height: '40px',
          border: '1px solid #00C1FE',
          '&:hover': {
            background: '#00C1FE',
            color: 'white',
          },
          borderRadius: '5px',
          padding: '5px',
        }}
        onClick={() => {
          getCandidateByRoleId();
        }}
      >
        <Refresh />
      </Button>
      <br/>
      <CandidateWorkingTable
        candidates={roleCandidates}
        onPageChange={(newPage) => {
          if (newPage !== page) setPage(newPage);
        }}
        onRowsPerPageChange={(newLimit) => {
          if (newLimit !== limit) setLimit(newLimit);
        }}
        roleId={roleId}
        loading={loading}
        totalCount={totalCount}
        onReload={() => getCandidateByRoleId()}
      />
    </div>
  );
}

export default CandidateWorking;
