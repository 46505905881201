import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { ReactMultiDatePicker } from 'src/sections/acm/components';

function RecruiterReports(props) {
  const navigate = useNavigate();
  const [formattedDates, setFormattedDates] = useState([null, null]);
  const userId = localStorage.getItem('userId');
  const [reportsData, setReportsData] = useState([]);

  const getReports = () => {
    try {
      Get(
        {
          recruiterId: userId,
          fromDate: formattedDates[0],
          toDate: formattedDates[1],
        },
        API_URLS.getRecruiterRolesByRecruiterId,
        (response) => {
          console.log('Reports:', response);
          setReportsData(response?.data?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReports();
  }, [formattedDates]);

  const data = [
    {
      id: 1,
      acm: 'ACM',
      role: 'Role',
      services: 'Services',
      roleNo: 'Role No',
      status: 'Status',
      resourcer: 'Resourcer',
      startTime: 'Start time',
      endTime: 'End time',
      duration: 'Duration',
      primaryChannel: 'Primary Channel',
      secondaryTask: 'Secondary task',
    },
  ];

  const formatTimeAndDuration = (startTime, endTime) => {
    if (!startTime || !endTime) {
        console.log('No start or end time provided');
      return { readableStartTime: null, duration: null };
    }

    try {
      // Convert ISO string to Date object
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      // Format start time to "hh:mm AM/PM"
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const readableStartTime = startDate.toLocaleTimeString('en-US', options);
      const readableEndTime = endDate.toLocaleTimeString('en-US', options);

      // Calculate duration
      const durationMs = endDate - startDate;
      if (durationMs < 0) return { readableStartTime, duration: null };

      const durationMinutes = Math.floor(durationMs / (1000 * 60));
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      const duration = `${hours} hours ${minutes} minutes`;
      return { readableStartTime, duration, readableEndTime };
    } catch (error) {
      console.error('Error formatting time or calculating duration:', error);
      return { readableStartTime: null, duration: null, readableEndTime: null };
    }
  };

  return (
    <div style={{ width: '97%', margin: 'auto', height: '100%' }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ArrowBack
          fontSize="small"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: '#00C1FE',
            cursor: 'pointer',
          }}
        />
        &nbsp;&nbsp;{' '}
        <Typography
          variant="subtitle2"
          sx={{ textTransform: 'none', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mt={1} justifyContent="space-between">
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>My Reports</p>
        <Box mr={3}>
          <ReactMultiDatePicker
            onChange={(dateRange) => {
              setFormattedDates(dateRange);
            }}
            style={{
              width: '240px',
              height: '50px',
              paddingRight: '5px',
              marginRight: '5px',
              border: '2px solid #00C1FE',
              outline: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '10px',
              boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
              backgroundColor: '#ECF6FE',
              color: '#00C1FE',
              boxSizing: 'border-box',
              paddingLeft: '10px',
            }}
          />
        </Box>
      </Box>
      <br />
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ACM</TableCell>
                <TableCell>Client No</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Role No</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Start time</TableCell>
                <TableCell>End time</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Primary Channel</TableCell>
                <TableCell>Secondary task</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item) => {
                  const { readableStartTime, duration, readableEndTime } = formatTimeAndDuration(
                    item.start_time,
                    item.end_time
                  );
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item?.recruiter_roles_workings_role?.role_roles_working[0]?.acm_name}</TableCell>
                      <TableCell>{item?.recruiter_roles_workings_role?.role_roles_working[0]?.client_number}</TableCell>
                      <TableCell>{item?.recruiter_roles_workings_role?.title}</TableCell>
                      <TableCell>{item?.recruiter_roles_workings_role?.service?.service_name}</TableCell>
                      <TableCell>{item?.recruiter_roles_workings_role?.role_number}</TableCell>
                      <TableCell>{item?.status}</TableCell>
                      <TableCell>{readableStartTime ? readableStartTime : 'N/A'}</TableCell>
                      <TableCell>{readableEndTime ? readableEndTime : 'N/A'}</TableCell>
                      <TableCell>{duration ? duration : 'N/A'}</TableCell>
                      <TableCell>{item?.primary_task}</TableCell>
                      <TableCell>{item?.sub_task}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default RecruiterReports;
