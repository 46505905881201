import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Checkbox,
  IconButton,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddCandidateEmailDialog, CallInterface, CandidateFeedbackDialog, SMSInterface, UpdateLogResponse } from '.';
import { useNavigate } from 'react-router-dom';
import { Chat } from 'src/components/whatsapp/mobile';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import moment from 'moment';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab';
import { Add } from '@mui/icons-material';

const statusBgColors = {
  'Partially Interested': '#D7FFD6',
  'Fully Interested': '#D7FFD6',
  'Not Interested': '#FFD2D2',
  WFR: '#C7DEFF',
  Irrelevant: '#E4CFFF',
  NCR: '#FFEBB1',
  'No Response': '#FFEBB1',
};

const statusTextColors = {
  'Partially Interested': '#1FAF38',
  'Fully Interested': '#1FAF38',
  'Not Interested': '#FF2D2D',
  WFR: '#3F8CFF',
  Irrelevant: '#9747FF',
  NCR: '#FFC727',
  'No Response': '#F5B600',
};

function CandidateWorkingTable({
  candidates,
  onPageChange,
  onRowsPerPageChange,
  loading,
  totalCount,
  roleId,
  onReload,
}) {
  console.log('candiddsadsates', candidates);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [selected, setSelected] = useState([]); // State for selected rows
  const [openCandidateFeedbackDialog, setOpenCandidateFeedbackDialog] = useState(false);
  const [openWhatsappDialog, setOpenWhatsappDialog] = useState(false);
  const [openSmsDialog, setOpenSmsDialog] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(false);
  const userId = localStorage.getItem('userId');
  const [sequence, setSequence] = useState();
  const [selectedChannel, setSelectedChannel] = useState();
  const [openUpdateResDialog, setOpenUpdateResDialog] = useState(false);
  const statusOptions = ['Interested', 'Not Interested', 'Irrelevant', 'WFR', 'NCR'];
  const { enqueueSnackbar } = useSnackbar();

  const [senderNumber, setSenderNumber] = useState('');
  const [candidateNumber, setCandidateNumber] = useState();
  const [openCallDialog, setOpenCallDialog] = useState(false);
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [areMorethanTwoEmails, setAreMorethanTwoEmails] = useState(true);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle checkbox toggle
  const handleSelect = (id) => {
    setSelected((prev) => (prev.includes(id) ? prev.filter((candidateId) => candidateId !== id) : [...prev, id]));
  };
  const getSequence = useCallback(() => {
    try {
      Get(
        {
          roleId: roleId,
          userId: userId,
        },
        API_URLS.findSequenceByRoleId,
        (resp) => {
          console.log('response', resp);
          setSequence(resp?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [roleId, userId]);

  useEffect(() => {
    getSequence();
  }, [getSequence]);

  const addCommunicationLogToDB = (message) => {
    console.log('mfdsfessage', message);
    const sender = message?.message?.from;
    const messageContent =
      message?.message?.body && message.message.body.trim() !== '' ? message.message.body : 'media';
    try {
      Post(
        {
          channel: selectedChannel,
          message: messageContent,
          sequenceId: sequence?.id,
          roleId: roleId,
          candidateId: selectedCandidate?.source === 'Candidates' ? selectedCandidate?.id : null,
          li_id: selectedCandidate?.source === 'LIProfiles' ? selectedCandidate?.id : null,
          userId: userId,
          whatsapp_sender: sender,
        },
        API_URLS.addCommunicationLog,
        (resp) => {
          console.log('response', resp);
          onReload();
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const updateLogCommunicationWhatsapp = (response) => {
    try {
      Post(
        {
          candidateId: selectedCandidate?.id,
          c_type: selectedCandidate?.source, // Add this field
          roleId: parseInt(roleId),
          sequenceId: sequence?.id,
          channel: selectedChannel,
          response: response,
          response_content: 'response content',
        },
        API_URLS.updateRecentCommunicationLog,
        (resp) => {
          console.log('response', resp);
          setOpenUpdateResDialog(false);
          enqueueSnackbar('Response updated successfully', { variant: 'success' });
          onReload();
          setSelectedChannel(null);
        },
        (error) => {
          console.log(error);
          enqueueSnackbar(error?.response?.data?.message || 'Failed to update response', { variant: 'error' });
          setOpenUpdateResDialog(false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#7FE0FE';
      case 'Accepted':
        return '#b3ffb3';
      case 'Rejected':
        return '#FFC9C9';
      default:
        return 'black';
    }
  };
  const getColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#00b0e6';
      case 'Accepted':
        return 'green';
      case 'Rejected':
        return 'red';
      default:
        return 'black';
    }
  };

  const formatCandidateNumber = (number) => {
    if (!number) return null;
    // Remove spaces from the number
    const cleanedNumber = number?.replace(/\s/g, '');

    // Ensure the number starts with a '+'
    const formattedNumber = cleanedNumber?.startsWith('+') ? cleanedNumber : `+${cleanedNumber}`;

    // Determine the country code
    if (formattedNumber?.startsWith('+1')) {
      return { formattedNumber, countryCode: 'US' };
    } else if (formattedNumber?.startsWith('+44')) {
      return { formattedNumber, countryCode: 'UK' };
    } else {
      return { formattedNumber, countryCode: 'Other' };
    }
  };

  // const pipelineDataList = useMemo(() => {
  //   return candidates?.data?.map((row) => {
  //     return row?.source === 'Candidates'
  //       ? row?.candidate_communication_logs
  //       : row?.lis_communication_logs;
  //   });
  // }, []);

  // console.log("dfjshfjdfdsf", selectedCandidate);
  return (
    <div style={{ marginTop: '1rem' }}>
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, borderRadius: 4, backgroundColor: 'white' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableCell style={{ textAlign: 'left' }}></TableCell>
                <TableCell style={{ textAlign: 'left' }}>Candidate Name</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Email</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Number</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Activities</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Resourcer</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Status</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Box>
                    <Autocomplete
                      id="status"
                      options={statusOptions}
                      size="small"
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {}}
                      disableClearable // Optional: Prevents the clearable option
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Status"
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              border: 'none', // Remove border
                              outline: 'none', // Remove outline
                              '& fieldset': {
                                display: 'none', // Hide the fieldset (the default border)
                              },
                            },
                            '& .MuiInputBase-input': {
                              padding: '6px 12px', // Adjust padding as needed
                              color: 'black',
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true, // Disable the underline
                          }}
                        />
                      )}
                      // Optionally customize the popup of the dropdown to be hidden or styled
                      // PopperComponent={(props) => <div {...props} style={{ display: 'none' }} />} // Hides the dropdown
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Feedback</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                Array.from(new Array(rowsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Skeleton variant="reactangular" width={24} height={24} />
                        <Skeleton variant="rectangular" width={16} height={16} style={{ marginLeft: '8px' }} />
                        <Skeleton variant="rectangular" width={16} height={16} style={{ marginLeft: '8px' }} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <Skeleton variant="text" width="60%" />{' '}
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="30%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="50%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>{' '}
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width="40%" />
                    </TableCell>
                  </TableRow>
                ))
              ) : candidates?.data?.length > 0 ? (
                <>
                  {candidates?.data?.map((row, index) => {
                    const responseStatus = (() => {
                      if (row?.source === 'Candidates') {
                        // Check candidate_communication_logs
                        return row?.candidate_communication_logs?.length > 0
                          ? [...row?.candidate_communication_logs].sort(
                              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                            )[0].response_status
                          : '';
                      } else {
                        // Check lis_communication_logs
                        return row?.lis_communication_logs?.length > 0
                          ? [...row.lis_communication_logs].sort(
                              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                            )[0].response_status
                          : '';
                      }
                    })();
                    const outreachEmailLog = row?.candidate_communication_logs?.find(
                      (log) => log?.channel === 'Outreach Email'
                    );
                    return (
                      <TableRow key={index} selected={selected?.includes(row?.candidateId)}>
                        <TableCell>
                          <Box display="flex" alignItems="center" justifyContent="space-around">
                            {/* Checkbox for selection */}
                            <Checkbox
                              checked={selected?.includes(row?.full_name)}
                              onChange={() => handleSelect(row?.full_name)}
                              inputProps={{ 'aria-label': 'select row' }}
                              sx={{
                                color: '#808080',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {/* Avatar with the first character */}
                            <Avatar
                              style={{
                                marginRight: '8px', // Adds space between avatar and name
                                backgroundColor: '#4CD4FF',
                                color: '#fff',
                                width: '30px',
                                height: '30px',
                                fontSize: '16px',
                                textAlign: 'center',
                              }}
                              src={row?.profile_image ? row?.profile_image : row?.first_name?.charAt(0)}
                            >
                              {row?.profile_image ? row?.profile_image : row?.first_name?.charAt(0)}
                            </Avatar>

                            {/* Candidate name */}
                            <p
                              style={{
                                // color: '#4CD4FF',
                                fontWeight: 'bold',
                                margin: 0,
                              }}
                              // onClick={() => navigate(`/recruiter/candidateWorkingDetails/1`)}
                            >
                              {row?.full_name}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            {row?.personal_email ? (
                              row?.candidate_contact_info?.length > 0 ? (
                                <>
                                  <span style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                    {row?.personal_email}
                                  </span>
                                  <span
                                    style={{
                                      backgroundColor: '#3F8CFF',
                                      color: 'white',
                                      fontSize: '12px',
                                      marginLeft: '4px',
                                      borderRadius: '50%',
                                      height: '16px',
                                      width: '16px',
                                      display: 'inline-flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setOpenAddEmailDialog(true);
                                      setAreMorethanTwoEmails(true);
                                      setSelectedCandidate(row);
                                    }}
                                  >
                                    {row?.candidate_contact_info?.length + 1}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span>{row?.personal_email}</span>
                                  <Tooltip title="Add another email" arrow>
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setOpenAddEmailDialog(true);
                                        setSelectedCandidate(row);
                                      }}
                                      style={{ marginLeft: '5px' }}
                                    >
                                      <Add fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )
                            ) : null}
                          </Box>
                        </TableCell>

                        <TableCell>
                          <p>{row?.personal_phone ? row?.personal_phone : 'N/A'}</p>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <CommunicationChannelPipeline pipeline={row?.candidate_communication_logs} />
                        </TableCell>
                        <TableCell>
                          <p>{row?.cv_adder ? `${row?.cv_adder?.first_name} ${row?.cv_adder?.last_name}` : 'N/A'}</p>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              backgroundColor: getStatusBackgroundColor(row?.candidate_status),
                              color: getColor(row?.candidate_status),
                              padding: '6px',
                              width: '120px',
                              margin: '5px',
                              fontWeight: 'bold',
                              textAlign: 'center',
                              borderRadius: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {row?.candidate_status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <p
                            style={{
                              backgroundColor: statusBgColors[responseStatus],
                              color: statusTextColors[responseStatus],
                              padding: '5px 10px',
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            {responseStatus ? responseStatus : 'N/A'}
                          </p>
                        </TableCell>

                        <TableCell>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Icon
                              icon="ic:outline-email"
                              style={{ color: '#00C1FE', fontSize: '24px', margin: '2px', cursor: 'pointer' }}
                              onClick={() => {
                                setOpenUpdateResDialog(true);
                                setSelectedCandidate(row);
                                setSelectedChannel('Outreach Email');
                              }}
                            />
                            {/* {outreachEmailLog &&
                                outreachEmailLog?.response_status === 'WFR' &&
                                outreachEmailLog?.is_response_received === false ? (
                                  <Icon
                                    icon="ic:outline-email"
                                    style={{ color: '#00C1FE', fontSize: '24px', margin: '2px', cursor: 'pointer' }}
                                    onClick={() => {
                                      setOpenUpdateResDialog(true);
                                      setSelectedCandidate(row);
                                      setSelectedChannel('Outreach Email');
                                    }}
                                  />
                                ) : (
                                  <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                    variant="dot"
                                    color="error"
                                  >
                                    <Icon
                                      icon="ic:outline-email"
                                      style={{ color: '#00C1FE', fontSize: '24px', margin: '2px', cursor: 'pointer' }}
                                      onClick={() => {
                                        setOpenUpdateResDialog(true);
                                        setSelectedCandidate(row);
                                        setSelectedChannel('Outreach Email');
                                      }}
                                    />
                                  </Badge>
                                )} */}

                            <Icon
                              icon="iconoir:message-text-solid"
                              style={{ fontSize: '24px', color: '#5C61F2', margin: '2px', cursor: 'pointer' }}
                              onClick={() => {
                                setOpenSmsDialog(true);
                                setSelectedCandidate(row);
                                setSelectedChannel('SMS');
                                const number = formatCandidateNumber(row?.personal_phone);
                                if (number === undefined || number === null || number.formattedNumber === '') {
                                  enqueueSnackbar('Please add a number for this candidate to send messages', {
                                    variant: 'warning',
                                  });
                                  return;
                                } else {
                                  setCandidateNumber(number?.formattedNumber);
                                  const log = row?.candidate_communication_logs?.find((log) => log?.channel === 'SMS');
                                  if (number?.countryCode === 'US' && !log) {
                                    setSenderNumber('whatsapp:+15078586181');
                                  } else if (number?.countryCode === 'UK' && !log) {
                                    setSenderNumber('whatsapp:+447400270472');
                                  } else if (log) {
                                    setSenderNumber(log?.whatsapp_sender);
                                  } else {
                                    setSenderNumber(null);
                                  }
                                }
                              }}
                            />
                            <Icon
                              icon="logos:whatsapp-icon"
                              style={{ fontSize: '24px', margin: '2px', cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedCandidate(row);
                                setSelectedChannel('WhatsApp Message');
                                const number = formatCandidateNumber(row?.personal_phone);
                                console.log('number', number);
                                // Check if number is undefined, null, or an empty string
                                if (number === undefined || number === null || number.formattedNumber === '') {
                                  enqueueSnackbar('Please add a number for this candidate to send messages', {
                                    variant: 'warning',
                                  });
                                  return;
                                } else {
                                  setOpenWhatsappDialog(true);
                                  setCandidateNumber(number.formattedNumber);
                                  const log = row?.candidate_communication_logs?.find(
                                    (log) => log?.channel === 'WhatsApp Message'
                                  );

                                  if (number.countryCode === 'US' && !log) {
                                    setSenderNumber('whatsapp:+15078586181');
                                  } else if (number.countryCode === 'UK' && !log) {
                                    setSenderNumber('whatsapp:+447400270472');
                                  } else if (log) {
                                    setSenderNumber(log?.whatsapp_sender);
                                  } else {
                                    setSenderNumber(null);
                                  }
                                }
                              }}
                            />
                            <Icon
                              icon="fluent:call-add-20-filled"
                              style={{ fontSize: '24px', color: '#00C1FE', margin: '2px', cursor: 'pointer' }}
                              onClick={() => {
                                setOpenUpdateResDialog(true);
                                setSelectedCandidate(row);
                                setSelectedChannel('Call');

                                // setOpenCallDialog(true);
                                // setSelectedCandidate(row);
                              }}
                            />
                            <Icon
                              icon="ri:linkedin-fill"
                              style={{ fontSize: '24px', color: '#3F8CFF', margin: '2px', cursor: 'pointer' }}
                              // onClick={()=> navigate(`/recruiter/sendLiConnection/${roleId}`)}
                            />
                            <Icon
                              icon="mdi:linkedin"
                              style={{ fontSize: '24px', color: '#E5B137', margin: '2px', cursor: 'pointer' }}
                            />
                            {/* <Tooltip title="Update Status" arrow>
                                  <Icon
                                    icon="grommet-icons:update"
                                    style={{ fontSize: '20px', color: '#E5B137', margin: '2px', cursor: 'pointer' }}
                                    onClick={() => {
                                      setOpenUpdateResDialog(true);
                                      setSelectedCandidate(row);
                                    }}
                                  />
                                </Tooltip> */}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <p onClick={() => setOpenCandidateFeedbackDialog(true)} style={{ cursor: 'pointer' }}>
                            abc
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display={'flex'} justifyContent="flex-end" alignItems="flex-end">
          Total :{totalCount ? totalCount : 0} Candidates
        </Box>
        {/* <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 50, 100]} // Optional: Different options for rows per page
        /> */}
      </Box>
      {openCandidateFeedbackDialog && (
        <CandidateFeedbackDialog
          open={openCandidateFeedbackDialog}
          onClose={() => setOpenCandidateFeedbackDialog(false)}
        />
      )}
      {openAddEmailDialog && (
        <AddCandidateEmailDialog
          open={openAddEmailDialog}
          onClose={() => setOpenAddEmailDialog(false)}
          selectedCandidate={selectedCandidate}
          onSubmitted={() => onReload()}
          areMorethanTwoEmails={areMorethanTwoEmails}
        />
      )}
      {/* {openWhatsappDialog && (
            <PhoneAuthentication />
      )} */}
      {openWhatsappDialog && (
        // <ChatInterface /
        <Chat
          selectedCandidate={selectedCandidate}
          title={candidates?.roleTitle}
          candidateNumber={candidateNumber}
          sender={senderNumber}
          location={candidates?.location}
          onClose={() => {
            setOpenWhatsappDialog(false);
          }}
          onMessageSent={(message) => {
            setOpenWhatsappDialog(false);
            addCommunicationLogToDB(message);
            setOpenUpdateResDialog(true);
          }}
        />
      )}
      {openSmsDialog && (
        <SMSInterface
          selectedCandidate={selectedCandidate}
          title={candidates?.roleTitle}
          location={candidates?.location}
          candidateNumber={candidateNumber}
          sender={senderNumber}
          onClose={() => setOpenSmsDialog(false)}
          onSMSSent={(message) => {
            setOpenSmsDialog(false);
            addCommunicationLogToDB(message);
            setOpenUpdateResDialog(true);
          }}
        />
      )}
      {openCallDialog && (
        <CallInterface selectedCandidate={selectedCandidate} onClose={() => setOpenCallDialog(false)} />
      )}
      {openUpdateResDialog && (
        <UpdateLogResponse
          open={openUpdateResDialog}
          onClose={() => setOpenUpdateResDialog(false)}
          onSubmit={(response) => updateLogCommunicationWhatsapp(response)}
          selectedChannel={selectedChannel}
          selectedCandidate={selectedCandidate}
          roleId={roleId}
          sequenceId={sequence?.id}
        />
      )}
    </div>
  );
}

export default CandidateWorkingTable;

const CommunicationChannelPipeline = ({ pipeline }) => {
  // console.log('pipeline', pipeline);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null); // Holds the selected channel

  const statusColors = {
    'Partially Interested': '#1FAF38',
    'Fully Interested': '#1FAF38',
    'Not Interested': '#FF2D2D',
    WFR: '#3F8CFF',
    Irrelevant: '#9747FF',
    NCR: '#FFC727',
    'No Response': '#FFC727',
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedChannel(null); // Reset selected channel
  };

  const renderIcon = (type) => {
    if (type?.includes('Email')) {
      return <Icon icon="mdi:envelope" style={{ color: 'white', fontSize: '16px' }} />;
    } else if (type?.includes('WhatsApp')) {
      return <Icon icon="mingcute:whatsapp-fill" style={{ color: 'white', fontSize: '18px' }} />;
    } else if (type?.includes('SMS')) {
      return <Icon icon="material-symbols:sms" style={{ color: 'white', fontSize: '16px' }} />;
    } else if (type?.includes('Call')) {
      return <Icon icon="ic:round-phone" style={{ color: 'white', fontSize: '16px' }} />;
    } else if (type?.includes('LI')) {
      return <Icon icon="ri:linkedin-fill" style={{ color: 'white', fontSize: '18px' }} />;
    } else if (type?.includes('InMail')) {
      return <Icon icon="mdi:linkedin" style={{ color: 'white', fontSize: '18px' }} />;
    }
    return null;
  };

  // Group activities by channel and sort by earliest createdAt timestamp
  const groupedChannels = Object.entries(
    pipeline?.reduce((acc, activity) => {
      const channel = activity.channel;
      if (!acc[channel]) {
        acc[channel] = [];
      }
      acc[channel].push(activity);
      return acc;
    }, {})
  ).sort((a, b) => {
    const firstCreatedA = new Date(a[1][0]?.createdAt); // First activity's createdAt timestamp
    const firstCreatedB = new Date(b[1][0]?.createdAt);
    return firstCreatedA - firstCreatedB; // Ascending order
  });

  const formatDateTime = (dateString) => {
    const momentObject = moment(dateString);
    const time = momentObject.format('h:mm A'); // Time in 12-hour format
    const date = momentObject.format('MMM D, YYYY'); // Full month name, day, year
    return { time, date };
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {groupedChannels?.length > 0 ? (
        <>
          {groupedChannels?.map(([channel, activities], index, array) => {
            const activity = activities[0]; // Use the first activity for details
            const bgColor = statusColors[activity.response_status] || '#ccc'; // Default color

            return (
              <React.Fragment key={channel}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        backgroundColor: bgColor,
                        color: '#fff',
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '18px',
                        cursor: 'pointer',
                      }}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setSelectedChannel(channel); // Set the selected channel
                      }}
                    >
                      <Badge
                        badgeContent={activities.length > 1 ? activities.length : null}
                        color="error"
                        invisible={activities.length <= 1}
                      >
                        {renderIcon(channel)}
                      </Badge>
                    </div>
                  </div>
                  {index !== array.length - 1 && (
                    <Box sx={{ width: 20, height: 2.5, backgroundColor: '#00bfff', marginLeft: '-2px' }} />
                  )}
                </Stack>
                <Popover
                  open={Boolean(anchorEl) && selectedChannel === channel} // Check if selectedChannel matches the current channel
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{ marginTop: '10px' }}
                >
                  <Box sx={{ backgroundColor: '#E2F2FF', padding: '5px' }}>
                    <p style={{ fontSize: '16px', marginLeft: '10px' }}>Details</p>
                  </Box>
                  <Box sx={{ maxHeight: '400px', overflowY: 'auto', width: '400px' }}>
                    {activities?.map((activity) => (
                      <Timeline
                        key={activity.id}
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            paddingLeft: 1.5,
                          },
                        }}
                      >
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                backgroundColor: statusColors[activity?.response_status],
                                borderColor: statusColors[activity?.response_status],
                                borderRadius: '50%',
                                padding: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', // Center icon in dot
                              }}
                            >
                              {renderIcon(activity?.channel)}
                            </TimelineDot>
                            <TimelineConnector sx={{ height: '10px' }} />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Box display="flex" flexDirection="column">
                              <Box display="flex" justifyContent="space-between">
                                <p
                                  style={{
                                    fontSize: '10px',
                                    color: '#00C1FE',
                                  }}
                                >
                                  {activity.channel}
                                </p>
                                <p style={{ fontSize: '10px', marginLeft: '10px' }}>
                                  {activity?.communication_log_user?.first_name}{' '}
                                  {activity?.communication_log_user?.last_name}
                                </p>
                              </Box>
                              <Box display="flex" justifyContent="space-between">
                                <p
                                  style={{
                                    fontSize: '10px',
                                    color: statusColors[activity?.response_status],
                                    backgroundColor: statusBgColors[activity?.response_status],
                                    padding: '2px',
                                  }}
                                >
                                  {activity?.response_status}
                                </p>
                              </Box>
                              <Box>
                                <p style={{ fontSize: '10px' }}>
                                  Sent at: {formatDateTime(activity?.createdAt)?.time} ,{' '}
                                  {formatDateTime(activity?.createdAt)?.date}{' '}
                                </p>
                              </Box>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    ))}
                  </Box>
                </Popover>
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <p style={{ fontSize: '14px', color: 'black' }}>No Activities</p>
      )}
    </div>
  );
};
