import React, { useCallback, useEffect, useState } from 'react';
import { CandidatePipelineTable } from './components';
import { Box } from '@mui/material';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

function CandidatesSection(props) {
  const buttons = [
    {
      buttonText: 'Total',
      value: 100,
      borderTop: '#F688F2',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Database',
      value: 25,
      borderTop: '#88BCF6',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Job Board',
      value: 25,
      borderTop: '#A4B0DB',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'LinkedIn',
      value: 25,
      borderTop: '#FF9999',
      buttonFontSize: '22px',
    },
    {
      buttonText: 'Job Posting',
      value: 25,
      borderTop: '#EBB794',
      buttonFontSize: '22px',
    },
  ];

  const { roleId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [roleCandidates, setRoleCandidates] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const getCandidateByRoleId = useCallback(() => {
    setLoading(true);
    try {
      Get(
        {
          roleId: roleId,
          page: page,
          limit: limit,
        },
        API_URLS.getRoleCandidatesById,
        (resp) => {
          setRoleCandidates(resp?.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [roleId, page, limit]);

  useEffect(() => {
    getCandidateByRoleId();
  }, [getCandidateByRoleId]);

  return (
    <div>
      <Box>
        <p style={{ fontSize: '32px', fontWeight: 'bold', marginTop: '10px' }}>All Candidates</p>
      </Box>
      <CandidatePipelineTable
        buttons={buttons}
        onButtonClick={(value) => {}}
        showHiringPipeline={true}
        onPageChange={(page) => setPage(page)}
        onRowsPerPageChange={(limit) => setLimit(limit)}
        candidates={roleCandidates}
        totalCandidates={roleCandidates?.pagination?.resultCount}
        showButtons={false}
        loading={loading}
        roleId={roleId}
      />
    </div>
  );
}

export default CandidatesSection;
