import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  activeWithinLast,
  candidateLocation,
  currentSalary,
  disciplines,
  hours,
  jobTitles,
  jobTypes,
  profileType,
} from './constants';
import { ExpandMore } from '@mui/icons-material';
import { CandidateCard } from '.';
import { Icon } from '@iconify/react';

function ShowCandidateResults({ onShowDetails, candidates, total }) {

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedActiveWithin, setSelectedActiveWithin] = useState([]);
  const [selectedSalaryRange, setSelectedSalaryRange] = useState(null);

  const [selectedJobTitles, setSelectedJobTitles] = useState([]);
  //array of disciplines
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [selectedProfileType, setSelectedProfileType] = useState(null);
  //state for selectedJobType
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [salaryType, setSalaryType] = useState(null);

  const handleCheckboxChange = (event) => {
    const location = event.target.name;
    setSelectedLocations(
      (prev) =>
        event.target.checked
          ? [...prev, location] // Add to selected locations if checked
          : prev.filter((loc) => loc !== location) // Remove from selected locations if unchecked
    );
  };

  const handleActiveWithinChange = (event) => {
    const location = event.target.name;
    setSelectedActiveWithin((prev) =>
      event.target.checked ? [...prev, location] : prev.filter((loc) => loc !== location)
    );
  };
  // Handle radio button change
  const handleRadioChange = (event) => {
    setSelectedSalaryRange(event.target.value);
  };

  const handleJobTitleChange = (event) => {
    const jobTitle = event.target.name; // Get the name (job title) from event.target
    setSelectedJobTitles(
      (prev) =>
        event.target.checked
          ? [...prev, jobTitle] // Add to selected job titles if checked
          : prev.filter((title) => title !== jobTitle) // Remove if unchecked
    );
  };

  const handleDisciplineChange = (event) => {
    const discipline = event.target.name; // Get the name (job title) from event.target
    setSelectedDisciplines(
      (prev) =>
        event.target.checked
          ? [...prev, discipline] // Add to selected job titles if checked
          : prev.filter((disc) => disc !== discipline) // Remove if unchecked
    );
  };

  const handleProfileTypeChange = (event) => {
    const selectedType = event.target.name;

    // If the selected type is the same as the current one, allow it to be deselected
    if (selectedProfileType === selectedType) {
      setSelectedProfileType(''); // Deselect the current selection
    } else {
      setSelectedProfileType(selectedType); // Otherwise, set the new selection
    }
  };

  const handleJobTypeChange = (event) => {
    const selectedType = event.target.name;

    // If the selected type is the same as the current one, allow it to be deselected
    if (selectedJobType === selectedType) {
      setSelectedJobType(''); // Deselect the current selection
    } else {
      setSelectedJobType(selectedType); // Otherwise, set the new selection
    }
  };

  const handleHourChange = (event) => {
    const selectedType = event.target.name;

    // If the selected type is the same as the current one, allow it to be deselected
    if (selectedHour === selectedType) {
      setSelectedHour(''); // Deselect the current selection
    } else {
      setSelectedHour(selectedType); // Otherwise, set the new selection
    }
  };

  return (
    <div>
      <div style={{ width: '95%', margin: 'auto' }}>
        <Box display="flex" flexDirection="column" sx={{ backgroundColor: '#EEF7FF', height: 'auto' }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" justifyContent="space-around" alignItems="flex-start" width="40%">
              <p style={{ fontSize: '22px' }}>Candidates</p>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>345</p>
              <p style={{ fontSize: '18px', color: '#00C1FE' }}>Save search</p>
              <p style={{ fontSize: '18px', color: '#00C1FE' }}>View saved searches</p>
            </Box>
            <Box display="flex" marginRight="1rem">
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Credits Used: <span style={{ marginLeft: '10px' }}>09</span>
              </p>
            </Box>
          </Box>
          <p style={{ fontSize: '18px', marginTop: '10px', color: 'grey', marginLeft: '1rem' }}>
            Search anything in CV or Profile : Web Designer , Current or desired job titles : N/A , skills: N/A , within
            45 mints of UK search type: Standard, Salary Annual: Unspecified, Hide profiles viewdsince 5 days ago.
          </p>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <p style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '20px' }}>Refine your results</p>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  backgroundColor: 'white',
                  padding: '1rem',
                  marginTop: '1rem',
                  borderRadius: '10px',
                  overflowY: 'scroll',
                }}
              >
                <Accordion
                  defaultExpanded
                  sx={{
                    margin: 0,
                    '&:before': { display: 'none' }, // Remove the top divider
                    boxShadow: 'none', // Remove box-shadow for a cleaner look
                    borderBottom: '1px solid #e0e0e0', // Optional bottom border to separate accordions
                  }}
                >
                  {/* Accordion Heading */}
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      padding: '0 16px', // Adjust padding to control spacing
                      minHeight: '0px', // Remove extra height from the summary
                      '& .MuiAccordionSummary-content': {
                        margin: 0,
                      },
                    }}
                  >
                    <Typography variant="h6">Current Locations</Typography>
                  </AccordionSummary>

                  {/* Accordion Content */}
                  <AccordionDetails sx={{ padding: '8px 16px' }}>
                    <Box display="flex" flexDirection="column" sx={{ gap: '4px' }}>
                      {candidateLocation.map((location) => (
                        <FormControlLabel
                          key={location}
                          control={
                            <Checkbox
                              name={location}
                              checked={selectedLocations.includes(location)} // Check if the location is selected
                              onChange={handleCheckboxChange}
                              sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          }
                          label={location}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {/* <hr style={{ width: '95%', margin: 'auto', color: '#CAC5C5' }} /> */}
                <Accordion
                  defaultExpanded
                  sx={{
                    margin: 0,
                    '&:before': { display: 'none' }, // Remove the top divider
                    boxShadow: 'none', // Remove box-shadow for a cleaner look
                    borderBottom: '1px solid #e0e0e0', // Optional bottom border to separate accordions
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      padding: '0 16px', // Adjust padding to control spacing
                      minHeight: '0px', // Remove extra height from the summary
                      '& .MuiAccordionSummary-content': {
                        margin: 0,
                      },
                    }}
                  >
                    <Typography variant="h6">Active Within Last</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '8px 16px' }}>
                    <Box display="flex" flexDirection="column" sx={{ gap: '4px' }}>
                      {activeWithinLast.map((location) => (
                        <FormControlLabel
                          key={location}
                          control={
                            <Checkbox
                              name={location}
                              checked={selectedActiveWithin.includes(location)} // Check if selected
                              onChange={handleActiveWithinChange}
                              sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          }
                          label={location}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {/* Accordion for Salary Range */}
                <Accordion defaultExpanded sx={{ margin: 0, '&:before': { display: 'none' } }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ padding: 0, minHeight: 'unset', '& .MuiAccordionSummary-content': { margin: 0 } }}
                  >
                    <Typography variant="h6">Current Salary</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box display="flex" flexDirection="column">
                      <ToggleButtonGroup
                        value={salaryType}
                        exclusive
                        onChange={(e, value) => {
                          if (value !== null) {
                            setSalaryType(value);
                          }
                        }}
                        aria-label="company sections"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginLeft: '20px',
                          borderBottom: '1px solid grey',
                          width: '250px',
                          marginBottom: '5px',
                        }}
                      >
                        <ToggleButton
                          value="p.a"
                          aria-label="p.a"
                          style={{
                            border: 'none',
                            background: salaryType === 'p.a' ? '#EBEBEB' : 'white',
                            fontSize: '14px', // Reduced font size
                            fontWeight: 'bold',
                            padding: '6px 12px', // Reduced padding
                          }}
                        >
                          Annual
                        </ToggleButton>
                        <ToggleButton
                          value="p.d"
                          aria-label="p.d"
                          style={{
                            border: 'none',
                            marginLeft: '8px', // Slightly smaller margin
                            background: salaryType === 'p.d' ? '#EBEBEB' : 'white',
                            fontSize: '14px', // Reduced font size
                            fontWeight: 'bold',
                            padding: '6px 12px', // Reduced padding
                          }}
                        >
                          Daily
                        </ToggleButton>
                        <ToggleButton
                          value="p.h"
                          aria-label="p.h"
                          style={{
                            border: 'none',
                            marginLeft: '8px', // Slightly smaller margin
                            background: salaryType === 'p.h' ? '#EBEBEB' : 'white',
                            fontSize: '14px', // Reduced font size
                            fontWeight: 'bold',
                            padding: '6px 12px', // Reduced padding
                          }}
                        >
                          Hourly
                        </ToggleButton>
                      </ToggleButtonGroup>

                      {/* RadioGroup to group radio buttons */}
                      <RadioGroup value={selectedSalaryRange} onChange={handleRadioChange}>
                        {currentSalary.map((range) => (
                          <FormControlLabel
                            key={range}
                            value={range}
                            control={
                              <Radio
                                sx={{
                                  color: 'black',
                                  '&.Mui-checked': {
                                    color: '#00C1FE', // Set radio button color to blue when selected
                                  },
                                }}
                              />
                            }
                            label={range}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion for the current job title */}
                <Accordion defaultExpanded sx={{ marginTop: '-100px' }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Current Job Title</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column">
                      {jobTitles.map((jobTitle) => (
                        <FormControlLabel
                          key={jobTitle}
                          control={
                            <Checkbox
                              name={jobTitle} // Use the job title as the name
                              checked={selectedJobTitles.includes(jobTitle)} // Check if the job title is selected
                              onChange={handleJobTitleChange}
                              sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          }
                          label={jobTitle}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {/* make accordion of disciplines */}
                <Accordion defaultExpanded sx={{ marginTop: '-100px' }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Disciplines</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column">
                      {disciplines.map((discipline) => (
                        <FormControlLabel
                          key={discipline}
                          control={
                            <Checkbox
                              name={discipline} // Use the discipline as the name
                              checked={selectedDisciplines.includes(discipline)} // Check if the discipline is selected
                              onChange={handleDisciplineChange}
                              sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          }
                          label={discipline}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Profile Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column">
                      {profileType.map((type) => (
                        <FormControlLabel
                          key={type}
                          control={
                            <Checkbox
                              name={type}
                              checked={selectedProfileType === type} // Only one can be selected
                              onChange={handleProfileTypeChange}
                              sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          }
                          label={type}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {/*  make accordion of jobTypes like profile type  */}
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Job Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column">
                      {jobTypes.map((type) => (
                        <FormControlLabel
                          key={type}
                          control={
                            <Checkbox
                              name={type}
                              checked={selectedJobType === type} // Only one can be selected
                              onChange={handleJobTypeChange}
                              sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          }
                          label={type}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {/* make the accordion of hours like profile type */}
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">Hours</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column">
                      {hours.map((hour) => (
                        <FormControlLabel
                          key={hour}
                          control={
                            <Checkbox
                              name={hour}
                              checked={selectedHour === hour} // Only one can be selected
                              onChange={handleHourChange}
                              sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                  color: '#00C1FE', // Set checkbox color to blue when checked
                                },
                              }}
                            />
                          }
                          label={hour}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  {/* Add Checkbox before the icon */}
                  <Checkbox
                    sx={{
                      color: 'black',
                      '&.Mui-checked': {
                        color: '#00C1FE', // Set checkbox color to match the theme when checked
                      },
                    }}
                  />
                  {/* Iconify download icon */}
                  <Icon icon="system-uicons:download" style={{ fontSize: '20px', marginRight: '8px' }} />
                  {/* Text for Download Selected CVs */}
                  <p style={{ fontSize: '18px', margin: 0, color: '#00C1FE' }}>Download Selected CVs</p>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  backgroundColor: 'white',
                  padding: '1rem',
                  marginTop: '1rem',
                  borderRadius: '10px',
                  overflowY: 'scroll',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Results found: {total || 0}</p>
                  </Grid>

                  {candidates?.map((candidate, index) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={candidate?.id}>
                      <CandidateCard
                        index={index}
                        candidate={candidate}
                        searchString="manager"
                        onShowDetails={() => {
                          onShowDetails();
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* <Box display="flex" justifyContent="center" marginTop="1rem">
                <p style={{ fontSize: '18px', color: '#00C1FE' }}>Load More</p>
              </Box> */}

              {/* <Box display="flex" justifyContent="center" marginTop="1rem">
                <p style={{ fontSize: '18px', color: '#00C1FE' }}>Save Search</p>
              </Box> */}
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default ShowCandidateResults;
