
// ------------------------------------------- UploadCVs.jsx without conversion progress -------------------------------------------
import React, { useCallback, useState } from 'react';
import { CloudUpload, ContentPaste, Visibility } from '@mui/icons-material';
import { Box, Button, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Post } from 'src/actions/API/apiActions';
import { Python_API_URLs } from 'src/constants/pythonAPIURLs';

const DropZone = styled(Box)(({ theme, isDragging }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: isDragging ? theme.palette.grey[100] : theme.palette.background.paper,
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function UploadCVs({ onFileSelected, jd }) {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [isConvertingDoc, setIsConvertingDoc] = useState(false);
  const [extrationProcessingIndices, setExtractionProcessingIndices] = useState([]);
  const [milesProcessingIndices, setMilesProcessingIndices] = useState([]);
  // set processed files
  const [extractedFiles, setExtractedFiles] = useState([
    {
      index: -1,
      data: null,
    },
  ]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    processFiles(droppedFiles);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    processFiles(selectedFiles);
  };

  //   2. Process Files and Convert DOC files to PDF
  const processFiles = useCallback((newFiles) => {
    const deduplicate = (existingFiles, incomingFiles) => {
      const fileMap = new Map();
      existingFiles.concat(incomingFiles).forEach((file) => {
        fileMap.set(`${file.name}-${file.lastModified}`, file);
      });
      return Array.from(fileMap.values());
    };

    const pdfFiles = newFiles.filter((file) => file.type === 'application/pdf');
    const docxFiles = newFiles.filter(
      (file) => file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
    const docFiles = newFiles.filter((file) => file.type === 'application/msword');

    // Append PDFs and DOCX files
    setFiles((prevFiles) => deduplicate(prevFiles, [...pdfFiles, ...docxFiles]));

    // Convert DOC files to PDF
    if (docFiles.length > 0) {
      setIsConvertingDoc(true);
      convertDocFiles(docFiles, (convertedFiles) => {
        setFiles((prevFiles) => deduplicate(prevFiles, convertedFiles));
        setIsConvertingDoc(false);
      });
    }
  }, []);

  const convertDocFiles = (docFiles, onComplete) => {
    const processQueue = async (queue, convertedFiles = []) => {
      if (queue.length === 0) {
        onComplete(convertedFiles); // Call onComplete when all files are processed
        return;
      }

      const [file, ...rest] = queue;
      const formData = new FormData();
      formData.append('file', file);

      //   Post(
      //     formData,
      //     Python_API_URLs.convert_doc_to_pdf,
      //     (response) => {
      //       // Convert the response (ArrayBuffer or binary data) to a Blob
      //       const pdfBlob = new Blob([response], { type: 'application/pdf' });

      //       // Create a File object from the Blob
      //       const pdfFile = new File([pdfBlob], file.name.replace('.doc', '.pdf'), {
      //         type: 'application/pdf',
      //       });

      //       // Process the converted PDF file
      //       processQueue(rest, [...convertedFiles, pdfFile]);
      //     },
      //     () => {
      //       // Handle failed conversion and continue with the next file
      //       processQueue(rest, convertedFiles);
      //     }
      //   );
      try {
        const response = await fetch(Python_API_URLs.convert_doc_to_pdf, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Failed to convert document: ${response.statusText}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Convert the response (ArrayBuffer or binary data) to a Blob
        const pdfBlob = new Blob([arrayBuffer], { type: 'application/pdf' });

        // Create a File object from the Blob
        const pdfFile = new File([pdfBlob], file.name.replace('.doc', '.pdf'), {
          type: 'application/pdf',
        });

        // Process the converted PDF file
        processQueue(rest, [...convertedFiles, pdfFile]);
      } catch (error) {
        console.error('Error during document conversion:', error);

        // Handle failed conversion and continue with the next file
        processQueue(rest, convertedFiles);
      }
    };

    processQueue(docFiles);
  };

  //   3. Extract Data from Files
  const onExtractAll = () => {
    try {
      files.forEach((file, index) => {
        handleExtractSingleFile(file, index);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //   4. Extract Data from a Single File
  const handleExtractSingleFile = (file, index) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('user_postal_code', jd?.postal_code);
      formData.append('country_code', jd?.country_code);
      formData.append('job_description', jd?.doc_file_template);
      Post(
        formData,
        Python_API_URLs.extractData,
        // Python_API_URLs.CV_Analyzer,
        (resp) => {
          const data = {
            ...resp,
            file: file,
            type: file.type,
            name: file.name,
          };
          setExtractedFiles((prevFiles) => [...prevFiles, { index: index, data: data }]);

          setExtractionProcessingIndices((prevIndices) => prevIndices.filter((i) => i !== index));
        },
        (error) => {
          setExtractedFiles((prevFiles) => [...prevFiles, { index: index, data: { error: error } }]);
          setExtractionProcessingIndices((prevIndices) => prevIndices.filter((i) => i !== index));
          console.log(error);
        }
      );
    } catch (error) {
      setExtractedFiles((prevFiles) => [...prevFiles, { index: index, data: { error: error } }]);
      setExtractionProcessingIndices((prevIndices) => prevIndices.filter((i) => i !== index));
      console.log(error);
    }
  };

  const calculateMiles = (data, index) => {
    try {
      const location = data?.parsed_resume?.address;
      let city = location?.city;
      let street = location?.street;
      let postal_code = location?.postal_code;
      let country = location?.country;

      const destination = `${city || ''} ${street || ''} ${postal_code || ''} ${country || ''}`.trim();

      const formData = new FormData();
      formData.append('start_location', jd?.postal_code);

      // if destination is empty, then take input from user from window prompt and then calculate miles
      if(destination === '') {
        const destination = window.prompt('Please enter the destination address');
        formData.append('destination', destination);
      }else{
        formData.append('destination', destination);
      }
      Post(
        formData,
        Python_API_URLs.get_directions,
        (resp) => {
          setExtractedFiles((prevFiles) => {
            // Create a new copy of the files array
            const updatedFiles = [...prevFiles];
            // Update the specific file immutably
            updatedFiles[index] = {
              ...updatedFiles[index],
              data: {
                ...updatedFiles[index].data,
                distance: resp?.directions,
              },
            };
            return updatedFiles;
          });

          setMilesProcessingIndices((prevIndices) => prevIndices.filter((i) => i !== index));
        },
        (error) => {
          setExtractedFiles((prevFiles) => {
            // Create a new copy of the files array
            const updatedFiles = [...prevFiles];
            // Update the specific file immutably
            updatedFiles[index] = {
              ...updatedFiles[index],
              data: {
                ...updatedFiles[index].data,
                distance: 'Failed to calculate miles',
              },
            };
            return updatedFiles;
          });

          setMilesProcessingIndices((prevIndices) => prevIndices.filter((i) => i !== index));
          console.log(error);
        }
      );
    } catch (error) {
      setExtractedFiles((prevFiles) => {
        // Create a new copy of the files array
        const updatedFiles = [...prevFiles];
        // Update the specific file immutably
        updatedFiles[index] = {
          ...updatedFiles[index],
          data: {
            ...updatedFiles[index].data,
            distance: 'Failed to calculate miles',
          },
        };
        return updatedFiles;
      });

      setMilesProcessingIndices((prevIndices) => prevIndices.filter((i) => i !== index));
    }
  };

  console.log('extractedFiles', extractedFiles);

  return (
    <div>
      {isConvertingDoc ? (
        <Box display="flex" alignItems="center" sx={{ width: '100%', ml: 1 }}>
          <LinearProgress sx={{ width: '100%', marginLeft: '5px', color: '#00C1FE' }} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '2px dashed #00C1FE',
              }}
            >
              <DropZone
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                isDragging={isDragging}
              >
                <Button
                  component="label"
                  startIcon={<CloudUpload />}
                  sx={{
                    border: '2px solid #00C1FE',
                    backgroundColor: '#00C1FE',
                    color: 'white',
                    '&:hover': { backgroundColor: '#00C1FE' },
                  }}
                >
                  Upload files
                  <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
                </Button>
              </DropZone>
            </Box>
          </Grid>
          {files.length > 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  border: '2px dashed #00C1FE',
                  padding: '20px',
                  height: '30vh',
                  overflowY: 'scroll',
                }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1">Files uploaded: {files.length}</Typography>
                  <Button
                    sx={{
                      m: 1,
                      borderRadius: '5px',
                      border: '1px solid #00C1FE',
                      color: '#00C1FE',
                      padding: '5px',
                      width: '110px',
                    }}
                    onClick={() => {
                      // Add all indices to processing indices
                      setExtractionProcessingIndices([...Array(files.length).keys()]);
                      onExtractAll(files)}
                    }
                    size="small"
                  >
                    Extract All
                  </Button>
                </Box>
                {files?.map((file, index) => (
                  <>
                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" alignItems="center" gap={2}>
                        <Typography variant="body1">
                          {index + 1} {(file?.name).trim(0, 20)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {extractedFiles[index]?.data ? extractedFiles[index]?.data?.distance : ''}
                        </Typography>

                        {/* <Typography variant="body2" color="textSecondary">
                          {extractedFiles[index]?.data ? extractedFiles[index]?.data?.rating : 'Not rated'}
                        </Typography> */}
                      </Box>

                      <Box display="flex" alignItems="center">
                        {/* check if this index is included in extrcated files index */}
                        {extractedFiles.some((item) => item.index === index) ? (
                          <>
                            <Button
                              sx={{
                                m: 1,
                                borderRadius: '5px',
                                border: '1px solid #00C1FE',
                                color: '#00C1FE',
                                padding: '5px',
                                width: '110px',
                              }}
                              size="small"
                              onClick={() => {
                                const data = extractedFiles.find((item) => item.index === index);
                                onFileSelected(data.data, index);
                              }}
                            >
                              <Visibility />
                            </Button>
                            <Button
                              sx={{
                                m: 1,
                                borderRadius: '5px',
                                border: '1px solid #00C1FE',
                                color: '#00C1FE',
                              }}
                              disabled={milesProcessingIndices.includes(index)}
                              onClick={() => {
                                setMilesProcessingIndices((prevIndices) => [...prevIndices, index]);
                                const data = extractedFiles.find((item) => item.index === index);
                                calculateMiles(data.data, index);
                              }}
                            >
                              Check Miles
                            </Button>
                          </>
                        ) : (
                          <Button
                            sx={{
                              m: 1,
                              borderRadius: '5px',
                              border: '1px solid #00C1FE',
                              color: '#00C1FE',
                              padding: '5px',
                              width: '110px',
                            }}
                            disabled={extrationProcessingIndices.includes(index)}
                            onClick={() => {
                              setExtractionProcessingIndices((prevIndices) => [...prevIndices, index]);
                              handleExtractSingleFile(file, index);
                            }}
                            size="small"
                          >
                            Extract File
                          </Button>
                        )}
                        <Button
                          sx={{
                            m: 1,
                            borderRadius: '5px',
                            border: '1px solid #00C1FE',
                            color: '#00C1FE',
                            padding: '5px',
                            width: '110px',
                          }}
                          onClick={() => setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))}
                          size="small"
                        >
                          Remove file
                        </Button>
                        <Button
                          sx={{
                            m: 1,
                            borderRadius: '5px',
                            border: '1px solid #00C1FE',
                            color: '#00C1FE',
                            padding: '5px',
                            width: '110px',
                          }}
                          size="small"
                          onClick={() => {
                            console.log('hjdfdssdfsdffsdf', file);
                            const data = {
                              file: file,
                              type: file.type,
                              name: file.name,
                            };
                            onFileSelected(data, index);
                          }}
                        >
                          <Tooltip title="View" placement="top">
                            <ContentPaste />
                          </Tooltip>
                        </Button>
                      </Box>
                    </Box>
                    <hr />
                  </>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

export default UploadCVs;


// ------------------------------------------- UploadCVs.jsx with conversion progress -------------------------------------------
// import React, { useCallback, useState } from 'react';
// import { CloudUpload } from '@mui/icons-material';
// import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
// import { styled } from '@mui/styles';
// import { Python_API_URLs } from 'src/constants/pythonAPIURLs';

// const DropZone = styled(Box)(({ theme, isDragging }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   padding: theme.spacing(2),
//   backgroundColor: isDragging ? theme.palette.grey[100] : theme.palette.background.paper,
//   border: '2px dashed #00C1FE',
//   transition: 'border .24s ease-in-out',
//   cursor: 'pointer',
//   '&:hover': {
//     backgroundColor: theme.palette.grey[100],
//   },
// }));

// const VisuallyHiddenInput = styled('input')({
//   clip: 'rect(0 0 0 0)',
//   clipPath: 'inset(50%)',
//   height: 1,
//   overflow: 'hidden',
//   position: 'absolute',
//   whiteSpace: 'nowrap',
//   width: 1,
// });

// function UploadCVs() {
//   const [isDragging, setIsDragging] = useState(false);
//   const [files, setFiles] = useState([]);
//   const [conversionProgress, setConversionProgress] = useState({});
//   const [overallProgress, setOverallProgress] = useState(0);

//   const processFiles = useCallback((newFiles) => {
//     const deduplicate = (existingFiles, incomingFiles) => {
//       const fileMap = new Map();
//       existingFiles.concat(incomingFiles).forEach((file) => {
//         fileMap.set(`${file.name}-${file.lastModified}`, file);
//       });
//       return Array.from(fileMap.values());
//     };

//     const docFiles = newFiles.filter(
//       (file) => file.type === 'application/msword' ||
//                 file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
//     );

//     if (docFiles.length > 0) {
//       convertDocFiles(docFiles);
//     }

//     setFiles((prevFiles) => deduplicate(prevFiles, newFiles));
//   }, []);

//   const convertDocFiles = (docFiles) => {
//     const totalFiles = docFiles.length;
//     let completedFiles = 0;

//     const updateOverallProgress = () => {
//       completedFiles += 1;
//       setOverallProgress(Math.round((completedFiles / totalFiles) * 100));
//     };

//     docFiles.forEach(async (file, index) => {
//       const formData = new FormData();
//       formData.append('file', file);

//       try {
//         const response = await fetch(Python_API_URLs.convert_doc_to_pdf, {
//           method: 'POST',
//           body: formData,
//         });

//         if (!response.ok) {
//           throw new Error(`Failed to convert document: ${response.statusText}`);
//         }

//         const arrayBuffer = await response.arrayBuffer();
//         const pdfBlob = new Blob([arrayBuffer], { type: 'application/pdf' });
//         const pdfFile = new File([pdfBlob], file.name.replace(/\.(docx|doc)$/i, '.pdf'), {
//           type: 'application/pdf',
//         });

//         setFiles((prevFiles) => [...prevFiles, pdfFile]);
//       } catch (error) {
//         console.error('Error during document conversion:', error);
//       } finally {
//         updateOverallProgress();
//         setConversionProgress((prevProgress) => ({
//           ...prevProgress,
//           [index]: 100,
//         }));
//       }
//     });
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     setIsDragging(false);
//     const droppedFiles = Array.from(event.dataTransfer.files);
//     processFiles(droppedFiles);
//   };

//   const handleFileChange = (event) => {
//     const selectedFiles = Array.from(event.target.files);
//     processFiles(selectedFiles);
//   };

//   return (
//     <div>
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <DropZone
//             onDragOver={(e) => {
//               e.preventDefault();
//               setIsDragging(true);
//             }}
//             onDragLeave={() => setIsDragging(false)}
//             onDrop={handleDrop}
//             isDragging={isDragging}
//           >
//             <Button
//               component="label"
//               startIcon={<CloudUpload />}
//               sx={{
//                 border: '2px solid #00C1FE',
//                 backgroundColor: '#00C1FE',
//                 color: 'white',
//                 '&:hover': { backgroundColor: '#00C1FE' },
//               }}
//             >
//               Upload files
//               <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
//             </Button>
//           </DropZone>
//         </Grid>
//         {files.length > 0 && (
//           <Grid item xs={12}>
//             <Box sx={{ padding: '10px', border: '2px solid #00C1FE', borderRadius: '10px' }}>
//               <Typography variant="subtitle1">Overall Progress: {overallProgress}%</Typography>
//               <LinearProgress variant="determinate" value={overallProgress} />
//               {files.map((file, index) => (
//                 <Box key={index} mt={2}>
//                   <Typography variant="body1">{file.name}</Typography>
//                   <LinearProgress
//                     variant="determinate"
//                     value={conversionProgress[index] || 0}
//                   />
//                 </Box>
//               ))}
//             </Box>
//           </Grid>
//         )}
//       </Grid>
//     </div>
//   );
// }

// export default UploadCVs;
