import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddNewClient, AddNewService, ArrowPipeline } from '../components';
import { Icon } from '@iconify/react';
import MaterialReactTable from 'material-react-table';
import { Get, Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { replace } from 'lodash';
import dayjs from 'dayjs';
import { Edit, SyncTwoTone } from '@mui/icons-material';
import RenewClientSubscription from '../components/RenewClientSubscription';

function Clients() {
  const navigate = useNavigate();
  const [activePipeline, setActivePipeline] = useState(null);
  const [openClientDialog, setOpenClientDialog] = useState(false);
  const [data, setData] = useState([]);
  const [clientStats, setClientStats] = useState([]);
  const [isClientStatsLoaing, setIsClientStatsLoaing] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [status, setStatus] = useState({ status: '' });
  const [openClientRenewDialog, setOpenClientRenewDialog] = useState(false);

  const handleActivate = (title) => {
    setActivePipeline(title); // Set the active pipeline based on title
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'assign_acm',
        header: 'ACM',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <>
              {/* green for Active status, red for Expired status, blue+green gradient for Re-newed */}

              {cell?.row?.original?.status === 'Active' ? (
                <span style={{ color: 'green', fontWeight: 'bold' }}>{cell?.getValue()}</span>
              ) : cell?.row?.original?.status === 'Expired' ? (
                <span style={{ color: 'red', fontWeight: 'bold' }}>{cell?.getValue()}</span>
              ) : (
                <span style={{ color: 'blue', fontWeight: 'bold' }}>{cell?.getValue()}</span>
              )}
            </>
          </Box>
        ),
      },
      {
        accessorKey: 'client_number',
        header: 'Client No.',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell, row }) => (
          <Button
            variant="text"
            onClick={() => {
              navigate(`/acm/clients/client-details/${row.original.client_number}`);
            }}
          >
            {row.original.client_number}
          </Button>
        ),
      },

      {
        accessorKey: 'no_of_roles_working',
        header: 'Roles',
        enableClickToCopy: true,
        minWidth: 170,
        // Cell: ({ cell }) => (
        //   <Box component="span">
        //     {cell?.row?.original?.clientService?.serviceName === 'IT and Consulting' ||
        //     cell?.row?.original?.clientService?.serviceName === 'Lead-Generation' ||
        //     cell?.row?.original?.clientService?.serviceName === 'VA' ? (
        //       <span style={{ fontWeight: 'bold' }}>Not Applicable</span>
        //     ) : (
        //       <>
        //         <span style={{ fontWeight: 'bold' }}>{cell?.row?.original?.creditsUsed}</span> /{' '}
        //         {cell?.row?.original?.noOfCredits}
        //       </>
        //     )}
        //   </Box>
        // ),
      },
      {
        accessorKey: 'service.service_name',
        header: 'Service',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        enableClickToCopy: true,
        minWidth: 170,
        // Cell: ({ cell }) => (
        //   <Box component="span">
        //     <>
        //       <span style={{ fontWeight: 'bold' }}>
        //         {cell?.row?.original?.amount ? cell?.row?.original?.amount : 'Not Added'}
        //       </span>
        //     </>
        //   </Box>
        // ),
      },
      {
        accessorKey: 'vat',
        header: 'VAT',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <>
              <span style={{ fontWeight: 'bold' }}>20%</span>
            </>
          </Box>
        ),
      },
      {
        accessorKey: 'amount',
        header: 'Total Amount',
      },
      {
        accessorKey: 'fullName',
        header: 'Full name',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'client.email',
        header: 'Email',
        enableClickToCopy: true,
        minWidth: 150,
      },
      {
        accessorKey: 'start_date',
        header: 'Start Date',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => {
          // Convert the date string to the desired format
          const formattedDate = format(new Date(cell.getValue()), 'MMM d, yyyy, hh:mm a');
          return (
            <Box component="span">
              <Typography
                variant="body2"
                style={{
                  color: 'black',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  borderRadius: '5px',
                  width: '150px',
                  textAlign: 'center',
                }}
              >
                {formattedDate}
              </Typography>
            </Box>
          );
        },
      },

      // add expires in column w.r.t expiry date
      {
        accessorKey: 'kuchbhi',
        header: 'Expires in',
        Cell: ({ cell }) => {
          const expiryDate = dayjs(cell.row.original.end_date);
          const currentDate = dayjs();
          const days = expiryDate.diff(currentDate, 'days');
          if (days <= 0) {
            // if (cell.row.original.status !== 'Expired') {
            //   updateStatusToExpired(cell.row.original.id);
            // }
            const daysAgo = Math.abs(days);
            const expiredMessage =
              daysAgo === 0 ? 'Expired today' : daysAgo === 1 ? 'Expired yesterday' : `Expired ${daysAgo} days ago`;
            return <span style={{ color: 'GREY', fontWeight: 'bold' }}>{expiredMessage}</span>;
          } else if (days === 1) {
            return <span style={{ fontWeight: 'bold', color: 'red' }}>1 day</span>;
          } else if (days < 5) {
            return <span style={{ color: 'red', fontWeight: 'bold' }}>{days} days</span>;
          } else {
            return <span style={{ fontWeight: 'bold' }}>{days} days</span>;
          }
        },
      },

      // feedback
      {
        accessorKey: 'feedback',
        header: 'Feedback',
        enableClickToCopy: true,
        minWidth: 170,
      },
    ],
    []
  );

  console.log('shgfsdfsdf', data);

  const handleAddNewClient = (values, actions) => {
    try {
      Post(
        values,
        selectedClient ? API_URLS.updateClient : API_URLS.createClient,
        (resp) => {
          setOpenClientDialog(false);
          selectedClient
            ? enqueueSnackbar('Client updated succsessfully', { variant: 'success' })
            : enqueueSnackbar('Client added succsessfully', { variant: 'success' });
          actions.setSubmitting(false);
          setSelectedClient(null);
          getAllClientsWithPagination();
        },
        (error) => {
          actions.setSubmitting(false);
          enqueueSnackbar(error?.response?.data?.message || 'Failed to add client', 'error');
        }
      );
    } catch (error) {
      actions.setSubmitting(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const handleSubscriptionRenewal = (values, actions) => {
    try {
      Post(
        values,
        API_URLS.renewClientSubscription,
        (resp) => {
          setOpenClientRenewDialog(false);
          enqueueSnackbar('Subscription renewed succsessfully', { variant: 'success' });
          actions.setSubmitting(false);
          setSelectedClient(null);
          getAllClientsWithPagination();
        },
        (error) => {
          actions.setSubmitting(false);
          enqueueSnackbar(error?.response?.data?.message || 'Failed to renew subscription', 'error');
        }
      );
    } catch (error) {
      actions.setSubmitting(false);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }

  const getAllClientsWithPagination = useCallback(() => {
    try {
      setIsLoading(true);
      Post(
        {
          pageNumber: pagination.pageIndex,
          pageSize: pagination.pageSize,
          searchString: globalFilter,
          status: status.status,
          serviceId: serviceId,
        },
        API_URLS.getAllClientsWithPagination,
        (resp) => {
          let data = resp?.clients;
          console.log('datakjdagfdsf', resp?.clients);
          // concat first name and lastName to full name
          data = data?.map((row) => {
            row.no_of_roles_working = row?.client?.client_roles_working?.length;
            row.fullName = `${row?.client?.first_name} ${row?.client?.last_name}`;
            if (parseInt(row?.no_of_credits) && parseInt(row?.credits_used)) {
              row.remainingCredits = `${parseInt(row?.no_of_credits)}` - `${parseInt(row?.credits_used)}`;
            } else {
              row.remainingCredits = 0;
            }
            row.assign_acm = `${row?.user.first_name} ${row?.user.last_name}`;
            return row;
          });
          setData(data);
          setTotalCount(resp?.totalCount);
          setIsError(false);
          setIsLoading(false);
        },
        (error) => {
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  }, [pagination.pageIndex, pagination.pageSize, status, serviceId]);

  useEffect(() => {
    getAllClientsWithPagination();
  }, [getAllClientsWithPagination]);

  const getClientStats = useCallback(() => {
    try {
      setIsClientStatsLoaing(true);
      Get(
        {},
        API_URLS.getClientStatsByService,
        (resp) => {
          setClientStats(resp?.data);
          setIsClientStatsLoaing(false);
        },
        (error) => {
          setIsClientStatsLoaing(false);
        }
      );
    } catch (error) {
      setIsClientStatsLoaing(false);
    }
  }, []);

  useEffect(() => {
    getClientStats();
  }, []);

  console.log('clientStats', data);

  return (
    <div style={{ backgroundColor: '#ECF6FE' }}>
      <Box display="flex" marginLeft="10px">
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          Manage Clients
        </Typography>
      </Box>
      <br />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1rem', backgroundColor: 'white', width: '95%', margin: 'auto' }}
      >
        {clientStats.length > 0 ? (
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '1rem' }}>
            <ArrowPipeline
              title="Total"
              onClick={() => {
                setServiceId(null);
                setStatus((prevState) => ({ ...prevState, status: '' }));
              }}
              total={clientStats.reduce((acc, curr) => acc + curr.stats.total, 0)}
              left={clientStats.reduce((acc, curr) => acc + curr.stats.Expired, 0)}
              center={clientStats.reduce((acc, curr) => acc + curr.stats.total, 0)}
              right={clientStats.reduce((acc, curr) => acc + curr.stats.Active, 0)}
              titleService="Client Stats"
              leftTooltip="Expired"
              centerTooltip="Total"
              rightTooltip="Active"
              color="#E496FF"
              onLeftClick={() => {
                setStatus((prevState) => ({ ...prevState, status: 'Expired' }));
                setServiceId(null);
              }}
              onCenterClick={() => {
                setStatus('');
                setServiceId(null);
              }}
              onRightClick={() => {
                setStatus((prevState) => ({ ...prevState, status: 'Active' }));
                setServiceId(null);
              }}
            />
            {clientStats.map((clientStat) => {
              let color;
              switch (clientStat.service_name) {
                case 'CV-Sourcing':
                  color = '#73C94A';
                  break;
                case 'Pre-Qualification':
                  color = '#F0F013';
                  break;
                case '360/Direct':
                  color = '#00C1FE';
                  break;
                case 'Lead-Generation':
                  color = '#F32DDF';
                  break;
                case 'IT and Consulting':
                  color = '#F58538';
                  break;
                case 'VA':
                  color = '#8F00FF';
                  break;
                default:
                  color = ''; // You can specify a default color if needed
              }

              return (
                <ArrowPipeline
                  title={clientStat.service_name}
                  total={clientStat.stats.total}
                  left={clientStat.stats.Expired}
                  center={clientStat.stats.total}
                  right={clientStat.stats.Active}
                  onClick={() => {
                    setServiceId(clientStat?.id);
                    setStatus((prevState) => ({ ...prevState, status: '' }));
                  }}
                  titleService="Client Stats"
                  leftTooltip="Expired"
                  centerTooltip="Total"
                  rightTooltip="Active"
                  color={color} // Pass the color based on service name
                  onLeftClick={() => {
                    setStatus((prevState) => ({ ...prevState, status: 'Expired' }));
                    setServiceId(clientStat?.id);
                  }}
                  onCenterClick={() => {
                    setStatus('');
                    setServiceId(clientStat?.id);
                  }}
                  onRightClick={() => {
                    setStatus((prevState) => ({ ...prevState, status: 'Active' }));
                    setServiceId(clientStat?.id);
                  }}
                />
              );
            })}
          </Box>
        ) : (
          <>
            {isClientStatsLoaing ? (
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                <Typography variant="h5">No Clients Found</Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      <br />
      <Box display="flex" justifyContent="flex-end" sx={{ marginBottom: '1rem', width: '95%', margin: 'auto' }}>
        <Button
          variant="outlined"
          startIcon={<Icon icon="material-symbols:add" />}
          sx={{
            fontSize: '18px',
            borderRadius: '10px',
            borderWidth: '2px',
            color: '#0ec4fe',
            height: '54px',
            borderColor: '#0ec4fe',
            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              color: '#0ec4fe !important',
              borderColor: '#0ec4fe !important',
              boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
              backgroundColor: 'transparent !important',
              border: '2px solid #0ec4fe !important',
            },
          }}
          size="small"
          onClick={() => {
            setSelectedClient(null);
            setOpenClientDialog(true);
          }}
        >
          Create New Client
        </Button>
        <Button
          variant="outlined"
          startIcon={<Icon icon="material-symbols:add" />}
          sx={{
            fontSize: '18px',
            borderRadius: '10px',
            borderWidth: '2px',
            color: '#0ec4fe',
            height: '54px',
            borderColor: '#0ec4fe',
            boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2)',
            marginLeft: '10px',

            '&:hover': {
              color: '#0ec4fe !important',
              borderColor: '#0ec4fe !important',
              boxShadow: '-5px 7px 20px rgba(0, 0, 0, 0.2) !important',
              backgroundColor: 'transparent !important',
              border: '2px solid #0ec4fe !important',
            },
          }}
          size="small"
          onClick={() => setOpenServiceDialog(true)}
        >
          Add New Service
        </Button>
      </Box>

      <div style={{ margin: '2rem' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120, // Adjust the size as needed
            },
          }}
          columns={columns}
          data={data}
          enableColumnFilters={false}
          defaultColumn={{
            size: 50,
          }}
          manualPagination
          rowCount={totalCount}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          // muiTableBodyRowProps={{ hover: false }}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            sorting,
          }}
          muiTableProps={{
            sx: {
              borderRadius: '30px',
            },
          }}
          positionToolbarAlertBanner="bottom"
          actionsColumnIndex={-1} // Set to 0 if placing actions at the start
          enableRowActions
          renderRowActions={({ row }) => (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Edit
                onClick={() => {
                  setOpenClientDialog(true);
                  setSelectedClient(row?.original);
                }}
                sx={{ cursor: 'pointer' }}
              />
              {row.original.status === 'Expired' && (
                <SyncTwoTone
                  sx={{
                    cursor: 'pointer',
                    color: 'green',
                    marginLeft: '10px',
                    fontSize: '30px',
                  }}
                  onClick={() => {
                    setOpenClientRenewDialog(true);
                    setSelectedClient(row?.original);
                  }}
                />
              )}
            </Box>
          )}
        />
      </div>
      <br />

      {openClientDialog && (
        <AddNewClient
          open={openClientDialog}
          onClose={() => setOpenClientDialog(false)}
          onSubmit={(values, actions) => handleAddNewClient(values, actions)}
          updateClient={selectedClient}
        />
      )}

      {openServiceDialog && <AddNewService open={openServiceDialog} onClose={() => setOpenServiceDialog(false)} />}

      {openClientRenewDialog && (
        <RenewClientSubscription
          onClose={() => setOpenClientRenewDialog(false)}
          open={openClientRenewDialog}
          updateClient={selectedClient}
          onSubmit={(values, actions) => handleSubscriptionRenewal(values, actions)}
        />
      )}
    </div>
  );
}

export default Clients;
